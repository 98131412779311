import { Login } from "../../pages/public";
import {
  Brand,
  ChangePassword,
  Customer,
  Dashboard,
  Department,
  Employee,
  FormBrand,
  FormCustomer,
  FormDepartment,
  FormEmployee,
  FormMaterialConsumption,
  FormProduct,
  FormRecipe,
  FormStockTransfer,
  FormSupplier,
  LoyaltyCustomer,
  LoyaltySettings,
  MaterialConsumption,
  POSForm,
  POSList,
  PriceListCustomer,
  PriceListRetailer,
  PriceListWholesaler,
  Product,
  Recipe,
  Stock,
  StockTransfer,
  StockTransferRequest,
  StockVerification,
  Supplier,
  ViewProduct,
  ViewEmployee,
  ViewRecipe,
  ViewCustomer,
  ViewSupplier,
  PricingDetails,
  ProductSupplier,
  ProductProfitLoss,
  CustomerDisplay,
  Invoice,
  CreditNote,
  Receipt,
  SupplierBill,
  ProductList,
  DabitNote,
  Expenses,
  Sales,
  BankCash,
  Branch,
  Reports,
  CustomerList,
  SettingsLoyalty,
  Purchase,
  Settings,
  Profile,
} from "../../pages/private";

const publicRoutes = [
  {
    path: "",
    component: <Login />,
    exact: true,
    key: "login",
  },
  {
    path: "/login",
    component: <Login />,
    exact: false,
    key: "login",
  },
];

const privateRoutes = [
  {
    path: "/dashboard",
    component: <Dashboard />,
    exact: false,
    key: "dashboard",
    withNav: true,
  },
  {
    path: "/contact/supplier",
    component: <Supplier />,
    exact: false,
    key: "supplier",
    withNav: true,
  },
  {
    path: "/contact/supplier/:type",
    component: <FormSupplier />,
    exact: false,
    key: "form-supplier",
    withNav: true,
  },
  {
    path: "/contact/supplier/:type/:id",
    component: <FormSupplier />,
    exact: false,
    key: "form-edit-supplier",
    withNav: true,
  },
  {
    path: "/contact/supplier-details/:id",
    component: <ViewSupplier />,
    exact: false,
    key: "form-supplier-edit",
    withNav: true,
  },

  {
    path: "/contact/dabit-note/:id",
    component: <DabitNote />,
    exact: false,
    key: "dabit-note",
    withNav: true,
  },

  {
    path: "/contact/product-list/:id",
    component: <ProductList />,
    exact: false,
    key: "product-list",
    withNav: true,
  },

  {
    path: "/contact/supplier-bill/:id",
    component: <SupplierBill />,
    exact: false,
    key: "supplier-bill",
    withNav: true,
  },

  {
    path: "/contact/customer",
    component: <Customer />,
    exact: false,
    key: "customer",
    withNav: true,
  },
  {
    path: "/contact/customer/:type",
    component: <FormCustomer />,
    exact: false,
    key: "form-customer",
    withNav: true,
  },
  {
    path: "/contact/customer/:type/:id",
    component: <FormCustomer />,
    exact: false,
    key: "form-customer-edit",
    withNav: true,
  },

  {
    path: "/contact/invoice/:id",
    component: <Invoice />,
    exact: false,
    key: "invoice",
    withNav: true,
  },

  {
    path: "/contact/reciept/:id",
    component: <Receipt />,
    exact: false,
    key: "reciept",
    withNav: true,
  },
  {
    path: "/contact/credit-note/:id",
    component: <CreditNote />,
    exact: false,
    key: "credit-note",
    withNav: true,
  },

  {
    path: "/contact/customer-details/:id",
    component: <ViewCustomer />,
    exact: false,
    key: "form-customer-edit",
    withNav: true,
  },
  {
    path: "/contact/employee",
    component: <Employee />,
    exact: false,
    key: "employee",
    withNav: true,
  },
  {
    path: "/contact/employee/:type",
    component: <FormEmployee />,
    exact: false,
    key: "form-employee",
    withNav: true,
  },
  {
    path: "/contact/employee/:type/:id",
    component: <FormEmployee />,
    exact: false,
    key: "form-employee-edit",
    withNav: true,
  },

  {
    path: "/contact/employee-details/:id",
    component: <ViewEmployee />,
    exact: false,
    key: "form-employee-edit",
    withNav: true,
  },
  {
    path: "/inventory/product",
    component: <Product />,
    exact: false,
    key: "product",
    withNav: true,
  },
  {
    path: "/inventory/product/:type",
    component: <FormProduct />,
    exact: false,
    key: "product-add",
    withNav: true,
  },
  {
    path: "/inventory/product/:type/:id",
    component: <FormProduct />,
    exact: false,
    key: "product-edit",
    withNav: true,
  },
  {
    path: "/inventory/product-details/:id",
    component: <ViewProduct />,
    exact: false,
    key: "product-edit",
    withNav: true,
  },
  {
    path: "/inventory/product-supplier/:id",
    component: <ProductSupplier />,
    exact: false,
    key: "product-edit",
    withNav: true,
  },
  {
    path: "/inventory/product-brand/:id",
    component: <PricingDetails />,
    exact: false,
    key: "product-edit",
    withNav: true,
  },
  {
    path: "/inventory/product-profit-loss/:id",
    component: <ProductProfitLoss />,
    exact: false,
    key: "product-edit",
    withNav: true,
  },
  {
    path: "/inventory/stock",
    component: <Stock />,
    exact: false,
    key: "stock",
    withNav: true,
  },
  {
    path: "/inventory/stock-transfer-request",
    component: <StockTransferRequest />,
    exact: false,
    key: "stock-transfer-request",
    withNav: true,
  },
  {
    path: "/inventory/recipe",
    component: <Recipe />,
    exact: false,
    key: "recipe",
    withNav: true,
  },
  {
    path: "/inventory/recipe-details/:id",
    component: <ViewRecipe />,
    exact: false,
    key: "form-recipe-edit",
    withNav: true,
  },
  {
    path: "/inventory/recipe/:type",
    component: <FormRecipe />,
    exact: false,
    key: "recipe-add",
    withNav: true,
  },
  {
    path: "/inventory/recipe/:type/:id",
    component: <FormRecipe />,
    exact: false,
    key: "recipe-edit",
    withNav: true,
  },
  {
    path: "/inventory/material-consumption",
    component: <MaterialConsumption />,
    exact: false,
    key: "material-consumption",
    withNav: true,
  },
  {
    path: "/inventory/material-consumption/:type",
    component: <FormMaterialConsumption />,
    exact: false,
    key: "material-consumption-add",
    withNav: true,
  },
  {
    path: "/inventory/material-consumption/:type/:id",
    component: <FormMaterialConsumption />,
    exact: false,
    key: "material-consumption-edit",
    withNav: true,
  },
  {
    path: "/inventory/department",
    component: <Department />,
    exact: false,
    key: "department",
    withNav: true,
  },
  {
    path: "/inventory/department/:type",
    component: <FormDepartment />,
    exact: false,
    key: "department-add",
    withNav: true,
  },
  {
    path: "/inventory/department/:type/:id",
    component: <FormDepartment />,
    exact: false,
    key: "department-edit",
    withNav: true,
  },
  {
    path: "/inventory/brand",
    component: <Brand />,
    exact: false,
    key: "brand",
    withNav: true,
  },
  {
    path: "/inventory/brand/:type",
    component: <FormBrand />,
    exact: false,
    key: "brand-add",
    withNav: true,
  },
  {
    path: "/inventory/brand/:type/:id",
    component: <FormBrand />,
    exact: false,
    key: "brand-edit",
    withNav: true,
  },
  {
    path: "/inventory/stock-verification",
    component: <StockVerification />,
    exact: false,
    key: "stock-verification",
    withNav: true,
  },
  {
    path: "/inventory/stock-transfer",
    component: <StockTransfer />,
    exact: false,
    key: "stock-transfer",
    withNav: true,
  },
  {
    path: "/inventory/stock-transfer/:type",
    component: <FormStockTransfer />,
    exact: false,
    key: "stock-transfer-add",
    withNav: true,
  },
  {
    path: "/inventory/stock-transfer/:type/:id",
    component: <FormStockTransfer />,
    exact: false,
    key: "stock-transfer-edit",
    withNav: true,
  },
  {
    path: "/price-list/wholesaler",
    component: <PriceListWholesaler />,
    exact: false,
    key: "price-list-wholesaler",
    withNav: true,
  },
  {
    path: "/price-list/customer",
    component: <PriceListCustomer />,
    exact: false,
    key: "price-list-customer",
    withNav: true,
  },
  {
    path: "/price-list/retailer",
    component: <PriceListRetailer />,
    exact: false,
    key: "price-list-retailer",
    withNav: true,
  },
  {
    path: "/loyalty/customer-list",
    component: <LoyaltyCustomer />,
    exact: false,
    key: "loyalty-customer-list",
    withNav: true,
  },
  {
    path: "/loyalty/loyalty-settings",
    component: <LoyaltySettings />,
    exact: false,
    key: "loyalty-loyalty-settings",
    withNav: true,
  },
  {
    path: "/change-password",
    component: <ChangePassword />,
    exact: false,
    key: "change-password",
    withNav: true,
  },
  {
    path: "/pos-management",
    component: <POSList />,
    exact: false,
    key: "pos-management",
    withNav: true,
  },
  {
    path: "/pos-management/:type",
    component: <POSForm />,
    exact: false,
    key: "pos-management-add",
    withNav: true,
  },
  {
    path: "/pos-management/:type/:id",
    component: <POSForm />,
    exact: false,
    key: "pos-management-edit",
    withNav: true,
  },
  {
    path: "/settings",
    component: <Settings />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/settings/customer-display",
    component: <CustomerDisplay />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/settings/customer-list",
    component: <CustomerList />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/settings/loyalty-settings",
    component: <SettingsLoyalty />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/expenses",
    component: <Expenses />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/purchase",
    component: <Purchase />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/sales",
    component: <Sales />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/bank-cash",
    component: <BankCash />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/branch",
    component: <Branch />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/reports",
    component: <Reports />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
  {
    path: "/profile",
    component: <Profile />,
    exact: false,
    key: "customer-display-settings",
    withNav: true,
  },
];

export { publicRoutes, privateRoutes };
