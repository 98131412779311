import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import { FaRegFilePdf } from "react-icons/fa";

const ViewCustomer = () => {
  const params = useParams();
  const [customer, setCustomer] = useState([]);
  const breadCrumbs = [
    { key: 0, name: "Contact", to: "/contact/Customer", active: false },
    { key: 1, name: "Customer", to: "/contact/Customer", active: false },
    {
      key: 2,
      name: "Preview",
      to: "/contact/Customer/view",
      active: true,
    },
  ];

  console.log("customer data :", customer);

  useAsyncEffect(async () => {
    if (params.id !== "") {
      try {
        const response = await api.getCustomerById({ id: params.id });
        console.log("RESPONSE DATA  customer data :", response.data.data[0]);
        if (response.status === 200) {
          if (response.status === 200) {
            setCustomer(response.data.data[0]);
          }
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Preview Customer details" />

      <div className="flex items-center mx-6 my-6 space-x-8">
        <Link
          to={`/contact/customer-details/${params.id}`}
          className="text-sm font-bold border-b-2 border-[#3C81FC] pb-2"
        >
          Basic details
        </Link>
        <Link
          to={`/contact/invoice/${params.id}`}
          className="text-sm pb-2"
        >
          Invoice
        </Link>
        <Link
          to={`/contact/reciept/${params.id}`}
          className="text-sm pb-2"
        >
          Receipt
        </Link>
        <Link
          to={`/contact/credit-note/${params.id}`}
          className="text-sm pb-2"
        >
          Credit note
        </Link>
      </div>


      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Customer information</p>
        </div>
        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Customer name</p>
            <p className="text-base text-[#142243]">{customer.name}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Email ID</p>
            <p className="text-base text-[#142243]">{customer.email}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Mobile number</p>
            <p className="text-base text-[#142243]">{customer.mobile_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Telephone number</p>
            <p className="text-base text-[#142243]">{customer.telephone_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Whatsapp number</p>
            <p className="text-base text-[#142243]">{customer.whatsapp_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">VAT number</p>
            <p className="text-base text-[#142243]">{customer.vat_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Reference</p>
            <p className="text-base text-[#142243]">{customer.reference}</p>
          </div>

          {/* <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Company number</p>
            <p className="text-base text-[#142243]">
              {customer.company_number}
            </p>
          </div> */}
        </div>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Address information</p>
        </div>
    {customer.addresses && 
    customer.addresses.length > 0 && 
    customer.addresses.map((item, key) =>(
        <div className="grid grid-cols-3 grid-flow-row gap-x-10" key={key}>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Address Line 1</p>
            <p className="text-base text-[#142243]">{item.address_line_1}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Address Line 2</p>
            <p className="text-base text-[#142243]">{item.address_line_2}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Country</p>
            <p className="text-base text-[#142243]">{item.country}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">State</p>
            <p className="text-base text-[#142243]">{item.state}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">City</p>
            <p className="text-base text-[#142243]">{item.city}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Pincode</p>
            <p className="text-base text-[#142243]">{item.pincode}</p>
          </div>
        </div>
        ))}
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Documents</p>
        </div>
        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="m-3 p-3 flex items-center space-x-2 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
            <FaRegFilePdf className="text-xl text-red-500" />
            <span className="text-base text-[#142243]">
              {customer.address_proof}
            </span>
          </div>

          <div className="m-3 p-3 flex items-center space-x-2 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
            <FaRegFilePdf className="text-xl text-red-500" />
            <span className="text-base text-[#142243]">
              {customer.id_proof}
            </span>
          </div>

          <div className="m-3 p-3 flex items-center space-x-2 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
            <FaRegFilePdf className="text-xl text-red-500" />
            <span className="text-base text-[#142243]">
              {customer.acc_form}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewCustomer;
