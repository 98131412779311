import React, { useState } from "react";
import { Header, Table } from "../../../../components";
import { BiPencil, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import * as api from "../../../../http";
import { BsEyeFill } from "react-icons/bs";
import { ImWarning } from "react-icons/im";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Employee = () => {
  const breadCrumbs = [
    { key: 0, name: "Contact", to: "", active: false },
    { key: 1, name: "Employee", to: "/contact/employee", active: true },
  ];
  const [employees, setEmployees] = useState([]);

  const getList = async () => {
    try {
      const response = await api.getAllEmployees();
      //console.log("GETLIST=============================>" ,response.data);
      if (response.status === 200) {
        setEmployees(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };
  useAsyncEffect(async () => {
    await getList();
  }, []);

  const data = React.useMemo(() => {
    const formattedData = [];
    const confirmDelete = (id) => {
      confirmAlert({
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => deleteItem(id),
          },
          {
            label: "No",
          },
        ],
        // customUI: ({ onClose }) => {
        //   return (
        //     <div
        //       className="relative z-10"
        //       aria-labelledby="modal-title"
        //       role="dialog"
        //       aria-modal="true"
        //     >
        //       <div className="fixed inset-0 bg-gray-800 bg-opacity-75 transition-opacity"></div>
        //       <div className="fixed inset-0 z-10 overflow-y-auto">
        //         <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
        //           <div className="relative transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
        //             <div className="bg-white px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
        //               <div className="sm:flex sm:items-start">
        //                 <div className="mx-auto flex h-12 w-12 flex-shrink-0 items-center justify-center rounded-full bg-red-100 sm:mx-0 sm:h-10 sm:w-10">
        //                   <ImWarning className="h-6 w-6 text-red-600" />
        //                 </div>
        //                 <div className="mt-3 text-center sm:mt-0 sm:ml-4 sm:text-left">
        //                   <h3
        //                     className="text-lg font-medium leading-6 text-gray-900"
        //                     id="modal-title"
        //                   >
        //                     Delete Item
        //                   </h3>
        //                   <div className="mt-2">
        //                     <p className="text-sm text-gray-500">
        //                       Are you sure you want to delete this item?
        //                     </p>
        //                   </div>
        //                 </div>
        //               </div>
        //             </div>
        //             <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
        //               <button
        //                 onClick={() => {
        //                   deleteItem(id);
        //                   onClose();
        //                 }}
        //                 type="button"
        //                 className="inline-flex w-full justify-center rounded-md border border-transparent bg-red-600 px-4 py-2 text-base font-medium text-white shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2 sm:ml-3 sm:w-auto sm:text-sm"
        //               >
        //                 Delete
        //               </button>
        //               <button
        //                 onClick={onClose}
        //                 type="button"
        //                 className="mt-3 inline-flex w-full justify-center rounded-md border border-gray-300 bg-white px-4 py-2 text-base font-medium text-gray-700 shadow-sm hover:bg-gray-50 focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2 sm:mt-0 sm:ml-3 sm:w-auto sm:text-sm"
        //               >
        //                 Cancel
        //               </button>
        //             </div>
        //           </div>
        //         </div>
        //       </div>
        //     </div>
        //   );
        // },
      });
    };
    const deleteItem = async (id) => {
      try {
        const response = await api.deleteEmployee({ id });
        if (response.status === 200) {
          toast.success(response.data.message, { position: "top-right" });
          await getList();
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        console.log("Err:", err);
      }
    };
    employees?.map((data, index) =>
      formattedData.push({
        col1: data.name,
        col2: data.employee_type,
        col3: data.mobile_no,
        col4: data.email,
        col5: (
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-600"></div>
            <div>Active</div>
          </div>
        ),
        col6: (
          <div className="flex items-center space-x-4">
            <Link
              className="cursor-pointer"
              to={`/contact/employee-details/${data.id}`}
            >
              <BsEyeFill className="text-xl text-[#3C81FC]" />
            </Link>
            <Link
              className="cursor-pointer"
              to={`/contact/employee/edit/${data.id}`}
            >
              <BiPencil className="text-xl text-[#3C81FC]" />
            </Link>

            <p
              className="cursor-pointer"
              onClick={() => confirmDelete(data.id)}
            >
              <BiTrash className="text-xl text-red-500" />
            </p>
          </div>
        ),
      })
    );

    return formattedData;
  }, [employees]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Employee name",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Type",
        accessor: "col2",
      },
      {
        Header: "Mobile number",
        accessor: "col3",
      },
      {
        Header: "Email ID",
        accessor: "col4",
      },
      {
        Header: "Status",
        accessor: "col5",
      },
      {
        Header: "",
        accessor: "col6",
      },
    ],
    []
  );

  const addNewData = [
    {
      title: "Add New Employee",
      to: "/contact/Employee/add",
    },
  ];

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Employee" />
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};

export default Employee;
