import React from 'react';
import {
    BiSearchAlt,
  } from "react-icons/bi";

const Globlefilter = ({filter, setFilter}) => {
  return (
    <div className="relative">
           <div className="absolute inset-y-0 left-0 flex items-center pl-3 pointer-events-none">
              <BiSearchAlt className="text-xl text-[#3C81FC]" />
            </div>
        <input className="bg-gray-50 border border-gray-300 text-gray-900 text-sm rounded-md focus:ring-blue-500 focus:border-blue-500 block w-96 pl-10 p-2.5"
        value={filter || ''} onChange={(e) => setFilter(e.target.value)}
        placeholder="Search"/>
    </div>
  )
}
export default Globlefilter;