import React, { useState } from "react";
import { Header, Table } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import { BiAlbum, BiPencil, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import { BsEyeFill } from "react-icons/bs";
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-confirm-alert/src/react-confirm-alert.css' // Import css

const Supplier = () => {
  const breadCrumbs = [
    { key: 0, name: "Contact", to: "", active: false },
    { key: 1, name: "Supplier", to: "/contact/supplier", active: true },
  ];

  const [suppliers, setSuppliers] = useState([]);
  const getList = async () => {
    try {
      const response = await api.getAllSuppliers();
      if (response.status === 200) {
        console.log("GETLIST=============================>" ,response.data);
        setSuppliers(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };
  useAsyncEffect(async () => {
    await getList();
  }, []);

  const data = React.useMemo(() => {
    const formattedData = [];
    const confirmDelete=(id)=>{
      confirmAlert({
        message: 'Are you sure to do this.',
        buttons: [
          {
            label: 'Yes',
            onClick:()=>deleteItem(id)
          },
          {
            label: 'No',
          }
        ]
      })
    }
    const deleteItem = async (id) => {
      try {
        const response = await api.deleteSupplier({ id });
        if (response.status === 200) {
          toast.success(response.data.message, { position: "top-right" });
          await getList();
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        console.log("Err:", err);
      }
    };

    suppliers?.map((data, index) =>
      formattedData.push({
        col1: data.company_name,
        col2: data.contact_person,
        col3: data.contact_person_mobile_no,
        col4: data.email,
        col5: (
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-600"></div>{" "}
            <div>Active</div>{" "}
          </div>
        ),
        col6: (
          <div className="flex items-center space-x-4">
            <Link
              className="cursor-pointer"
              to={`/contact/supplier-details/${data.id}`}
            >
              <BsEyeFill className="text-xl text-[#3C81FC]" />
            </Link>
            <Link
              className="cursor-pointer"
              to={`/contact/supplier/edit/${data.id}`}
            >
              <BiPencil className="text-xl text-[#3C81FC]" />
            </Link>

            <p className="cursor-pointer" onClick={() => confirmDelete(data.id)}>
              <BiTrash className="text-xl text-red-500" />
            </p>
          </div>
        ),
      })
    );

    return formattedData;
  }, [suppliers]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Company name",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Contact person",
        accessor: "col2",
      },
      {
        Header: "Mobile number",
        accessor: "col3",
      },
      {
        Header: "Email ID",
        accessor: "col4",
      },
      {
        Header: "Status",
        accessor: "col5",
      },
      {
        Header: "",
        accessor: "col6",
      },
    ],
    []
  );

  const addNewData = [
    {
      title: "Add New Supplier",
      to: "/contact/supplier/add",
    },
  ];

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Supplier" />
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};

export default Supplier;
