import React, { useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Header } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";

const ViewSupplier = () => {
  const params = useParams();

  const [supplier, setSuppliers] = useState([]);

  const breadCrumbs = [
    { key: 0, name: "Contact", to: "/contact/Supplier", active: false },
    { key: 1, name: "Supplier", to: "/contact/Supplier", active: false },
    {
      key: 2,
      name: "Preview",
      to: "/contact/Supplier/view",
      active: true,
    },
  ];

  console.log("supplier :", supplier);

  useAsyncEffect(async () => {
    if (params.id !== "") {
      try {
        const response = await api.getSupplierById({ id: params.id });
        if (response.status === 200) {
          if (response.status === 200) {
            setSuppliers(response.data.data[0]);
          }
          console.log("RESPONSE DATA", response.data.data[0]);
          console.log(
            "RESPONSE DATA ADDRESSES",
            response.data.data[0].addresses
          );
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Preview Supplier details" />

      <div className="flex items-center mx-6 my-6 space-x-8">
        <Link
          to={`/contact/supplier-details/${params.id}`}
          className="text-sm font-bold border-b-2 border-[#3C81FC] pb-2"
        >
          Basic details
        </Link>
        <Link
          to={`/contact/supplier-bill/${params.id}`}
          className="text-sm pb-2"
        >
          Supplier bill
        </Link>
        <Link to={`/contact/dabit-note/${params.id}`} className="text-sm pb-2">
          Dabit note
        </Link>
        <Link
          to={`/contact/product-list/${params.id}`}
          className="text-sm pb-2"
        >
          Product list
        </Link>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Supplier information</p>
        </div>

        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Company name</p>
            <p className="text-base text-[#142243]">{supplier.company_name}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Owner name</p>
            <p className="text-base text-[#142243]">{supplier.owner_name}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Contact person</p>
            <p className="text-base text-[#142243]">
              {supplier.contact_person}
            </p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Email</p>
            <p className="text-base text-[#142243]">{supplier.email}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">
              Contact person mobile number
            </p>
            <p className="text-base text-[#142243]">
              {supplier.contact_person_mobile_no}
            </p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Telephone number</p>
            <p className="text-base text-[#142243]">{supplier.telephone_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Whatsapp number</p>
            <p className="text-base text-[#142243]">{supplier.whatsapp_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Date of birth</p>
            <p className="text-base text-[#142243]">{supplier.birth_date}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">VAT number</p>
            <p className="text-base text-[#142243]">{supplier.vat_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">
              Date of First time purchase
            </p>
            <p className="text-base text-[#142243]">
              {supplier.date_of_first_time_purchase}
            </p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Reference</p>
            <p className="text-base text-[#142243]">{supplier.reference}</p>
          </div>
        </div>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Address information</p>
        </div>
        {supplier.addresses &&
          supplier.addresses.length > 0 &&
          supplier.addresses.map((item, key) => (
            <div className="grid grid-cols-3 grid-flow-row gap-x-10" key={key}>
              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">Address Line 1</p>
                <p className="text-base text-[#142243]">
                  {item.address_line_1}
                </p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">Address Line 2</p>
                <p className="text-base text-[#142243]">
                  {item.address_line_2}
                </p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">Country</p>
                <p className="text-base text-[#142243]">{item.country}</p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">State</p>
                <p className="text-base text-[#142243]">{item.state}</p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">City</p>
                <p className="text-base text-[#142243]">{item.city}</p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">Pincode</p>
                <p className="text-base text-[#142243]">{item.pincode}</p>
              </div>
            </div>
          ))}
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Bank information</p>
        </div>

        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Account number</p>
            <p className="text-base text-[#142243]">{supplier.account_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Branch Name</p>
            <p className="text-base text-[#142243]">{supplier.branch_name}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">SORT Code</p>
            <p className="text-base text-[#142243]">{supplier.sort_code}</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewSupplier;
