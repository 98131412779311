import { FieldArray, Form, Formik, Field } from "formik";
import React, { useRef, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import {
  Checkbox,
  Header,
  ImagePreview,
  Label,
  TextEmail,
  TextInput,
} from "../../../../components";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";

const FormSupplier = () => {
  const navigate = useNavigate();
  const idProofRef = useRef();
  const addressProofRef = useRef();
  const accountFormRef = useRef();

  const params = useParams();

  const breadCrumbs = [
    { key: 0, name: "Contact", to: "", active: false },
    { key: 1, name: "Customer", to: "/contact/Customer", active: false },
    {
      key: 2,
      name: `${params.type === "add" ? "Add Customer" : "Edit Customer"}`,
      to: `${params.type} === "add" ? '/contact/customer/add' : '/contact/customer/edit/${params.id}}'`,
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    customer_type: "",
    name: "",
    email: "",
    mobile_no: "",
    telephone_no: "",
    whatsapp_no: "",
    vat_no: "",
    reference: "",
    company_number: "",
    contact_person_name: "",
    addresses: [
      {
        address_line_1: "",
        address_line_2: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
      },
    ],
    id_proof: null,
    address_proof: null,
    acc_form: null,
    make_loyalty_customer: "false",
    barcode_no: "",
  });

  useAsyncEffect(async () => {
    if (params.type === "edit" && params.id !== "") {
      try {
        const response = await api.getCustomerById({ id: params.id });
        if (response.status === 200) {
          setInitialValues(response.data.data[0]);
          console.log("response :>", response.data.data[0]);
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  const CustomerSchema = Yup.object().shape({
    customer_type: Yup.string().required("Customer type is required"),
    name: Yup.string().required("Customer name is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    mobile_no: Yup.string().required("Contact number is required"),
    telephone_no: Yup.string(),
    whatsapp_no: Yup.string(),

    vat_no: Yup.string(),
    reference: Yup.string().required("Reference is required"),
    addresses: Yup.array().of(
      Yup.object().shape({
        address_line_1: Yup.string().required("Please enter valid address"),
        address_line_2: Yup.string().required("Please enter valid address"),
        country: Yup.string().required("Please enter country"),
        state: Yup.string().required("Please enter state"),
        city: Yup.string().required("Please enter city"),
        pincode: Yup.string(),
      })
    ),
    id_proof: Yup.mixed().when("customer_type", {
      is: "Wholesaler",
      then: Yup.mixed().required("Please upload ID Proof"),
    }),
    address_proof: Yup.mixed().when("customer_type", {
      is: "Wholesaler",
      then: Yup.mixed().required("Please upload Address Proof"),
    }),
    acc_form: Yup.mixed().notRequired(),
    barcode_no: Yup.string().when("customer_type", {
      is: "General",
      then: Yup.string().required("Please enter barcode number"),
    }),
    make_loyalty_customer: Yup.string().when("customer_type", {
      is: "General",
      then: Yup.string(),
    }),
    company_number: Yup.string().when("customer_type", {
      is: "Wholesaler",
      then: Yup.string(),
    }),
    contact_person_name: Yup.string().when("customer_type", {
      is: "Wholesaler",
      then: Yup.string(),
    }),
  });

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add new customer" />
      <Formik
        initialValues={initialValues}
        validationSchema={CustomerSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          console.log("formData1", values);
          values.addresses = JSON.stringify(values.addresses);
          let formData = new FormData();
          console.log("formData2", values);
          for (var key in values) {
            formData.append(key, values[key]);
          }

          if (params.type === "add") {
            try {
              const response = await api.createCustomer(formData);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/contact/customer");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (params.type === "edit" && params.id !== "") {
            formData.append("id", params.id);
            try {
              const response = await api.updateCustomer(formData);
              console.log("edit", response);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/contact/customer");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="customer-tab p-6">
              <h5 className="text-dark text-sm mb-3">Select customer type</h5>
              <div>
                <div className="flex justify-start gap-6">
                  <div>
                    <Field
                      className="sr-only peer"
                      type="radio"
                      value="Wholesaler"
                      name="customer_type"
                      id="wholesaler"
                      onChange={() => {
                        setFieldValue("customer_type", "Wholesaler");
                      }}
                    />
                    <label
                      htmlFor="wholesaler"
                      className={`border border-highlight flex p-3 w-32 shadow-sm text-center rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center`}
                    >
                      Wholesaler
                    </label>
                  </div>
                  <Field
                    className="sr-only peer"
                    type="radio"
                    value="General"
                    name="customer_type"
                    id="general"
                    onChange={() => {
                      setFieldValue("customer_type", "General");
                    }}
                  />
                  <label
                    htmlFor="general"
                    className="flex p-3 w-32 shadow-sm text-center border border-highlight rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center"
                  >
                    General
                  </label>
                </div>
                <div>
                  {errors.customer_type && touched.customer_type ? (
                    <span className="text-xs text-red-500">
                      {errors.customer_type}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="mt-6">
                <div className="content active" id="wholesaler">
                  <div className="p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100 ">
                    <div className="mb-6">
                      <p className="text-xl font-semibold">
                        Customer information
                      </p>
                    </div>
                    <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                      {values.customer_type === "Wholesaler" && (
                        <div className="mb-6">
                          <Label text="Company name" isRequired={true} />
                          <TextInput
                            placeholder="Enter company name"
                            name="name"
                            isError={errors.name && touched.name}
                          />
                          {errors.name && touched.name ? (
                            <span className="text-xs text-red-500">
                              {errors.name}
                            </span>
                          ) : null}
                        </div>
                      )}

                      {values.customer_type === "General" && (
                        <div className="mb-6">
                          <Label text="Customer name" isRequired={true} />
                          <TextInput
                            placeholder="Enter customer name"
                            name="name"
                            isError={errors.name && touched.name}
                          />
                          {errors.name && touched.name ? (
                            <span className="text-xs text-red-500">
                              {errors.name}
                            </span>
                          ) : null}
                        </div>
                      )}

                      <div className="mb-6">
                        <Label text="Email ID" isRequired={true} />
                        <TextEmail
                          placeholder="Enter email ID"
                          name="email"
                          isError={errors.email && touched.email}
                        />
                        {errors.email && touched.email ? (
                          <span className="text-xs text-red-500">
                            {errors.email}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-6">
                        <Label text="Mobile number" isRequired={true} />
                        <TextInput
                          placeholder="Enter mobile number"
                          name="mobile_no"
                          isError={errors.mobile_no && touched.mobile_no}
                        />
                        {errors.mobile_no && touched.mobile_no ? (
                          <span className="text-xs text-red-500">
                            {errors.mobile_no}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-6">
                        <Label text="Telephone number" isRequired={false} />
                        <TextInput
                          placeholder="Enter Telephone number"
                          name="telephone_no"
                          isError={errors.telephone_no && touched.telephone_no}
                        />
                        {errors.telephone_no && touched.telephone_no ? (
                          <span className="text-xs text-red-500">
                            {errors.telephone_no}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-6">
                        <Label text="Whatsapp number" isRequired={false} />
                        <TextInput
                          placeholder="Enter Whatsapp number"
                          name="whatsapp_no"
                          isError={errors.whatsapp_no && touched.whatsapp_no}
                        />
                        {errors.whatsapp_no && touched.whatsapp_no ? (
                          <span className="text-xs text-red-500">
                            {errors.whatsapp_no}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-6">
                        <Label text="VAT number" isRequired={false} />
                        <TextInput
                          placeholder="Enter VAT number"
                          name="vat_no"
                          isError={errors.vat_no && touched.vat_no}
                        />
                        {errors.vat_no && touched.vat_no ? (
                          <span className="text-xs text-red-500">
                            {errors.vat_no}
                          </span>
                        ) : null}
                      </div>
                      <div className="mb-6">
                        <Label text="Reference" isRequired={true} />
                        <TextInput
                          placeholder="Enter Reference"
                          name="reference"
                          isError={errors.reference && touched.reference}
                        />
                        {errors.reference && touched.reference ? (
                          <span className="text-xs text-red-500">
                            {errors.reference}
                          </span>
                        ) : null}
                      </div>
                      {values.customer_type === "Wholesaler" && (
                        <>
                          <div className="mb-6">
                            <Label text="Company number" isRequired={false} />
                            <TextInput
                              placeholder="Enter company number"
                              name="company_number"
                              isError={
                                errors.company_number && touched.company_number
                              }
                            />
                            {errors.company_number && touched.company_number ? (
                              <span className="text-xs text-red-500">
                                {errors.company_number}
                              </span>
                            ) : null}
                          </div>

                          <div className="mb-6">
                            <Label
                              text="Contact Person Name"
                              isRequired={false}
                            />
                            <TextInput
                              placeholder="Enter Contact Person Name"
                              name="contact_person_name"
                              isError={
                                errors.contact_person_name &&
                                touched.contact_person_name
                              }
                            />
                            {errors.contact_person_name &&
                            touched.contact_person_name ? (
                              <span className="text-xs text-red-500">
                                {errors.contact_person_name}
                              </span>
                            ) : null}
                          </div>
                        </>
                      )}
                    </div>
                  </div>
                  <div className="mt-6 p-6 rounded-md shadow-md shadow-blue-100/70 border border-blue-100 ">
                    <div className="mb-6 flex items-center justify-between">
                      <p className="text-xl font-semibold">
                        Address information
                      </p>
                    </div>
                    <FieldArray
                      name="addresses"
                      render={({ insert, remove, push }) => (
                        <>
                          {values.addresses.length > 0 &&
                            values.addresses.map((item, index) => (
                              <div
                                className={`${
                                  values.addresses.length > 1
                                    ? "border-b  mb-4"
                                    : null
                                } grid grid-cols-3 grid-flow-row gap-x-10`}
                                key={index}
                              >
                                <div className="mb-6">
                                  <Label
                                    text="Address Line 1"
                                    isRequired={true}
                                  />
                                  <TextInput
                                    placeholder="Enter Address Line 1"
                                    name={`addresses.${index}.address_line_1`}
                                    isError={
                                      errors.addresses &&
                                      errors.addresses[index] &&
                                      errors.addresses[index].address_line_1 &&
                                      touched.addresses &&
                                      touched.addresses[index] &&
                                      touched.addresses[index].address_line_1
                                    }
                                  />
                                  {errors.addresses &&
                                  errors.addresses[index] &&
                                  errors.addresses[index].address_line_1 &&
                                  touched.addresses &&
                                  touched.addresses[index] &&
                                  touched.addresses[index].address_line_1 ? (
                                    <span className="text-xs text-red-500">
                                      {errors.addresses[index].address_line_1}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="mb-6">
                                  <Label
                                    text="Address Line 2"
                                    isRequired={true}
                                  />
                                  <TextInput
                                    placeholder="Enter Address Line 2"
                                    name={`addresses.${index}.address_line_2`}
                                    isError={
                                      errors.addresses &&
                                      errors.addresses[index] &&
                                      errors.addresses[index].address_line_2 &&
                                      touched.addresses &&
                                      touched.addresses[index] &&
                                      touched.addresses[index].address_line_2
                                    }
                                  />
                                  {errors.addresses &&
                                  errors.addresses[index] &&
                                  errors.addresses[index].address_line_2 &&
                                  touched.addresses &&
                                  touched.addresses[index] &&
                                  touched.addresses[index].address_line_2 ? (
                                    <span className="text-xs text-red-500">
                                      {errors.addresses[index].address_line_2}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="mb-6">
                                  <Label text="Country" isRequired={true} />
                                  <TextInput
                                    placeholder="Enter Country"
                                    name={`addresses.${index}.country`}
                                    isError={
                                      errors.addresses &&
                                      errors.addresses[index] &&
                                      errors.addresses[index].country &&
                                      touched.addresses &&
                                      touched.addresses[index] &&
                                      touched.addresses[index].country
                                    }
                                  />
                                  {errors.addresses &&
                                  errors.addresses[index] &&
                                  errors.addresses[index].country &&
                                  touched.addresses &&
                                  touched.addresses[index] &&
                                  touched.addresses[index].country ? (
                                    <span className="text-xs text-red-500">
                                      {errors.addresses[index].country}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="mb-6">
                                  <Label text="State" isRequired={true} />
                                  <TextInput
                                    placeholder="Enter state"
                                    name={`addresses.${index}.state`}
                                    isError={
                                      errors.addresses &&
                                      errors.addresses[index] &&
                                      errors.addresses[index].state &&
                                      touched.addresses &&
                                      touched.addresses[index] &&
                                      touched.addresses[index].state
                                    }
                                  />
                                  {errors.addresses &&
                                  errors.addresses[index] &&
                                  errors.addresses[index].state &&
                                  touched.addresses &&
                                  touched.addresses[index] &&
                                  touched.addresses[index].state ? (
                                    <span className="text-xs text-red-500">
                                      {errors.addresses[index].state}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="mb-6">
                                  <Label text="City" isRequired={true} />
                                  <TextInput
                                    placeholder="Enter city"
                                    name={`addresses.${index}.city`}
                                    isError={
                                      errors.addresses &&
                                      errors.addresses[index] &&
                                      errors.addresses[index].city &&
                                      touched.addresses &&
                                      touched.addresses[index] &&
                                      touched.addresses[index].city
                                    }
                                  />
                                  {errors.addresses &&
                                  errors.addresses[index] &&
                                  errors.addresses[index].city &&
                                  touched.addresses &&
                                  touched.addresses[index] &&
                                  touched.addresses[index].city ? (
                                    <span className="text-xs text-red-500">
                                      {errors.addresses[index].city}
                                    </span>
                                  ) : null}
                                </div>
                                <div className="mb-6">
                                  <Label text="Pincode" isRequired={false} />
                                  <TextInput
                                    placeholder="Enter Pincode"
                                    name={`addresses.${index}.pincode`}
                                  />
                                </div>
                              </div>
                            ))}
                          <div
                            className="cursor-pointer flex items-center space-x-4"
                            onClick={() =>
                              push({
                                address_line_1: "",
                                address_line_2: "",
                                country: "",
                                state: "",
                                city: "",
                                pincode: "",
                              })
                            }
                          >
                            <BiPlusCircle className="text-xl text-[#3C81FC]" />
                            <span className="text-base font-semibold text-[#3C81FC]">
                              Add new addresses
                            </span>
                          </div>
                        </>
                      )}
                    />
                  </div>

                  {values.customer_type === "Wholesaler" && (
                    <div className="mt-6 p-6 rounded-md shadow-md shadow-blue-100/70 border border-blue-100">
                      <div className="mb-6">
                        <p className="text-xl font-semibold">Upload document</p>
                      </div>
                      <div className="flex flex-wrap -mx-3">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <Label text="Document Type" isRequired={true} />
                          <p className="border border-[#DBE4F2] mt-2 py-3 px-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1">
                            ID Proof
                          </p>
                        </div>
                        <div className="w-full md:w-2/5 px-3 mb-6 mt-1 md:mb-0">
                          <label className="block text-sm mb-2 text-dark">
                            Upload File *
                          </label>

                          <input
                            ref={idProofRef}
                            hidden
                            type="file"
                            name="id_proof"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file.size > 2 * 1024 * 1024) {
                                toast.error("File size exceeds 2MB limit.", {
                                  position: "top-right",
                                });
                              } else {
                                setFieldValue("id_proof", file);
                              }
                            }}
                            accept="image/png, image/jpg, image/jpeg, application/pdf"
                            multiple
                          />
                          <button
                            type="button"
                            className={`${
                              errors.id_proof && touched.id_proof
                                ? "border border-red-500"
                                : "border border-[#DBE4F2]"
                            } w-full px-20 py-3 text-sm rounded-lg text-[#3C81FC] bg-[#EAEFF8]`}
                            onClick={() => {
                              idProofRef.current.click();
                            }}
                          >
                            Browse...
                          </button>
                          {errors.id_proof && touched.id_proof ? (
                            <span className="text-xs text-red-500">
                              {errors.id_proof}
                            </span>
                          ) : null}
                          {values.id_proof && (
                            <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                              <p className="text-[#525D68] text-xs my-2 flex justify-center">
                                (Supported format: PDF, PNG, JPG only, max 2 MB)
                              </p>
                              <ImagePreview file={values.id_proof} />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mt-6">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <Label text="Document Type" isRequired={true} />
                          <p className="border border-[#DBE4F2] mt-2 py-3 px-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1">
                            Address Proof
                          </p>
                        </div>
                        <div className="w-full md:w-2/5 px-3 mb-6 mt-1 md:mb-0">
                          <label className="block text-sm mb-2 text-dark">
                            Upload File *
                          </label>

                          <input
                            ref={addressProofRef}
                            hidden
                            type="file"
                            name="address_proof"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file.size > 2 * 1024 * 1024) {
                                toast.error("File size exceeds 2MB limit.", {
                                  position: "top-right",
                                });
                              } else {
                                setFieldValue("address_proof", file);
                              }
                            }}
                            accept="image/png, image/jpg, image/jpeg, application/pdf"
                            multiple
                          />
                          <button
                            type="button"
                            className={`${
                              errors.address_proof && touched.address_proof
                                ? "border border-red-500"
                                : "border border-[#DBE4F2]"
                            } w-full px-20 py-3 text-sm rounded-lg text-[#3C81FC] bg-[#EAEFF8]`}
                            onClick={() => {
                              addressProofRef.current.click();
                            }}
                          >
                            Browse...
                          </button>
                          {errors.address_proof && touched.address_proof ? (
                            <span className="text-xs text-red-500">
                              {errors.address_proof}
                            </span>
                          ) : null}
                          {values.address_proof && (
                            <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                              <p className="text-[#525D68] text-xs my-2 flex justify-center">
                                (Supported format: PDF, PNG, JPG only, max 2 MB)
                              </p>
                              <ImagePreview file={values.address_proof} />
                            </div>
                          )}
                        </div>
                      </div>

                      <div className="flex flex-wrap -mx-3 mt-6">
                        <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                          <Label text="Document Type" isRequired={false} />
                          <p className="border border-[#DBE4F2] mt-2 py-3 px-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1">
                            Account Form
                          </p>
                        </div>
                        <div className="w-full md:w-2/5 px-3 mb-6 mt-1 md:mb-0">
                          <label className="block text-sm mb-2 text-dark">
                            Upload File
                          </label>

                          <input
                            ref={accountFormRef}
                            hidden
                            type="file"
                            name="acc_form"
                            onChange={(e) => {
                              const file = e.target.files[0];
                              if (file.size > 2 * 1024 * 1024) {
                                toast.error("File size exceeds 2MB limit.", {
                                  position: "top-right",
                                });
                              } else {
                                setFieldValue("acc_form", file);
                              }
                            }}
                            accept="image/png, image/jpg, image/jpeg, application/pdf"
                            multiple
                          />
                          <button
                            type="button"
                            className="border border-[#DBE4F2] w-full px-20 py-3 text-sm rounded-lg text-[#3C81FC] bg-[#EAEFF8]"
                            onClick={() => {
                              accountFormRef.current.click();
                            }}
                          >
                            Browse...
                          </button>

                          {values.acc_form && (
                            <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                              <p className="text-[#525D68] text-xs my-2 flex justify-center">
                                (Supported format: PDF, PNG, JPG only, max 2 MB)
                              </p>
                              <ImagePreview file={values.acc_form} />
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  )}

                  {values.customer_type === "General" && (
                    <div className="mt-6 p-6 rounded-md shadow-md shadow-blue-100/70 border border-blue-100">
                      <div className="flex items-center space-x-4 mb-6">
                        <Checkbox name="make_loyalty_customer" />
                        <label className="text-xl font-semibold">
                          Make loyalty customer
                        </label>
                      </div>
                      {values.make_loyalty_customer && (
                        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                          <div className="mb-6">
                            <Label text="Customer name" isRequired={true} />
                            <TextInput name="name" disabled={true} />
                          </div>

                          <div className="mb-6">
                            <Label text="Mobile No" isRequired={true} />
                            <TextInput name="mobile_no" disabled={true} />
                          </div>

                          <div className="mb-6">
                            <Label text="Barcode number" isRequired={true} />
                            <TextInput
                              placeholder="Enter barcode number"
                              name="barcode_no"
                              isError={errors.barcode_no && touched.barcode_no}
                            />
                            {errors.barcode_no && touched.barcode_no ? (
                              <span className="text-xs text-red-500">
                                {errors.barcode_no}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      )}
                    </div>
                  )}
                </div>
              </div>
            </div>

            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                // onClick={(event) => {
                //   event.preventDefault();
                //   handleReset();
                // }}
                type="reset"
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormSupplier;
