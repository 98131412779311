import React, { useState } from "react";
import { Header, Table1 } from "../../../../components";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";





const DabitNote = () => {
    const params = useParams();
    const breadCrumbs = [
        { key: 0, name: "Contact", to: "/contact/Supplier", active: false },
        { key: 1, name: "Supplier", to: "/contact/Supplier", active: false },
        {
            key: 2,
            name: "Preview",
            to: "/contact/Supplier/view",
            active: true,
        },
    ];

    const data = React.useMemo(
        () => [
            {
                col1: "001",
                col2: "123-456",
                col3: "12 June 2022",
                col4: "£50.00",
                col5: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,
            },

            {
                col1: "002",
                col2: "526-569",
                col3: "10 June 2022",
                col4: "£100.5",
                col5: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,
            },
            {
                col1: "003",
                col2: "125-696",
                col3: "10 June 2022",
                col4: "£105.00",
                col5: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,

            },
        ],
        []
    );


    const columns = React.useMemo(
        () => [

            {
                Header: "#No",
                accessor: "col1", // accessor is the "key" in the data
            },
            {
                Header: "  Purchase no",
                accessor: "col2",
            },
            {
                Header: "Purchase date",
                accessor: "col3",
            },
            {
                Header: " Amount",
                accessor: "col4",
            },


            {
                Header: "",
                accessor: "col5",
            },
        ],
        []
    );

    return (
        <>
            <Header breadCrumbs={breadCrumbs} title={`Preview supplier details ${data[1].col2}`} />
            <div className="flex items-center mx-6 my-6 space-x-8">
                <Link
                    to={`/contact/supplier-details/${params.id}`}
                    className="text-sm pb-2"
                >
                    Basic details
                </Link>
                <Link
                    to={`/contact/supplier-bill/${params.id}`}
                    className="text-sm pb-2"
                >
                    Supplier bill
                </Link>
                <Link
                    to={`/contact/dabit-note/${params.id}`}
                    className="text-sm font-bold border-b-2 border-[#3C81FC] pb-2"
                >
                    Dabit note
                </Link>
                <Link
                    to={`/contact/product-list/${params.id}`}
                    className="text-sm pb-2"
                >
                    Product list
                </Link>
            </div>

            <Table1 columns={columns} data={data} />

        </>
    )
}


export default DabitNote;