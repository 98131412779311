import React from "react";
import {
  Header,
  Label,
  SingleImagePreview,
  TextInput,
} from "../../../../components";
import { Formik, Form } from "formik";
import { useState } from "react";
import * as Yup from "yup";
import NoUpload from "../../../../assets/images/no-upload.svg";
import { BiPlus } from "react-icons/bi";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";
import { useAsyncEffect } from "use-async-effect";

const CustomerDisplay = () => {
  const navigate = useNavigate();
  const breadCrumbs = [
    {
      key: 0,
      name: "Customer Display",
      to: "/settings/customer-display",
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    image: "",
    description: "",
    url: "",
  });

  const schema = Yup.object().shape({
    image: Yup.mixed(),
    description: Yup.string().required("Description is required"),
    url: Yup.string(),
  });

  const [formType, setFormType] = useState("");
  const [imageURL, setImageURL] = useState("");

  const getById = async () => {
    try {
      const response = await api.getDisplay({});
      if (
        (response.status === 200 || response.status === 201) &&
        response.data.data.length > 0
      ) {
        setFormType("update");
        setInitialValues(response.data.data[0]);
        setImageURL(response.data.url);
      } else {
        setFormType("create");
      }
    } catch (err) {
      console.error("Err :>", err);
    }
  };

  useAsyncEffect(async () => {
    await getById();
  }, []);

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Customer Display" />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={schema}
        onSubmit={async (values) => {
          let formData = new FormData();
          for (var key in values) {
            formData.append(key, values[key]);
          }

          if (formType === "create") {
            try {
              const response = await api.createUserDisplay(formData);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                // navigate("/dashboard");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (formType === "update") {
            try {
              const response = await api.updateDisplay(formData);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                // navigate("/dashboard");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="grid grid-cols-3 grid-flow-row gap-x-10 mb-6">
                <div className="col-span-3 flex items-center space-x-8">
                  {typeof values.image == "object" && (
                    <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                      <SingleImagePreview file={values.image} />
                    </div>
                  )}
                
                  {values.image === "" && (
                    <img
                      src={NoUpload}
                      className={`${
                        errors.image && touched.image
                          ? "border border-red-500 rounded-md"
                          : null
                      }`}
                      alt=""
                    />
                  )}
                  {typeof values.image === "string" &&
                    formType === "update" && (
                      <img
                        src={`${imageURL}${values.image}`}
                        className={`w-40 border rounded-md`}
                        alt=""
                      />
                    )}
                  <label
                    htmlFor="upload"
                    className="flex flex-row items-center gap-2 cursor-pointer"
                  >
                    <BiPlus className="text-xl text-[#3C81FC]" />
                    <span className="text-sm text-[#3C81FC]">Add Image</span>
                  </label>
                  <input
                    id="upload"
                    type="file"
                    className="hidden"
                    name="image"
                    onChange={(e) => {
                      setFieldValue("image", e.target.files[0]);
                    }}
                  />
                </div>
                {errors.image && touched.image ? (
                  <span className="text-xs mt-2 text-red-500">
                    {errors.image}
                  </span>
                ) : null}
              </div>

              <div className="grid grid-cols-3 grid-flow-row gap-x-10 mb-6">
                <div>
                  <Label text="URL" isRequired={false} />
                  <TextInput
                    placeholder="Enter Account number"
                    name="url"
                    isError={errors.url && touched.url}
                  />
                  {errors.url && touched.url ? (
                    <span className="text-xs text-red-500">{errors.url}</span>
                  ) : null}
                </div>

                <div>
                  <Label text="Description" isRequired={false} />
                  <TextInput
                    placeholder="Enter Description"
                    name="description"
                    isError={errors.description && touched.description}
                  />
                  {errors.description && touched.description ? (
                    <span className="text-xs text-red-500">
                      {errors.description}
                    </span>
                  ) : null}
                </div>
              </div>

              <div className="p-6 flex items-center gap-x-4 w-full justify-end">
                <button
                  type="submit"
                  className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
                >
                  Save
                </button>
                <button
                  type="reset"
                  className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
                >
                  Cancel
                </button>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default CustomerDisplay;
