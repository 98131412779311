import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import {
  Datepicker,
  Header,
  Label,
  TextEmail,
  TextInput,
} from "../../../../components";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import moment from "moment";

const FormSupplier = () => {
  const navigate = useNavigate();
  const params = useParams();

  const breadCrumbs = [
    { key: 0, name: "Contact", to: "", active: false },
    { key: 1, name: "Supplier", to: "/contact/supplier", active: false },
    {
      key: 2,
      name: `${params.type === "add" ? "Add Supplier" : "Edit Supplier"}`,
      to: `${params.type} === "add" ? '/contact/supplier/add' : '/contact/supplier/edit/${params.id}}'`,
      active: true,
    },
  ];
  const [birthDate, onBirthDateChange] = useState("");
  console.log("birthDate", birthDate);
  const [firstTimePurchaseDate, onFirstTimePurchaseDateChange] = useState(
    new Date()
  );

  const [initialValues, setInitialValues] = useState({
    company_name: "",
    owner_name: "",
    contact_person: "",
    email: "",
    contact_person_mobile_no: "",
    telephone_no: "",
    whatsapp_no: "",
    birth_date: birthDate,
    vat_no: "",
    date_of_first_time_purchase: firstTimePurchaseDate,
    reference: "",
    addresses: [
      {
        address_line_1: "",
        address_line_2: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
      },
    ],
    account_no: "",
    branch_name: "",
    sort_code: "",
  });

  useAsyncEffect(async () => {
    if (params.type === "edit" && params.id !== "") {
      try {
        const response = await api.getSupplierById({ id: params.id });
        if (response.status === 200) {
          console.log("data=======>>>", response.data.data[0]);
          delete response.data.data[0].created_at;
          delete response.data.data[0].updated_at;

          setInitialValues(response.data.data[0]);
          //onFirstTimePurchaseDateChange(new Date(response.data.data[0].date_of_first_time_purchase));
          console.log("response :>", response.data.data[0]);
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  const SupplierSchema = Yup.object().shape({
    company_name: Yup.string().required("Company name is required"),
    owner_name: Yup.string().required("Owner name is required"),
    contact_person: Yup.string().required("Contact person is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    contact_person_mobile_no: Yup.string().required(
      "Contact number is required"
    ),
    telephone_no: Yup.string(),
    whatsapp_no: Yup.string(),
    // birth_date: Yup.string().required(
    //   "BirthDate required"
    // ),
    vat_no: Yup.string(),
    date_of_first_time_purchase: Yup.date(),
    reference: Yup.string().required("Reference is required"),
    addresses: Yup.array().of(
      Yup.object().shape({
        address_line_1: Yup.string().required("Please enter valid address"),
        address_line_2: Yup.string().required("Please enter valid address"),
        country: Yup.string().required("Please enter country"),
        state: Yup.string().required("Please enter state"),
        city: Yup.string().required("Please enter city"),
        pincode: Yup.string(),
      })
    ),
    account_no: Yup.string().required("Account number is required"),
    branch_name: Yup.string().required("Branch name is required"),
    sort_code: Yup.string().required("SORT code is required"),
  });

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add new supplier" />
      <Formik
        initialValues={initialValues}
        validationSchema={SupplierSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          console.log("VALUES", values);
          values.date_of_first_time_purchase = new Date(
            values.date_of_first_time_purchase
          )
            .toISOString()
            .split("T")[0];
          values.addresses = JSON.stringify(values.addresses);
          if (!values.birth_date) {
            delete values.birth_date;
          }
          let formData = new FormData();
          console.log("formData1", values);

          for (var key in values) {
            const valueData = values[key] == null ? "" : values[key];
            formData.append(key, valueData);
          }

          if (params.type === "add") {
            try {
              const response = await api.createSupplier(formData);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/contact/supplier");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (params.type === "edit" && params.id !== "") {
            formData.append("id", params.id);
            try {
              const response = await api.updateSupplier(formData);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/contact/supplier");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100 ">
              <div className="mb-6">
                <p className="text-xl font-semibold">Supplier Information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Company name" isRequired={true} />
                  <TextInput
                    placeholder="Enter Company name"
                    name="company_name"
                    isError={errors.company_name && touched.company_name}
                  />
                  {errors.company_name && touched.company_name ? (
                    <span className="text-xs text-red-500">
                      {errors.company_name}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Owner name" isRequired={true} />
                  <TextInput
                    placeholder="Enter Owner name"
                    name="owner_name"
                    isError={errors.owner_name && touched.owner_name}
                  />
                  {errors.owner_name && touched.owner_name ? (
                    <span className="text-xs text-red-500">
                      {errors.owner_name}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Contact person" isRequired={true} />
                  <TextInput
                    placeholder="Enter Contact person name"
                    name="contact_person"
                    isError={errors.contact_person && touched.contact_person}
                  />
                  {errors.contact_person && touched.contact_person ? (
                    <span className="text-xs text-red-500">
                      {errors.contact_person}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Email" isRequired={true} />
                  <TextEmail
                    placeholder="Enter email"
                    name="email"
                    isError={errors.email && touched.email}
                  />
                  {errors.email && touched.email ? (
                    <span className="text-xs text-red-500">{errors.email}</span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label
                    text="Contact person mobile number"
                    isRequired={true}
                  />
                  <TextInput
                    placeholder="Enter Contact person mobile number"
                    name="contact_person_mobile_no"
                    isError={
                      errors.contact_person_mobile_no &&
                      touched.contact_person_mobile_no
                    }
                  />
                  {errors.contact_person_mobile_no &&
                  touched.contact_person_mobile_no ? (
                    <span className="text-xs text-red-500">
                      {errors.contact_person_mobile_no}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Telephone number" isRequired={false} />
                  <TextInput
                    placeholder="Enter Telephone number"
                    name="telephone_no"
                    isError={errors.telephone_no && touched.telephone_no}
                  />
                  {errors.telephone_no && touched.telephone_no ? (
                    <span className="text-xs text-red-500">
                      {errors.telephone_no}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Whatsapp number" isRequired={false} />
                  <TextInput
                    placeholder="Enter Whatsapp number"
                    name="whatsapp_no"
                    isError={errors.whatsapp_no && touched.whatsapp_no}
                  />
                  {errors.whatsapp_no && touched.whatsapp_no ? (
                    <span className="text-xs text-red-500">
                      {errors.whatsapp_no}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Date of birth" isRequired={false} />
                  <Datepicker
                    value={birthDate}
                    name="birth_date"
                    // isError={errors.birth_date && touched.birth_date}
                    onNewChange={(date) => {
                      setFieldValue(
                        "birth_date",
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                  />

                  {/* {errors.birth_date && touched.birth_date ? (
                    <span className="text-xs text-red-500">
                      {errors.birth_date}
                    </span>
                  ) : null} */}
                </div>
                <div className="mb-6">
                  <Label text="VAT number" isRequired={false} />
                  <TextInput
                    placeholder="Enter VAT number"
                    name="vat_no"
                    isError={errors.vat_no && touched.vat_no}
                  />
                  {errors.vat_no && touched.vat_no ? (
                    <span className="text-xs text-red-500">
                      {errors.vat_no}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label
                    text="Date of First time purchase"
                    isRequired={false}
                  />
                  <Datepicker
                    value={firstTimePurchaseDate}
                    name="date_of_first_time_purchase"
                    isError={
                      errors.date_of_first_time_purchase &&
                      touched.date_of_first_time_purchase
                    }
                    onNewChange={(date) => {
                      console.log(date);
                      setFieldValue(
                        "date_of_first_time_purchase",
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                  />
                  {errors.date_of_first_time_purchase &&
                  touched.date_of_first_time_purchase ? (
                    <span className="text-xs text-red-500">
                      {errors.date_of_first_time_purchase}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Reference" isRequired={true} />
                  <TextInput
                    placeholder="Enter Reference"
                    name="reference"
                    isError={errors.reference && touched.reference}
                  />
                  {errors.reference && touched.reference ? (
                    <span className="text-xs text-red-500">
                      {errors.reference}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/70 border border-blue-100 ">
              <div className="mb-6 flex items-center justify-between">
                <p className="text-xl font-semibold">Address information</p>
              </div>
              <FieldArray
                name="addresses"
                render={({ insert, remove, push }) => (
                  <>
                    {values.addresses.length > 0 &&
                      values.addresses.map((item, index) => (
                        <div
                          className={`${
                            values.addresses.length > 1
                              ? "border-b  mb-4"
                              : null
                          } grid grid-cols-3 grid-flow-row gap-x-10`}
                          key={index}
                        >
                          <div className="mb-6">
                            <Label text="Address Line 1" isRequired={true} />
                            <TextInput
                              placeholder="Enter Address Line 1"
                              name={`addresses.${index}.address_line_1`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].address_line_1 &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].address_line_1
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].address_line_1 &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].address_line_1 ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].address_line_1}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="Address Line 2" isRequired={true} />
                            <TextInput
                              placeholder="Enter Address Line 2"
                              name={`addresses.${index}.address_line_2`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].address_line_2 &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].address_line_2
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].address_line_2 &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].address_line_2 ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].address_line_2}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="Country" isRequired={true} />
                            <TextInput
                              placeholder="Enter Country"
                              name={`addresses.${index}.country`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].country &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].country
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].country &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].country ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].country}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="State" isRequired={true} />
                            <TextInput
                              placeholder="Enter state"
                              name={`addresses.${index}.state`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].state &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].state
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].state &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].state ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].state}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="City" isRequired={true} />
                            <TextInput
                              placeholder="Enter city"
                              name={`addresses.${index}.city`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].city &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].city
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].city &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].city ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].city}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="Pincode" isRequired={false} />
                            <TextInput
                              placeholder="Enter Pincode"
                              name={`addresses.${index}.pincode`}
                            />
                          </div>
                        </div>
                      ))}
                    <div
                      className="cursor-pointer flex items-center space-x-4"
                      onClick={() =>
                        push({
                          address_line_1: "",
                          address_line_2: "",
                          country: "",
                          state: "",
                          city: "",
                          pincode: "",
                        })
                      }
                    >
                      <BiPlusCircle className="text-xl text-[#3C81FC]" />
                      <span className="text-base font-semibold text-[#3C81FC]">
                        Add new address
                      </span>
                    </div>
                  </>
                )}
              />
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/70 border border-blue-100 ">
              <div className="mb-6 flex items-center justify-between">
                <p className="text-xl font-semibold">Bank information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Account number" isRequired={true} />
                  <TextInput
                    placeholder="Enter Account number"
                    name="account_no"
                    isError={errors.account_no && touched.account_no}
                  />
                  {errors.account_no && touched.account_no ? (
                    <span className="text-xs text-red-500">
                      {errors.account_no}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Branch Name" isRequired={true} />
                  <TextInput
                    placeholder="Enter Branch Name"
                    name="branch_name"
                    isError={errors.branch_name && touched.branch_name}
                  />
                  {errors.branch_name && touched.branch_name ? (
                    <span className="text-xs text-red-500">
                      {errors.branch_name}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="SORT Code" isRequired={true} />
                  <TextInput
                    placeholder="Enter bank SORT code"
                    name="sort_code"
                    isError={errors.sort_code && touched.sort_code}
                  />
                  {errors.sort_code && touched.sort_code ? (
                    <span className="text-xs text-red-500">
                      {errors.sort_code}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                onClick={(event) => {
                  navigate("/contact/supplier");
                  // event.preventDefault();
                  // handleReset();
                }}
                type="reset"
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormSupplier;
