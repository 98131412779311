import { Form, Formik } from "formik";
import React, { useState } from "react";
import { BiInfoCircle } from "react-icons/bi";
import { Header, Label, TextInput } from "../../../../components";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";

const LoyaltySetting = () => {
  const params = useParams();
  const breadCrumbs = [
    { key: 0, name: "Loyalty", to: "/loyalty/customer-list", active: false },
    {
      key: 1,
      name: "Settings",
      to: "/loyalty/loyalty-settings",
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    redeem_point: "",
    discount_amount: "",
    total_purchase: "",
    points: ""

  });

  const LoyaltySchema = Yup.object().shape({
    redeem_point: Yup.number().required("Please enter redeem point"),
    discount_amount: Yup.number().required("Please enter discount amount"),
    total_purchase: Yup.number().required("Please enter total purchase"),
    points: Yup.number().required("Please enter the points")

  });

  useAsyncEffect(async () => {

    try {
      const response = await api.getAllLoyalty();
      console.log("getall Loyalty :", response);
      if (response.status === 200) {
        if (response.status === 200) {
          // setInitialValues(response.data.data[0]);
        }
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      toast.error("Something went wrong! Please try again.", {
        position: "top-right",
      });
    }

  })
  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Loyalty settings" />
      <Formik
        initialValues={initialValues}
        validationSchema={LoyaltySchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          console.log(values);
          values.points = JSON.parse(localStorage.getItem("pos-admin-user")).id;




          try {
            const response = await api.createLoyalty(values);
            console.log("data after submit :", response);
            if (response.status === 200 || response.status === 201) {
              toast.success(response.data.message, { position: "top-right" });
              // navigate("/contact/customer");
            } else {
              toast.error(response.data.message, { position: "top-right" });
            }
          } catch (err) {
            toast.error("Something went wrong! Please try again.", {
              position: "top-right",
            });
          }







        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <div className="mb-3 flex items-center space-x-2">
                  <p className="text-xl font-semibold">Redeem</p>
                  <BiInfoCircle className="h-6 w-6 text-[#525D68]" />
                </div>
                <p className="text-sm text-[#525D68]">
                  Set point for amount which customer will get at the time of
                  payment, Customer will get discount according to point
                </p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Point" isRequired={true} />
                  <TextInput
                    placeholder="Enter point"
                    name="redeem_point"
                    isError={errors.redeem_point && touched.redeem_point}
                  />
                  {errors.redeem_point && touched.redeem_point ? (
                    <span className="text-xs text-red-500">
                      {errors.redeem_point}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Discount Value" isRequired={true} />
                  <TextInput
                    placeholder="Enter Discount Value*"
                    name="discount_amount"
                    isError={errors.discount_amount && touched.discount_amount}
                  />
                  {errors.discount_amount && touched.discount_amount ? (
                    <span className="text-xs text-red-500">
                      {errors.discount_amount}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <div className="mb-3 flex items-center space-x-2">
                  <p className="text-xl font-semibold">Points</p>
                  <BiInfoCircle className="h-6 w-6 text-[#525D68]" />
                </div>
                <p className="text-sm text-[#525D68]">
                  Set point for amount which customer will get at the time of
                  payment, Customer will get discount according to point
                </p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Total Purchase" isRequired={true} />
                  <TextInput
                    placeholder="Enter point"
                    name="total_purchase"
                    isError={errors.total_purchase && touched.total_purchase}
                  />
                  {errors.total_purchase && touched.total_purchase ? (
                    <span className="text-xs text-red-500">
                      {errors.total_purchase}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Point" isRequired={true} />
                  <TextInput
                    placeholder="Enter amount*"
                    name="points"
                    isError={errors.points && touched.points}
                  />
                  {errors.points && touched.points ? (
                    <span className="text-xs text-red-500">{errors.points}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleReset();
                }}
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default LoyaltySetting;
