import React from "react";
import Navbar from "../Navbar/Navbar";
import Sidebar from "../Sidebar/Sidebar";

const Layout = ({ children }) => {
  return (
    <div className="flex flex-row">
      <Sidebar />
      <main className="w-full h-screen overflow-y-auto">
        <Navbar />
        {children}
      </main>
    </div>
  );
};

export default Layout;
