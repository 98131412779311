import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import { FaRegFilePdf } from "react-icons/fa";
//import Employee from "./Employee";

const ViewEmployee = () => {
  const params = useParams();

  const [employee, setEmployee] = useState([]);

  const breadCrumbs = [
    { key: 0, name: "Contact", to: "/contact/employee", active: false },
    { key: 1, name: "Employee", to: "/contact/employee", active: false },
    {
      key: 2,
      name: "Preview",
      to: "/contact/employee/view",
      active: true,
    },
  ];
  useAsyncEffect(async () => {
    if (params.id !== "") {
      try {
        const response = await api.getEmployeeById({ id: params.id });
        if (response.status === 200) {
          setEmployee(response.data.data[0]);
          console.log("RESPONSE DATA", response.data.data[0]);
     
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title={`Preview Employee details (${employee.name})`}/>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Employee information</p>
        </div>
        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Employee name</p>
            <p className="text-base text-[#142243]">{employee.name}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Email ID</p>
            <p className="text-base text-[#142243]">{employee.email}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Mobile number</p>
            <p className="text-base text-[#142243]">{employee.mobile_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">NIA number</p>
            <p className="text-base text-[#142243]">{employee.nia_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Wages</p>
            <p className="text-base text-[#142243]">{employee.wages}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Employee as</p>
            <p className="text-base text-[#142243]">{employee.employee_type}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Employment type</p>
            <p className="text-base text-[#142243]">
              {employee.employment_type}
            </p>
          </div>
        </div>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Address information</p>
        </div>

        {employee.addresses &&
          employee.addresses.length > 0 &&
          employee.addresses.map((item, key) => (
            <div className="grid grid-cols-3 grid-flow-row gap-x-10" key={key}>
              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">Address Line 1</p>
                <p className="text-base text-[#142243]">
                  {item.address_line_1}
                </p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">Address Line 2</p>
                <p className="text-base text-[#142243]">
                  {item.address_line_2}
                </p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">Country</p>
                <p className="text-base text-[#142243]">{item.country}</p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">State</p>
                <p className="text-base text-[#142243]">{item.state}</p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">City</p>
                <p className="text-base text-[#142243]">{item.city}</p>
              </div>

              <div className="mb-6">
                <p className="text-sm text-[#525D68] mb-3">Pincode</p>
                <p className="text-base text-[#142243]">{item.pincode}</p>
              </div>
            </div>
          ))}
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Bank information</p>
        </div>

        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Account number</p>
            <p className="text-base text-[#142243]">{employee.account_no}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Branch Name</p>
            <p className="text-base text-[#142243]">{employee.branch_name}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">SORT Code</p>
            <p className="text-base text-[#142243]">
              {employee.bank_sort_code}
            </p>
          </div>
        </div>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Documents</p>
        </div>
        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="m-3 p-3 flex items-center space-x-2 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
            <FaRegFilePdf className="text-xl text-red-500" />
            <span className="text-base text-[#142243]">
              {employee.address_proof}
            </span>
          </div>

          <div className="m-3 p-3 flex items-center space-x-2 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
            <FaRegFilePdf className="text-xl text-red-500" />
            <span className="text-base text-[#142243]">
              {employee.id_proof}
            </span>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewEmployee;
