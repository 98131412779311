import React, { useState } from "react";
import { Header, Table } from "../../../../components";
import { BiPencil, BiTrash } from "react-icons/bi";
import { GoCloudDownload } from "react-icons/go";
import { HiOutlineAdjustments } from "react-icons/hi";
import { BsEyeFill } from "react-icons/bs";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import { Link } from "react-router-dom";
import "jspdf-autotable";
import { Field, Form, Formik } from "formik";
import {
  Label,
  Modal,
  TextPassword,
  TextInput,
} from "../../../../../../admin/src/components";
import { VscEye } from "react-icons/vsc";
import * as Yup from "yup";
import { confirmAlert } from 'react-confirm-alert';
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Product = () => {
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    { key: 1, name: "Product", to: "/inventory/product", active: true },
  ];

  const downloadPdf = [
    {
      title: "Download PDF",
      icon: <GoCloudDownload className="text-[#525D68] text-lg" />,
    },
  ];

  const [adjustStock, setAdjustStock] = useState(false);
  const [adjustStockDetails, setAdjustStockDetails] = useState(false);
  const [selectedProduct, setSelectedProduct] = useState();

  const [products, setProducts] = useState([]);
  let [imageURL, setImageURL] = useState("");
  const [passwordShown, setPasswordShown] = useState(false);

  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const addStockQnt = (data) => {
    setSelectedProduct(data);
    setAdjustStock(true);
  };

  const [initialValues, setInitialValues] = useState({
    batch_number: "",
    out_qty: "",
    in_qty: "",
  });

  const AdjustStockSchema = Yup.object().shape({
    password: Yup.string().required("password is required"),
  });

  const AdjustStockDetailsSchema = Yup.object().shape({
    out_qty: Yup.number().required("Out quantity is required"),
    in_qty: Yup.number().required("In quantity is required"),
  });

  const getList = async () => {
    try {
      const response = await api.getAllProducts();
      if (response.status === 200 && response.data.data?.length > 0) {
        setImageURL(response.data.url);
        setProducts(response.data.data);
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  useAsyncEffect(async () => {
    // console.log("Call from here");
    await getList();
  }, []);

  const data = React.useMemo(() => {
    const formattedData = [];
    const confirmDelete = (id) => {
      confirmAlert({
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => deleteItem(id),
          },
          {
            label: "No",
          },
        ],
      });
    };
    const deleteItem = async (id) => {
      try {
        const response = await api.deleteProduct({ id });
        if (response.status === 200) {
          toast.success(response.data.message, { position: "top-right" });
          await getList();
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        console.log("Err:", err);
      }
    };

    products.map((data, index) =>
      formattedData.push({
        col1: index + 1,
        col2: (
          <img
            src={`${imageURL}${data.image}`}
            alt="no-img"
            className="w-6"
          />
        ),
        col3: data.barcode,
        col4: data.product_name,
        col5: data.department_name,
        col6: data.brand_name,
        col7: data.batch_number,
        col8: `${data.mrp}`,
        col9: `${data.wholesaler_price}`,
        col10: `${data.wholesale_box_product_quantity}`,
        col11: (
          <div className="flex items-center gap-2 cursor-pointer">
            <div className="w-3 h-3 rounded-full bg-green-600"></div>
            <div>{data.show_in_pos === "yes" ? "Active" : "Inactive"}</div>
          </div>
        ),
        col12: (
          <div className="flex items-center space-x-4">
            <Link
              className="cursor-pointer"
              to={`/inventory/product-details/${data.id}`}
            >
              <BsEyeFill className="text-xl text-[#3C81FC]" />
            </Link>
            <Link
              className="cursor-pointer"
              to={`/inventory/product/edit/${data.id}`}
            >
              <BiPencil className="text-xl text-[#3C81FC]" />
            </Link>
            <p className="cursor-pointer" onClick={() => confirmDelete(data.id)}>
              <BiTrash className="text-xl text-red-500" />
            </p>

            <p className="cursor-pointer" onClick={() => addStockQnt(data)}>
              <HiOutlineAdjustments className="text-xl text-[#3C81FC] cursor-pointer" />
            </p>
          </div>
        ),
      })
    );
    return formattedData;
  }, [imageURL, products]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Image",
        accessor: "col2",
      },
      {
        Header: "Bar code",
        accessor: "col3",
      },
      {
        Header: "Name",
        accessor: "col4",
      },
      {
        Header: "Department",
        accessor: "col5",
      },
      {
        Header: "Brand",
        accessor: "col6",
      },
      {
        Header: "Batch No.",
        accessor: "col7",
      },
      {
        Header: "MRP",
        accessor: "col8",
      },
      {
        Header: "HSP",
        accessor: "col9",
      },
      {
        Header: "Qty",
        accessor: "col10",
      },
      {
        Header: "Show in POS",
        accessor: "col11",
      },
      {
        Header: "",
        accessor: "col12",
      },
    ],
    []
  );

  const addNewData = [
    {
      title: "Add New Product",
      to: "/inventory/product/add",
    },
  ];

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Product" />

      <Table
        columns={columns}
        data={data}
        addData={addNewData}
        downloadPdf={downloadPdf}
        pdfData={products}
        priceListModal={true}
      />

      {adjustStock && (
        <Modal setIsOpen={setAdjustStock}>
          <div className="h-auto p-6 w-[25rem]">
            <Formik
              initialValues={{
                password: "",
              }}
              validationSchema={AdjustStockSchema}
              enableReinitialize={true}
              onSubmit={async (values) => {
                values.id = JSON.parse(
                  localStorage.getItem("pos-admin-user")
                ).id;

                try {
                  const response = await api.checkPassValid(values);
                  if (response.data.status === 200) {
                    setAdjustStock(false);
                    setAdjustStockDetails(true);
                  } else {
                    toast.error(response.data.message, {
                      position: "top-right",
                    });
                  }
                } catch (err) {
                  console.log("Err:", err);
                }
              }}
            >
              {({ errors, touched, values, handleReset, setFieldValue }) => (
                <Form>
                  <div className="">
                    <div className="mb-2">
                      <p className="text-xl font-semibold">Adjust Stock</p>
                      <div className="text-muted">
                        <p>Please enter your password</p>
                      </div>
                    </div>

                    <div>
                      <div className="mb-12 relative">
                        <Label text="Password" isRequired={true} />
                        <TextPassword
                          placeholder="**********"
                          name="password"
                          type={passwordShown ? "text" : "password"}
                          isError={errors.password && touched.password}
                        />
                        <div className="absolute right-4 top-12">
                          <VscEye
                            className={
                              passwordShown
                                ? "text-blue-900 cursor-pointer"
                                : "text-gray-500 cursor-pointer"
                            }
                            onClick={togglePasswordVisibility}
                          />
                        </div>
                        {errors.password && touched.password ? (
                          <span className="text-xs text-red-500">
                            {errors.password}
                          </span>
                        ) : null}
                      </div>

                      <div className="mb-2 flex items-center gap-x-4 mt-8">
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            handleReset();
                          }}
                          className="border border-[#1267FF] py-3 px-12 !text-[#1267FF] rounded-full w-52"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="bg-[#1267FF] py-3 px-12 !text-white rounded-full w-52"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}

      {/* Adjust Stock Batch */}

      {adjustStockDetails && (
        <Modal setIsOpen={setAdjustStockDetails}>
          <div className="h-auto p-6 w-[25rem]">
            <Formik
              initialValues={initialValues}
              validationSchema={AdjustStockDetailsSchema}
              enableReinitialize={true}
              onSubmit={async (values) => {
                values.id = selectedProduct.id;
                values.batch_number = selectedProduct.batch_number;
                values.account_id = JSON.parse(
                  localStorage.getItem("pos-admin-user")
                ).id;

                try {
                  const response = await api.adjustStock(values);
                  if (response.status === 200) {
                    setAdjustStockDetails(false);
                    toast.success(response.data.message, {
                      position: "top-right",
                    });
                  } else {
                    toast.error(response.data.message, {
                      position: "top-right",
                    });
                  }
                } catch (err) {
                  console.log("Err:", err);
                }
              }}
            >
              {({ errors, touched, handleReset, setFieldValue }) => (
                <Form>
                  <div className="">
                    <div className="mb-2">
                      <p className="text-xl font-semibold">Adjust Stock</p>
                      <div className="text-muted">
                        <p>Please enter details</p>
                      </div>
                    </div>

                    <div>
                      <div className="mb-6">
                        <Label text="Select Batch" isRequired={true} />
                        <Field
                          className={`${
                            errors.batch_number && touched.batch_number
                              ? "!border-red-500"
                              : null
                          } border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
                          placeholder="Batch Number"
                          value={selectedProduct.batch_number}
                          name="batch_number"
                          disabled={true}
                        />
                        {errors.batch_number && touched.batch_number ? (
                          <span className="text-xs text-red-500">
                            {errors.batch_number}
                          </span>
                        ) : null}
                      </div>

                      <div className="grid grid-cols-2 grid-flow-row gap-x-10 mb-6">
                        <div className="mb-6">
                          <Label text="In Qut" isRequired={true} />
                          <TextInput
                            placeholder="In Qut"
                            name="in_qty"
                            isError={errors.in_qty && touched.in_qty}
                          />
                          {errors.in_qty && touched.in_qty ? (
                            <span className="text-xs text-red-500">
                              {errors.in_qty}
                            </span>
                          ) : null}
                        </div>

                        <div className="mb-6">
                          <Label text="Out Qut" isRequired={true} />
                          <TextInput
                            placeholder="out_qty"
                            name="out_qty"
                            isError={errors.out_qty && touched.out_qty}
                          />
                          {errors.out_qty && touched.out_qty ? (
                            <span className="text-xs text-red-500">
                              {errors.out_qty}
                            </span>
                          ) : null}
                        </div>
                      </div>
                      <div className="mb-2 flex items-center gap-x-4 mt-8">
                        <button
                          onClick={(event) => {
                            event.preventDefault();
                            handleReset();
                          }}
                          className="border border-[#1267FF] py-3 px-12 !text-[#1267FF] rounded-full w-52"
                        >
                          Cancel
                        </button>
                        <button
                          type="submit"
                          className="bg-[#1267FF] py-3 px-12 !text-white rounded-full w-52"
                        >
                          Submit
                        </button>
                      </div>
                    </div>
                  </div>
                </Form>
              )}
            </Formik>
          </div>
        </Modal>
      )}
    </>
  );
};

export default Product;
