import React, { useState } from "react";
import { BiPencil, BiTrash } from "react-icons/bi";
import { Header, Table } from "../../../../components";
import { Link } from "react-router-dom";
import { useAsyncEffect } from "use-async-effect";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";

const DepartmentOrBrand = () => {
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    {
      key: 1,
      name: "Department",
      to: "/inventory/department",
      active: true,
    },
  ];

  const [departments, setDepartments] = useState([]);
  let [imageURL, setImageURL] = useState("");

  const getList = async () => {
    try {
      const response = await api.getAllDepartments();
      if (response.status === 200) {
        setImageURL(response.data.url);
        setDepartments(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };
  useAsyncEffect(async () => {
    await getList();
  }, []);
  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteItem(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteItem = async (id) => {
    try {
      const response = await api.deleteDepartment({ id });
      if (response.status === 200) {
        toast.success(response.data.message, { position: "top-right" });
        await getList();
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const data = React.useMemo(() => {
    const formattedData = [];

    departments?.map((data, index) =>
      formattedData.push({
        col1: index + 1,
        col2: (
          <img src={`${imageURL}${data.image}`} alt="no-img" className="w-6" />
        ),
        col3: data.name,
        col4: data.code,
        col5: data.description,
        col6: (
          <div className="flex items-center space-x-4">
            <Link
              className="cursor-pointer"
              to={`/inventory/department/edit/${data.id}`}
            >
              <BiPencil className="text-xl text-[#3C81FC]" />
            </Link>
            <p
              className="cursor-pointer"
              onClick={() => confirmDelete(data.id)}
            >
              <BiTrash className="text-xl text-red-500" />
            </p>
          </div>
        ),
      })
    );

    return formattedData;
  }, [departments, imageURL]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Image",
        accessor: "col2",
      },
      {
        Header: "Name",
        accessor: "col3",
      },
      {
        Header: "Code",
        accessor: "col4",
      },
      {
        Header: "Description",
        accessor: "col5",
      },
      {
        Header: "",
        accessor: "col6",
      },
    ],
    []
  );

  const addNewData = [
    {
      title: "Add Department",
      to: "/inventory/department/add",
    },
  ];
  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Department / Brand" />
      <div className="flex items-center mx-6 my-6 space-x-16">
        <Link
          to="/inventory/department"
          className="text-base border-b-2 border-[#3C81FC] pb-2"
        >
          Department
        </Link>
        <Link to="/inventory/brand" className="text-base pb-2">
          Brand
        </Link>
      </div>
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};

export default DepartmentOrBrand;
