import React, { useState } from "react";
import { useParams } from "react-router-dom";
import { Header } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import { FaRegFilePdf } from "react-icons/fa";
import Recipe from "./Recipe";

const ViewRecipe = () => {
  const params = useParams();
  const [recipe, setRecipe] = useState([]);
  console.log("recipe++++++++++", recipe.raw_products);

  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    { key: 1, name: "Recipe", to: "/inventory/recipe", active: false },
    {
      key: 2,
      name: "Preview",
      to: "/inventory/recipe-details/view",
      active: true,
    },
  ];
  useAsyncEffect(async () => {
    if (params.id !== "") {
      try {
        const response = await api.getRecipeById({ id: params.id });
        console.log("response", response);
        if (response.status === 200) {
          setRecipe(response.data.data[0]);
          console.log("RESPONSE DATA", response.data.data[0]);
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Preview Employee details" />

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Recipe information</p>
        </div>

        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Recipe name</p>
            <p className="text-base text-[#142243]">{recipe.name}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Recipe date</p>
            <p className="text-base text-[#142243]">{recipe.recipe_date}</p>
          </div>

          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Recipe type</p>
            <p className="text-base text-[#142243]">{recipe.type}</p>
          </div>
        </div>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Raw product</p>
        </div>
        <table className="min-w-full table-fixed">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                #No
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Item code
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Product
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                MRP
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Use Quantity
              </th>
            </tr>
          </thead>
          {recipe.raw_products &&
            recipe.raw_products.length > 0 &&
            recipe.raw_products.map((raw_product, index) => (
              <tbody className="bg-white divide-y divide-gray-200">
                <tr key={index + 1}>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {raw_product.code}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {raw_product.product_name}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {raw_product.mrp}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {raw_product.quantity}
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Final product</p>
        </div>
        <table className="min-w-full table-fixed">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                #No
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Item code
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Product
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                MRP
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Generate Qnt
              </th>
            </tr>
          </thead>
          {recipe.final_products &&
            recipe.final_products.length > 0 &&
            recipe.final_products.map((final_product, index) => (
              <tbody className="bg-white divide-y divide-gray-200">
                <tr key={index + 1}>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {final_product.code}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {final_product.product_name}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {final_product.mrp}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {final_product.quantity}
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Remaining product</p>
        </div>
        <table className="min-w-full table-fixed">
          <thead className="bg-gray-100">
            <tr>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                #No
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Item code
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Product
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                MRP
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Raw Qut
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Final qut
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Remaining
              </th>
              <th
                scope="col"
                className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
              >
                Status
              </th>
            </tr>
          </thead>
          {recipe.remain_products &&
            recipe.remain_products.length > 0 &&
            recipe.remain_products.map((remain_product, index) => (
              <tbody className="bg-white divide-y divide-gray-200">
                <tr key={index + 1}>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {index + 1}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                    {remain_product.code}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {remain_product.product_name}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {remain_product.mrp}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {remain_product.raw_quantity}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {remain_product.final_quantity}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {remain_product.remain_quantity}
                  </td>
                  <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                    {remain_product.status}
                  </td>
                </tr>
              </tbody>
            ))}
        </table>
      </div>
    </>
  );
};

export default ViewRecipe;
