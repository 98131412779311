import React from "react";

const SingleImagePreview = ({ file }) => {
  const [image, setImage] = React.useState("");
  if (typeof file == "string") {
    setImage(null);
  }

  if (typeof file == "object") {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function (e) {
      setImage(e.target.result);
    };
  }

  return (
    <>
      <img src={image} className="w-40 border rounded-md" alt="preview" />
    </>
  );
};

export default SingleImagePreview;
