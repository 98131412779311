import React, { useState } from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

const Datepicker = ({ onNewChange, name, isTimeRequired, isError, value }) => {
  const [startDate, setStartDate] = useState(
    typeof value == "undefined" ? value : new Date()
  );
  // console.log("startDate", startDate);
  // if(startDate != value){
  //   setStartDate(value);
  // }
  return (
    <>
      <DatePicker
        name={name}
        onChange={(date) => setStartDate(date)}
        onSelect={onNewChange}
        selected={startDate}
        showTimeSelect={isTimeRequired}
        dateFormat={isTimeRequired ? "yyyy-mm-dd HH:mm:ss" : "yyyy-MM-dd"}
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        shouldCloseOnSelect={true}
        className={`${
          isError ? "!border-red-500" : null
        } border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
      />
    </>
  );
};

export default Datepicker;
