import React,{useState} from "react";
import { BiDotsVerticalRounded,BiTrash,BiAlbum, BiPencil} from "react-icons/bi";
import { Header, Table } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { Link } from "react-router-dom";
import { useAsyncEffect } from "use-async-effect";
import moment from "moment";

const MaterialConsumption = () => {
  const [materialCons ,setMaterialCons] = useState([]);
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    {
      key: 1,
      name: "Material Consumption",
      to: "/inventory/material-consumption",
      active: true,
    },
  ];
 

  const getList = async () => {
    try {
      const response = await api.getAllMaterialConsm();
  
      if (response.status === 200) {
        setMaterialCons(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  useAsyncEffect(async () => {
    await getList();
  }, []);

  


  const data = React.useMemo(() => {

    
    const formattedData = [];
    const deleteItem = async (id) => {
     
      try {
        const response = await api.deleteMaterialConsm({ id });
       
        if (response.status === 200) {
          toast.success(response.data.message, { position: "top-right" });
          await getList();
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        console.log("Err:", err);
      }
    };

    materialCons.map((data1, index) =>
    data1.products.map((data)=>
    
    formattedData.push({
      col1: data1.id,
      col2: data1.consumption_no,
      col3: "New",
      col4:  moment(data1.consumption_date).format("MM-DD-YYYY"),
      col5: data1.consumption_type_name,
      col6: data.quantity,
      col7: data.price,
     
      col8: (
        <div className="flex items-center gap-2">
          <div className="w-3 h-3 rounded-full bg-green-600"></div>{" "}
          <div>Active</div>{" "}
        </div>
      ),
      col9: (
        <div className="flex items-center space-x-4">
          <Link
            className="cursor-pointer"
            to={`/inventory/material-consumption/edit/${data1.id}`}
          >
            <BiPencil className="text-xl text-[#3C81FC]" />
          </Link>
          <Link
            className="cursor-pointer"
            to={`/inventory/material-consumption/view/${data1.id}`}
          >
            <BiAlbum className="text-xl text-[#3C81FC]" />
          </Link>
          <p className="cursor-pointer" onClick={() => deleteItem(data1.id)}>
            <BiTrash className="text-xl text-red-500" />
          </p>
        </div>
      ),
    })
    
    )
    
    );

    return formattedData;
  }, [materialCons]);



  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Consumption No",
        accessor: "col2",
      },
      {
        Header: "Product",
        accessor: "col3",
      },
      {
        Header: "Date",
        accessor: "col4",
      },
      {
        Header: "Consumption type",
        accessor: "col5",
      },
      {
        Header: "Qnt",
        accessor: "col6",
      },
      {
        Header: "Amount",
        accessor: "col7",
      },
      {
        Header: "",
        accessor: "col8",
      },
      {
        
        Header: "",
        accessor: "col9",
      },
    ],
    []
  );

  const addNewData = [
    {
      title: "Add consumption",
      to: "/inventory/material-consumption/add",
    },
  ];

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Material consumption" />
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};

export default MaterialConsumption;
