import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { BiDotsVerticalRounded, BiPlusCircle } from "react-icons/bi";
import { useAsyncEffect } from "use-async-effect";
import {
  Header,
  Label,
  TextInput,
  SelectInput,
  Datepicker,
} from "../../../../components";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import moment from "moment";

const FormRecipe = () => {
  const params = useParams();
  const navigate = useNavigate();

  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    { key: 1, name: "Recipe", to: "/inventory/recipe", active: false },
    {
      key: 2,
      name: `${params.type === "add" ? "Add Recipe" : "Edit Recipe"}`,
      to: `${params.type} === "add" ? '/inventory/Recipe/add' : '/inventory/Recipe/edit/${params.id}}'`,
      active: true,
    },
  ];

  const allStatus = [{ name: "Remaining" }, { name: "Wastage" }];
  const [recipeDate, onRecipeDateChange] = useState(new Date());

  const [initialValues, setInitialValues] = useState({
    name: "",
    recipe_date: recipeDate,
    type: "",
    raw_products: [
      {
        // product_id: "",
        product_name: "",
        code: "",
        mrp: "",
        quantity: "",
      },
    ],
    final_products: [
      {
        // product_id: "",
        product_name: "",
        code: "",
        mrp: "",
        quantity: "",
      },
    ],
    remain_products: [
      {
        // product_id: "",
        product_name: "",
        code: "",
        mrp: "",
        raw_quantity: "",
        final_quantity: "",
        remain_quantity: "",
        status: "",
      },
    ],
  });

  const [products, setProducts] = useState([]);
  console.log("products==================================", products);

  const getProductList = async () => {
    try {
      const response = await api.getAllProducts();
      if (response.status === 200) {
        let data = response.data.data;
        setProducts(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const getAllRecipeList = async () => {
    try {
      const response = await api.getAllRecipe();
      console.log("response recipe data :", response);

      if (response.status === 200) {
        if (response.status === 200) {
          // setInitialValues(response.data.data[0]);
        }
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      toast.error("Something went wrong! Please try again.", {
        position: "top-right",
      });
    }
  };

  useAsyncEffect(async () => {
    await getAllRecipeList();
    await getProductList();
    console.log("params++++++++++++++++++++++++", params);
    if (params.type === "edit" && params.id !== "") {
      console.log("params", params);
      try {
        const response = await api.getRecipeById({ id: params.id });
        if (response.status === 200) {
          setInitialValues(response.data.data[0]);
          console.log("response :>", response.data.data[0]);
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  const updateDataAccordingProduct = (
    index,
    product_name,
    formValues,
    setFieldValue
  ) => {
    const selectedProduct = products.find(
      (value) => value.product_name == product_name
    );

    if (selectedProduct) {
      const updatedRawProducts = [...formValues.raw_products];
      updatedRawProducts[index] = {
        ...updatedRawProducts[index],
        product_name: selectedProduct.product_name,
        mrp: selectedProduct.mrp,
      };
      const updatedFinalProducts = [...formValues.final_products];
      updatedFinalProducts[index] = {
        ...updatedFinalProducts[index],
        product_name: selectedProduct.product_name,
        mrp: selectedProduct.mrp,
      };
      const updatedRemainProducts = [...formValues.remain_products];
      updatedRemainProducts[index] = {
        ...updatedRemainProducts[index],
        product_name: selectedProduct.product_name,
        mrp: selectedProduct.mrp,
      };
      setInitialValues((prev) => ({
        name: formValues.name,
        type: formValues.type,
        recipe_date: formValues.recipe_date,
        raw_products: updatedRawProducts,
        final_products: updatedFinalProducts,
        remain_products: updatedRemainProducts,
      }));
    }
  };
  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add new Recipe" />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        // validationSchema={LoginSchema}
        onSubmit={async (values) => {
          values.recipe_date = new Date(values.recipe_date)
            .toISOString()
            .split("T")[0];

          if (params.type === "add") {
            try {
              const response = await api.createRecipe(values);
              console.log("response add=========>", response);
              if (response.status === 200) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/inventory/recipe");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (params.type === "edit" && params.id !== "") {
            try {
              const response = await api.updateRecipe(values);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/inventory/recipe");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Recipe information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Recipe name" isRequired={true} />
                  <TextInput
                    placeholder="Enter Recipe name"
                    name="name"
                    isError={errors.name && touched.name}
                  />
                  {errors.name && touched.name ? (
                    <span className="text-xs text-red-500">{errors.name}</span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Recipe date" isRequired={true} />
                  <Datepicker
                    value={recipeDate || moment().format("YYYY-MM-DD")}
                    name="recipe_date"
                    isError={errors.recipe_date && touched.recipe_date}
                    onNewChange={(date) => {
                      setFieldValue(
                        "recipe_date",
                        moment(date).format("YYYY-MM-DD")
                      );
                    }}
                  />

                  {errors.recipe_date && touched.recipe_date ? (
                    <span className="text-xs text-red-500">
                      {errors.recipe_date}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Recipe type" isRequired={true} />
                  <TextInput
                    placeholder="Enter Recipe type"
                    name="type"
                    isError={errors.type && touched.type}
                  />
                  {errors.type && touched.type ? (
                    <span className="text-xs text-red-500">{errors.type}</span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Raw product</p>
              </div>
              <div>
                <FieldArray
                  name="raw_products"
                  render={({ insert, remove, push }) => (
                    <>
                      <table className="min-w-full table-fixed">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              #No
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Item code
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Product
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              MRP
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Use Quantity
                            </th>
                            <th scope="col" className="p-4"></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {values?.raw_products?.length > 0 &&
                            values?.raw_products?.map((item, index) => (
                              <tr key={index}>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  {index + 1}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Code"
                                    name={`raw_products.${index}.code`}
                                    isError={
                                      errors.raw_products &&
                                      errors.raw_products[index] &&
                                      errors.raw_products[index].code &&
                                      touched.raw_products &&
                                      touched.raw_products[index].code
                                    }
                                  />
                                  {errors.raw_products &&
                                  errors.raw_products[index] &&
                                  errors.raw_products[index].code &&
                                  touched.raw_products &&
                                  touched.raw_products[index].code ? (
                                    <span className="text-xs text-red-500">
                                      {errors.raw_products[index].code}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <SelectInput
                                    selectOptions={products}
                                    // isError={
                                    // errors?.raw_product[index]?.product_name
                                    // }
                                    name={`raw_products.${index}.product_name`}
                                    onblur={(e) => {
                                      // setFieldValue(
                                      //   `raw_products.${index}.product_name`,
                                      //   e.target.value
                                      // );
                                      // const selectedProduct = products.find(
                                      //   (value) =>
                                      //     value.product_name == e.target.value
                                      // );
                                      // if (selectedProduct) {
                                      //   const updatedRawProducts = [
                                      //     ...values.raw_products,
                                      //   ];
                                      //   updatedRawProducts[index] = {
                                      //     ...updatedRawProducts[index],
                                      //     product_name:
                                      //       selectedProduct.product_name,
                                      //     mrp: selectedProduct.mrp,
                                      //   };
                                      //   setInitialValues((prev) => ({
                                      //     ...prev,
                                      //     raw_products: updatedRawProducts,
                                      //   }));
                                      //   setFieldValue(
                                      //     "raw_products",
                                      //     updatedRawProducts
                                      //   );
                                      // } else {
                                      //   console.log("ELSEEEEE");
                                      // }
                                      updateDataAccordingProduct(
                                        index,
                                        e.target.value,
                                        values,
                                        setFieldValue
                                      );
                                    }}
                                  />

                                  {errors.raw_products &&
                                  errors.raw_products[index] &&
                                  errors.raw_products[index].product_name &&
                                  touched.raw_products &&
                                  touched.raw_products[index].product_name ? (
                                    <span className="text-xs text-red-500">
                                      {errors.raw_products[index].product_name}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  {values?.raw_products[index]?.mrp}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Quantity"
                                    name={`raw_products.${index}.quantity`}
                                    isError={
                                      errors.raw_products &&
                                      errors.raw_products[index] &&
                                      errors.raw_products[index].quantity &&
                                      touched.raw_products &&
                                      touched.raw_products[index].quantity
                                    }
                                  />
                                  {errors.raw_products &&
                                  errors.raw_products[index] &&
                                  errors.raw_products[index].quantity &&
                                  touched.raw_products &&
                                  touched.raw_products[index].quantity ? (
                                    <span className="text-xs text-red-500">
                                      {errors.raw_products[index].quantity}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-right whitespace-nowrap">
                                  <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div
                        className="cursor-pointer flex items-center space-x-2 w-32 mt-4"
                        onClick={() =>
                          push({
                            product_name: "",
                            code: "",
                            mrp: "",
                            quantity: "",
                          })
                        }
                      >
                        <BiPlusCircle className="text-xl text-[#3C81FC]" />
                        <p className="text-[#3C81FC] text-base font-semibold">
                          Add product
                        </p>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Final product</p>
              </div>
              <div>
                <FieldArray
                  name="final_products"
                  render={({ insert, remove, push }) => (
                    <>
                      <table className="min-w-full table-fixed">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              #No
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Item code
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Product
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              MRP
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Generate Qnt
                            </th>
                            <th scope="col" className="p-4"></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {values?.final_products?.length > 0 &&
                            values?.final_products?.map((item, index) => (
                              <tr key={index}>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  {index + 1}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Code"
                                    name={`final_products.${index}.code`}
                                    isError={
                                      errors.final_products &&
                                      errors.final_products[index] &&
                                      errors.final_products[index].code &&
                                      touched.final_products &&
                                      touched.final_products[index].code
                                    }
                                  />
                                  {errors.final_products &&
                                  errors.final_products[index] &&
                                  errors.final_products[index].code &&
                                  touched.final_products &&
                                  touched.final_products[index].code ? (
                                    <span className="text-xs text-red-500">
                                      {errors.final_products[index].code}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <SelectInput
                                    selectOptions={products}
                                    isError={errors.product_name}
                                    name={`final_products.${index}.product_name`}
                                    onblur={(e) => {
                                      // setFieldValue(
                                      //   `final_products.${index}.product_name`,
                                      //   e.target.value
                                      // );
                                      // const selectedProduct = products.find(
                                      //   (value) =>
                                      //     value.product_name == e.target.value
                                      // );
                                      // if (selectedProduct) {
                                      //   const updatedRawProducts = [
                                      //     ...values.final_products,
                                      //   ];
                                      //   updatedRawProducts[index] = {
                                      //     ...updatedRawProducts[index],
                                      //     product_name:
                                      //       selectedProduct.product_name,
                                      //     mrp: selectedProduct.mrp,
                                      //   };
                                      //   setInitialValues((prev) => ({
                                      //     ...prev,
                                      //     final_products: updatedRawProducts,
                                      //   }));
                                      //   setFieldValue(
                                      //     "final_products",
                                      //     updatedRawProducts
                                      //   );
                                      // }
                                      updateDataAccordingProduct(
                                        index,
                                        e.target.value,
                                        values,
                                        setFieldValue
                                      );
                                    }}
                                  />

                                  {errors.final_products &&
                                  errors.final_products[index] &&
                                  errors.final_products[index].product_name &&
                                  touched.final_products &&
                                  touched.final_products[index].product_name ? (
                                    <span className="text-xs text-red-500">
                                      {
                                        errors.final_products[index]
                                          .product_name
                                      }
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  {values?.final_products[index]?.mrp}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Quantity"
                                    name={`final_products.${index}.quantity`}
                                    isError={
                                      errors.final_products &&
                                      errors.final_products[index] &&
                                      errors.final_products[index].quantity &&
                                      touched.final_products &&
                                      touched.final_products[index].quantity
                                    }
                                  />
                                  {errors.final_products &&
                                  errors.final_products[index] &&
                                  errors.final_products[index].quantity &&
                                  touched.final_products &&
                                  touched.final_products[index].quantity ? (
                                    <span className="text-xs text-red-500">
                                      {errors.final_products[index].quantity}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-right whitespace-nowrap">
                                  <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div
                        className="cursor-pointer flex items-center space-x-2 w-32 mt-4"
                        onClick={() =>
                          push({
                            product_name: "",
                            code: "",
                            mrp: "",
                            quantity: "",
                          })
                        }
                      >
                        <BiPlusCircle className="text-xl text-[#3C81FC]" />
                        <p className="text-[#3C81FC] text-base font-semibold">
                          Add product
                        </p>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Remaining product</p>
              </div>
              <div>
                <FieldArray
                  name="remain_products"
                  render={({ insert, remove, push }) => (
                    <>
                      <table className="min-w-full table-fixed">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              #No
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Item code
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Product
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              MRP
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Raw Qut
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Final qut
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Remaining
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Status
                            </th>
                            <th scope="col" className="p-4"></th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200">
                          {values?.remain_products?.length > 0 &&
                            values?.remain_products?.map((item, index) => (
                              <tr key={index}>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  {index + 1}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Code"
                                    name={`remain_products.${index}.code`}
                                    isError={
                                      errors.remain_products &&
                                      errors.remain_products[index] &&
                                      errors.remain_products[index].code &&
                                      touched.remain_products &&
                                      touched.remain_products[index].code
                                    }
                                  />
                                  {errors.remain_products &&
                                  errors.remain_products[index] &&
                                  errors.remain_products[index].code &&
                                  touched.remain_products &&
                                  touched.remain_products[index].code ? (
                                    <span className="text-xs text-red-500">
                                      {errors.remain_products[index].code}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <SelectInput
                                    selectOptions={products}
                                    isError={errors.product_name}
                                    name={`remain_products.${index}.product_name`}
                                    onblur={(e) => {
                                      // setFieldValue(
                                      //   `remain_products.${index}.product_name`,
                                      //   e.target.value
                                      // );
                                      // const selectedProduct = products.find(
                                      //   (value) =>
                                      //     value.product_name == e.target.value
                                      // );
                                      // if (selectedProduct) {
                                      //   const updatedRawProducts = [
                                      //     ...values.remain_products,
                                      //   ];
                                      //   updatedRawProducts[index] = {
                                      //     ...updatedRawProducts[index],
                                      //     product_name:
                                      //       selectedProduct.product_name,
                                      //     mrp: selectedProduct.mrp,
                                      //   };
                                      //   setInitialValues((prev) => ({
                                      //     ...prev,
                                      //     remain_products: updatedRawProducts,
                                      //   }));
                                      //   setFieldValue(
                                      //     "remain_products",
                                      //     updatedRawProducts
                                      //   );
                                      //   console.log(
                                      //     "VALUESSSS remain_products===>>>",
                                      //     updatedRawProducts
                                      //   );
                                      // } else {
                                      //   console.log("ELSEEEEE");
                                      // }
                                      updateDataAccordingProduct(
                                        index,
                                        e.target.value,
                                        values,
                                        setFieldValue
                                      );
                                    }}
                                  />

                                  {errors.remain_products &&
                                  errors.remain_products[index] &&
                                  errors.remain_products[index].product_name &&
                                  touched.remain_products &&
                                  touched.remain_products[index]
                                    .product_name ? (
                                    <span className="text-xs text-red-500">
                                      {
                                        errors.remain_products[index]
                                          .product_name
                                      }
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  {values?.remain_products[index]?.mrp}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Quantity"
                                    name={`remain_products.${index}.raw_quantity`}
                                    isError={
                                      errors.remain_products &&
                                      errors.remain_products[index] &&
                                      errors.remain_products[index]
                                        .raw_quantity &&
                                      touched.remain_products &&
                                      touched.remain_products[index]
                                        .raw_quantity
                                    }
                                  />
                                  {errors.remain_products &&
                                  errors.remain_products[index] &&
                                  errors.remain_products[index].raw_quantity &&
                                  touched.remain_products &&
                                  touched.remain_products[index]
                                    .raw_quantity ? (
                                    <span className="text-xs text-red-500">
                                      {
                                        errors.remain_products[index]
                                          .raw_quantity
                                      }
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Quantity"
                                    name={`remain_products.${index}.final_quantity`}
                                    isError={
                                      errors.remain_products &&
                                      errors.remain_products[index] &&
                                      errors.remain_products[index]
                                        .final_quantity &&
                                      touched.remain_products &&
                                      touched.remain_products[index]
                                        .final_quantity
                                    }
                                  />
                                  {errors.remain_products &&
                                  errors.remain_products[index] &&
                                  errors.remain_products[index]
                                    .final_quantity &&
                                  touched.remain_products &&
                                  touched.remain_products[index]
                                    .final_quantity ? (
                                    <span className="text-xs text-red-500">
                                      {
                                        errors.remain_products[index]
                                          .final_quantity
                                      }
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Quantity"
                                    name={`remain_products.${index}.remain_quantity`}
                                    isError={
                                      errors.remain_products &&
                                      errors.remain_products[index] &&
                                      errors.remain_products[index]
                                        .remain_quantity &&
                                      touched.remain_products &&
                                      touched.remain_products[index]
                                        .remain_quantity
                                    }
                                  />
                                  {errors.remain_products &&
                                  errors.remain_products[index] &&
                                  errors.remain_products[index]
                                    .remain_quantity &&
                                  touched.remain_products &&
                                  touched.remain_products[index]
                                    .remain_quantity ? (
                                    <span className="text-xs text-red-500">
                                      {
                                        errors.remain_products[index]
                                          .remain_quantity
                                      }
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <SelectInput
                                    selectOptions={allStatus}
                                    isError={
                                      errors.remain_products &&
                                      errors.remain_products[index] &&
                                      errors.remain_products[index].status &&
                                      touched.remain_products &&
                                      touched.remain_products[index].status
                                    }
                                    name={`remain_products.${index}.status`}
                                    onblur={(e) => {
                                      setFieldValue(
                                        `remain_products.${index}.status`,
                                        e.target.value
                                      );
                                    }}
                                  />
                                  {errors.remain_products &&
                                  errors.remain_products[index] &&
                                  errors.remain_products[index].status &&
                                  touched.remain_products &&
                                  touched.remain_products[index].status ? (
                                    <span className="text-xs text-red-500">
                                      {errors.remain_products[index].status}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-right whitespace-nowrap">
                                  <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div
                        className="cursor-pointer flex items-center space-x-2 w-32 mt-4"
                        onClick={() =>
                          push({
                            product_name: "",
                            code: "",
                            mrp: "",
                            quantity: "",
                          })
                        }
                      >
                        <BiPlusCircle className="text-xl text-[#3C81FC]" />
                        <p className="text-[#3C81FC] text-base font-semibold">
                          Add product
                        </p>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                onClick={(event) => {
                  params.type == "edit"
                    ? navigate("/inventory/recipe/")
                    : event.preventDefault();
                  handleReset();
                }}
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormRecipe;
