import React from "react";
import { Link } from "react-router-dom";

const Header = ({ breadCrumbs, title }) => {
  return (
    <div className="bg-[#002362] h-32 flex py-2 px-6 text-white flex-col">
      <div className="mt-2 flex">
        {breadCrumbs.map((item, index) => (
          <div key={item.key}>
            <Link
              to={item.to}
              className={`${!item.active ? "font-bold" : null} text-sm mr-4`}
            >
              {item.name}
            </Link>
            {index + 1 !== breadCrumbs.length && (
              <span className="mr-4">/</span>
            )}
          </div>
        ))}
      </div>
      <div className="mt-6 text-2xl font-bold">{title}</div>
    </div>
  );
};

export default Header;
