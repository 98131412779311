import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
const { REACT_APP_SITE_URL } = process.env;

export function useLoadingWithRefresh() {
  const [loading, setLoading] = useState(true);
  const dispatch = useDispatch();

  useEffect(() => {
    (async () => {
      try {
        setLoading(false)
      } catch (err) {
        console.log(err);
        setLoading(false);
      }
    })();
  }, [dispatch]);

  return { loading };
}
