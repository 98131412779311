import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { BiMinusCircle, BiPlusCircle } from "react-icons/bi";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { BiPlus } from "react-icons/bi";
//import DateTimePicker from "react-datetime-picker";
import {
  Checkbox,
  Header,
  Label,
  RadioButton,
  TextInput,
  SelectInput,
  SingleImagePreview,
  MultiSelect,
} from "../../../../components";
import NoUpload from "../../../../assets/images/no-upload.svg";
import * as Yup from "yup";
import moment from "moment";
import { useAsyncEffect } from "use-async-effect";

const FormProduct = () => {
  const params = useParams();
  const navigate = useNavigate();

  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    { key: 1, name: "Product", to: "/inventory/product", active: false },
    {
      key: 2,
      name: "Add Product",
      to: "/inventory/product/add",
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    image: "",
    wholesale_box_barcode: "",
    wholesale_box_batch_number: "",
    wholesale_box_product_name: "",
    wholesale_box_product_quantity: "",
    wholesale_box_product_weight: "",
    supplier_id: [],
    barcode: "",
    batch_number: "",
    product_name: "",
    department_id: "",
    brand_id: "",
    net_weight: "",
    sale_tax: "",
    purchase_tax_include: false,
    sales_tax_include: false,
    add_vat: "",
    //expiry_date: "",
    ingredients: "",
    description: "",
    gross_weight: "",
    nutrition: [
      {
        name: "",
        value: "",
      },
    ],
    purchase_price: "",
    landing_cost: "",
    mrp: "",
    retailer_price: "",
    wholesaler_price: "",
  });

  const [departments, setDepartments] = useState([]);
  const [brands, setBrands] = useState([]);
  const [suppliers, setSuppliers] = useState([]);
  const [product, setProduct] = useState([]);
  //const [value, onChange] = useState(new Date());

  const getProductList = async () => {
    try {
      const response = await api.getAllProducts();
      if (response.status === 200) {
        let data = response.data.data;
        setProduct(data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const getDepartmentList = async () => {
    try {
      const response = await api.getAllDepartments();
      if (response.status === 200) {
        let data = response.data.data;
        setDepartments(data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const getBrandList = async () => {
    try {
      const response = await api.getAllBrands();
      if (response.status === 200) {
        setBrands(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const getSupplierList = async () => {
    try {
      const response = await api.getAllSuppliers();
      if (response.status === 200) {
        let data = response.data.data;
        let finalData = [];
        data.filter((i) => {
          finalData.push({
            label: i.company_name,
            value: i.id,
          });

          return finalData;
        });

        setSuppliers(finalData);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  useAsyncEffect(async () => {
    await getDepartmentList();
    await getBrandList();
    await getSupplierList();
    await getProductList();

    if (params.type === "edit" && params.id !== "") {
      try {
        const response = await api.getProductById({ id: params.id });
        if (response.status === 200) {
          response.data.data[0].image =
            response.data.url + response.data.data[0].image;

          setInitialValues(response.data.data[0]);
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  const ProductSchema = Yup.object().shape({
    image: Yup.mixed().required("Image is required"),
    wholesale_box_barcode: Yup.string().required(
      "Wholesale box barcode is required"
    ),
    wholesale_box_batch_number: Yup.string().required(
      "Wholesale box batch number is required"
    ),
    wholesale_box_product_name: Yup.string().required(
      "Wholesale box product name is required"
    ),
    wholesale_box_product_quantity: Yup.number()
      .typeError("Input should be a number")
      .required("Wholesale box product quantity is required"),
    wholesale_box_product_weight: Yup.number()
      .typeError("Input should be a number")
      .required("Wholesale box product weight is required"),
    // wholesale_box_product_weight: Yup.string().required(
    //   "Wholesale box product weight is required"
    // ),
    supplier_id: Yup.array()
      .min(1, "Please select supplier")
      .of(Yup.string())
      .required("Supplier is required"),
    barcode: Yup.string().required("Barcode is required"),
    batch_number: Yup.string().required("Batch number is required"),
    product_name: Yup.string().required("Product name is required"),
    department_id: Yup.string().required("Department is required"),
    brand_id: Yup.string().required("Brand is required"),
    //net_weight: Yup.string().required("Net weight is required"),
    net_weight: Yup.number()
      .typeError("Input should be a number")
      .required("Net weight is required"),
    //sale_tax: Yup.string().required("Sales tax is required"),
    sale_tax: Yup.number()
      .typeError("Input should be a number")
      .required("Sales tax is required"),
    purchase_tax_include: Yup.boolean(),
    sales_tax_include: Yup.boolean(),
    add_vat: Yup.string().required("Add Vat is required"),
    //expiry_date: Yup.string().required("Expiry date is required"),
    // ingredients: Yup.string().required("Ingredients is required"),
    // description: Yup.string().required("Description is required"),
    // gross_weight: Yup.string().required("Gross Weight is required"),
    gross_weight: Yup.number()
      .typeError("Input should be a number")
      .required("Gross Weight is required"),
    // nutrition: Yup.array().of(
    //   Yup.object().shape({
    //     name: Yup.string().required("Name is required"),
    //     value: Yup.string().required("Name is required"),
    //   })
    // ),
    purchase_price: Yup.number()
      .typeError("Input should be a number")
      .required("Purchase price is required"),
    landing_cost: Yup.number()
      .typeError("Input should be a number")
      .required("Landing cost is required"),
    mrp: Yup.number()
      .typeError("Input should be a number")
      .required("MRP is required"),
    retailer_price: Yup.number()
      .typeError("Input should be a number")
      .required("Retailer Price is required"),
    wholesaler_price: Yup.number()
      .typeError("Input should be a number")
      .required("Wholesaler Price is required"),
  });

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add new product" />
      <Formik
        initialValues={initialValues}
        validationSchema={ProductSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          values.show_in_pos = "yes";
          if (values.purchase_tax_include === false) {
            values.purchase_tax_include = "no";
          } else {
            values.purchase_tax_include = "yes";
          }
          if (values.sales_tax_include === false) {
            values.sales_tax_include = "no";
          } else {
            values.sales_tax_include = "yes";
          }
          values.nutrition = values.nutrition.filter(
            (item) => item.name !== "" || item.value !== ""
          );
          console.log("values nutrition", values.nutrition);
          if (values.nutrition.length > 0) {
            values.nutrition = JSON.stringify(values.nutrition);
          } else {
            values.nutrition = [];
          }

          let formData = new FormData();
          console.log("formData", values);
          for (var key in values) {
            // Special handling for 'supplier_id'
            formData.append(
              key,
              key === "supplier_id" ? JSON.stringify(values[key]) : values[key]
            );
          }
          // for (var key in values) {
          //   if (key === "supplier_id") {
          //     formData.append(key, JSON.stringify(values[key]));

          //   } else {
          //     formData.append(key, values[key]);
          //   }
          // }
          if (params.type === "add") {
            try {
              const response = await api.createProduct(formData);
              console.log("RE==============>", response);
              if (
                response.data.status === 200 ||
                response.data.status === 201
              ) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/inventory/product");
              } else if (response.data.status === 204) {
                toast.error(response.data.message, { position: "top-right" });
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (params.type === "edit" && params.id !== "") {
            if (typeof formData.get("image") == "string") {
              formData.delete("image");
            }
            try {
              const response = await api.updateProduct(formData);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/inventory/product");
              } else if (response.status === 402) {
                toast.error(response.data.message, { position: "top-left" });
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue, isError }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Product information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10 mb-6">
                <div className="col-span-3 flex items-center space-x-8">
                  {values.image && params.type === "add" && (
                    <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                      <SingleImagePreview file={values.image} />
                    </div>
                  )}
                  {typeof values.image == "string" &&
                    params.type === "edit" && (
                      <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                        <img
                          src={values.image}
                          alt="preview"
                          className="w-32"
                        />
                      </div>
                    )}
                  {typeof values.image == "object" &&
                    params.type === "edit" && (
                      <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                        <SingleImagePreview file={values.image} />
                      </div>
                    )}
                  {values.image === "" && params.type === "add" && (
                    <img
                      src={NoUpload}
                      className={`${
                        errors.image && touched.image
                          ? "border border-red-500 rounded-md"
                          : null
                      }`}
                      alt=""
                    />
                  )}
                  <label
                    htmlFor="upload"
                    className="flex flex-row items-center gap-2 cursor-pointer"
                  >
                    <BiPlus className="text-xl text-[#3C81FC]" />
                    <span className="text-sm text-[#3C81FC]">Add Image</span>
                  </label>
                  <input
                    id="upload"
                    type="file"
                    className="hidden"
                    name="image"
                    onChange={(e) => {
                      setFieldValue("image", e.target.files[0]);
                    }}
                  />
                </div>
                {errors.image && touched.image ? (
                  <span className="text-xs mt-2 text-red-500">
                    {errors.image}
                  </span>
                ) : null}
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Wholesale box barcode" isRequired={true} />
                  <TextInput
                    placeholder="Enter wholesale box barcode"
                    name="wholesale_box_barcode"
                    isError={
                      errors.wholesale_box_barcode &&
                      touched.wholesale_box_barcode
                    }
                  />
                  {errors.wholesale_box_barcode &&
                  touched.wholesale_box_barcode ? (
                    <span className="text-xs text-red-500">
                      {errors.wholesale_box_barcode}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Wholesale box batch number" isRequired={true} />
                  <TextInput
                    placeholder="Enter Wholesale box batch number"
                    name="wholesale_box_batch_number"
                    isError={
                      errors.wholesale_box_batch_number &&
                      touched.wholesale_box_batch_number
                    }
                  />
                  {errors.wholesale_box_batch_number &&
                  touched.wholesale_box_batch_number ? (
                    <span className="text-xs text-red-500">
                      {errors.wholesale_box_batch_number}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Wholesale box product name" isRequired={true} />
                  <TextInput
                    placeholder="Enter wholesale box product name"
                    name="wholesale_box_product_name"
                    isError={
                      errors.wholesale_box_product_name &&
                      touched.wholesale_box_product_name
                    }
                  />
                  {errors.wholesale_box_product_name &&
                  touched.wholesale_box_product_name ? (
                    <span className="text-xs text-red-500">
                      {errors.wholesale_box_product_name}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label
                    text="Wholesale box product quantity"
                    isRequired={true}
                  />
                  <TextInput
                    placeholder="Enter wholesale box product quantity"
                    name="wholesale_box_product_quantity"
                    isError={
                      errors.wholesale_box_product_quantity &&
                      touched.wholesale_box_product_quantity
                    }
                  />
                  {errors.wholesale_box_product_quantity &&
                  touched.wholesale_box_product_quantity ? (
                    <span className="text-xs text-red-500">
                      {errors.wholesale_box_product_quantity}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label
                    text="Wholesale box product weight"
                    isRequired={true}
                  />
                  <TextInput
                    placeholder="Enter wholesale box product weight"
                    name="wholesale_box_product_weight"
                    isError={
                      errors.wholesale_box_product_weight &&
                      touched.wholesale_box_product_weight
                    }
                  />
                  {errors.wholesale_box_product_weight &&
                  touched.wholesale_box_product_weight ? (
                    <span className="text-xs text-red-500">
                      {errors.wholesale_box_product_weight}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Supplier" isRequired={true} />
                  <MultiSelect
                    selectOptions={suppliers}
                    isError={errors.supplier_id && touched.supplier_id}
                    name="supplier_id"
                    onchange={(e) => {
                      let d = e.map((item, index) => {
                        return item.value;
                      });
                      console.log("Suppiler", e);
                      setFieldValue("supplier_id", d);
                    }}
                  />
                  {errors.supplier_id && touched.supplier_id ? (
                    <span className="text-xs text-red-500">
                      {errors.supplier_id}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <div>
                    <Label text="Barcode" isRequired={true} />
                    <TextInput
                      placeholder="Enter barcode number"
                      name="barcode"
                      isError={errors.barcode && touched.barcode}
                    />
                    {errors.barcode && touched.barcode ? (
                      <span className="text-xs text-red-500">
                        {errors.barcode}
                      </span>
                    ) : null}
                  </div>
                  <div className="mt-2">
                    <p className="text-xs text-[#3C81FC] cursor-pointer">
                      Auto generate
                    </p>
                  </div>
                </div>
                <div className="mb-6">
                  <Label text="Batch number" isRequired={true} />
                  <TextInput
                    placeholder="Enter Batch number"
                    name="batch_number"
                    isError={errors.batch_number && touched.batch_number}
                  />
                  {errors.batch_number && touched.batch_number ? (
                    <span className="text-xs text-red-500">
                      {errors.batch_number}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Product name" isRequired={true} />
                  <TextInput
                    placeholder="Enter Product name"
                    name="product_name"
                    isError={errors.product_name && touched.product_name}
                  />
                  {errors.product_name && touched.product_name ? (
                    <span className="text-xs text-red-500">
                      {errors.product_name}
                    </span>
                  ) : null}
                </div>

                {/* <div className="mb-6">
                  <Label text="Product name" isRequired={true} />
                  <SelectInput
                    selectOptions={product}
                    isError={errors.product_name && touched.product_name}
                    name="product_name"
                    onblur={(e) => {
                      setFieldValue("product_name", e.target.value);
                    }}
                  />
                  {errors.product_name && touched.product_name ? (
                    <span className="text-xs text-red-500">
                      {errors.product_name}
                    </span>
                  ) : null}
                </div> */}

                <div className="mb-6">
                  <Label text="Department" isRequired={true} />
                  <SelectInput
                    selectOptions={departments}
                    isError={errors.department_id && touched.department_id}
                    name="department_id"
                    onblur={(e) => {
                      setFieldValue("department_id", e.target.value);
                    }}
                  />
                  {errors.department_id && touched.department_id ? (
                    <span className="text-xs text-red-500">
                      {errors.department_id}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Brand" isRequired={true} />
                  <SelectInput
                    selectOptions={brands}
                    isError={errors.brand_id && touched.brand_id}
                    name="brand_id"
                    onblur={(e) => {
                      setFieldValue("brand_id", e.target.value);
                    }}
                  />
                  {errors.brand_id && touched.brand_id ? (
                    <span className="text-xs text-red-500">
                      {errors.brand_id}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Net weight" isRequired={true} />
                  <TextInput
                    placeholder="Enter Net weight"
                    name="net_weight"
                    isError={errors.net_weight && touched.net_weight}
                  />
                  {errors.net_weight && touched.net_weight ? (
                    <span className="text-xs text-red-500">
                      {errors.net_weight}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <div>
                    <Label text="Sale tax" isRequired={true} />
                    <TextInput
                      placeholder="Enter Sale tax"
                      name="sale_tax"
                      isError={errors.sale_tax && touched.sale_tax}
                    />
                    {errors.sale_tax && touched.sale_tax ? (
                      <span className="text-xs text-red-500">
                        {errors.sale_tax}
                      </span>
                    ) : null}
                  </div>

                  {/* <div className="flex items-center mt-2 space-x-6">
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        name="purchase_tax_include"
                        checked={
                          initialValues.purchase_tax_include &&
                          initialValues.purchase_tax_include == "yes"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          console.log("onChange1", e.target.value);
                          const value = e.target.checked ? "yes" : "no";
                          setInitialValues((prev) => ({
                            ...prev,
                            purchase_tax_include: value,
                          }));
                        }}
                      />
                      <label className="text-sm">Purchase tax include</label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        name="sales_tax_include"
                        checked={
                          initialValues.sales_tax_include &&
                          initialValues.sales_tax_include == "yes"
                            ? true
                            : false
                        }
                        onChange={(e) => {
                          console.log("onChange2", e.target.value);
                          const value = e.target.checked ? "yes" : "no";
                          setInitialValues((prev) => ({
                            ...prev,
                            sales_tax_include: value,
                          }));
                        }}
                      />
                      <label className="text-sm">Sale tax include</label>
                    </div>
                  </div> */}
                </div>

                <div className="mb-6">
                  <Label text="Add VAT" isRequired={true} />
                  <div className="flex items-center mt-4 space-x-4">
                    <div className="flex items-center space-x-2">
                      <RadioButton
                        name="add_vat"
                        value="yes"
                        isError={errors.add_vat && touched.add_vat}
                      />
                      <label className="text-sm">Yes</label>
                    </div>
                    <div className="flex items-center space-x-2">
                      <RadioButton
                        name="add_vat"
                        value="no"
                        isError={errors.add_vat && touched.add_vat}
                      />
                      <label className="text-sm">No</label>
                    </div>
                  </div>

                  {errors.add_vat && touched.add_vat ? (
                    <span className="text-xs text-red-500">
                      {errors.add_vat}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Other information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                {/* <div className="mb-6">
                  <Label text="Expiry date" isRequired={false} />
                  {console.log(errors.expiry_date)}
                  {console.log("supplier===========>>>",touched)}
                  <DateTimePicker
                    className={`${errors.expiry_date && touched.expiry_date ? "!border-red-500" : null
                      } border border-[#DBE4F2] flex mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243]  sm:text-sm focus:ring-1`}
                    name="expiry_date"
                    amPmAriaLabel="Select AM/PM"
                    calendarAriaLabel="Toggle calendar"
                    clearAriaLabel="Clear value"
                    dayAriaLabel="Day"
                    hourAriaLabel="Hour"
                    maxDetail="second"
                    minuteAriaLabel="Minute"
                    monthAriaLabel="Month"
                    nativeInputAriaLabel="Date and time"
                    onChange={onChange}
                    secondAriaLabel="Second"
                    value={value}
                    yearAriaLabel="Year"
                    // isError={errors.expiry_date && touched.expiry_date}
                  />
                  {errors.expiry_date && touched.expiry_date ? (
                    <span className="text-xs text-red-500">
                      {errors.expiry_date}
                    </span>
                  ) : null}
                </div> */}

                <div className="mb-6">
                  <Label text="Ingredients" />
                  <TextInput
                    placeholder="Enter Ingredients"
                    name="ingredients"
                    isError={errors.ingredients && touched.ingredients}
                  />
                  {errors.ingredients && touched.ingredients ? (
                    <span className="text-xs text-red-500">
                      {errors.ingredients}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Description" />
                  <TextInput
                    placeholder="Enter Description"
                    name="description"
                    isError={errors.description && touched.description}
                  />
                  {errors.description && touched.description ? (
                    <span className="text-xs text-red-500">
                      {errors.description}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Gross weight" isRequired={true} />
                  <TextInput
                    placeholder="Enter Gross weight"
                    name="gross_weight"
                    isError={errors.gross_weight && touched.gross_weight}
                  />
                  {errors.gross_weight && touched.gross_weight ? (
                    <span className="text-xs text-red-500">
                      {errors.gross_weight}
                    </span>
                  ) : null}
                </div>

                <FieldArray
                  name="nutrition"
                  render={({ insert, remove, push }) => (
                    <>
                      {values.nutrition &&
                        values.nutrition.length > 0 &&
                        values.nutrition.map((item, index) => (
                          <div
                            className="flex items-center space-x-4 mb-6"
                            key={index}
                          >
                            <div>
                              <Label text="Nutrition" />
                              <TextInput
                                placeholder="Enter Name"
                                name={`nutrition.${index}.name`}
                                isError={
                                  errors.nutrition &&
                                  errors.nutrition[index] &&
                                  errors.nutrition[index].name &&
                                  touched.nutrition &&
                                  touched.nutrition[index] &&
                                  touched.nutrition[index].name
                                }
                              />
                              {errors.nutrition &&
                              errors.nutrition[index] &&
                              errors.nutrition[index].name &&
                              touched.nutrition &&
                              touched.nutrition[index] &&
                              touched.nutrition[index].name ? (
                                <span className="text-xs text-red-500">
                                  {errors.nutrition[index].name}
                                </span>
                              ) : null}
                            </div>

                            <div className="mt-6">
                              <TextInput
                                placeholder="Enter Value"
                                name={`nutrition.${index}.value`}
                                isError={
                                  errors.nutrition &&
                                  errors.nutrition[index] &&
                                  errors.nutrition[index].value &&
                                  touched.nutrition &&
                                  touched.nutrition[index] &&
                                  touched.nutrition[index].value
                                }
                              />
                              {errors.nutrition &&
                              errors.nutrition[index] &&
                              errors.nutrition[index].value &&
                              touched.nutrition &&
                              touched.nutrition[index] &&
                              touched.nutrition[index].value ? (
                                <span className="text-xs text-red-500">
                                  {errors.nutrition[index].value}
                                </span>
                              ) : null}
                            </div>
                            <div className="flex items-center space-x-4 mt-8">
                              <BiPlusCircle
                                onClick={() => push({ name: "", value: "" })}
                                className="text-xl text-blue-500"
                              />
                              {values.nutrition.length > 1 && (
                                <BiMinusCircle
                                  className="text-xl text-red-500"
                                  onClick={() => remove(index)}
                                />
                              )}
                            </div>
                          </div>
                        ))}
                    </>
                  )}
                />
              </div>
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Pricing details</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Purchase price" isRequired={true} />
                  <TextInput
                    placeholder="Enter Purchase price"
                    name="purchase_price"
                    isError={errors.purchase_price && touched.purchase_price}
                  />
                  {errors.purchase_price && touched.purchase_price ? (
                    <span className="text-xs text-red-500">
                      {errors.purchase_price}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Landing cost" isRequired={true} />
                  <TextInput
                    placeholder="Enter Landing cost"
                    name="landing_cost"
                    isError={errors.landing_cost && touched.landing_cost}
                  />
                  {errors.landing_cost && touched.landing_cost ? (
                    <span className="text-xs text-red-500">
                      {errors.landing_cost}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="MRP" isRequired={true} />
                  <TextInput
                    placeholder="Enter MRP"
                    name="mrp"
                    isError={errors.mrp && touched.mrp}
                  />
                  {errors.mrp && touched.mrp ? (
                    <span className="text-xs text-red-500">{errors.mrp}</span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Retailer price" isRequired={true} />
                  <TextInput
                    placeholder="Enter Retailer price"
                    name="retailer_price"
                    isError={errors.retailer_price && touched.retailer_price}
                  />
                  {errors.retailer_price && touched.retailer_price ? (
                    <span className="text-xs text-red-500">
                      {errors.retailer_price}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Wholesaler price" isRequired={true} />
                  <TextInput
                    placeholder="Enter Wholesaler price"
                    name="wholesaler_price"
                    isError={
                      errors.wholesaler_price && touched.wholesaler_price
                    }
                  />
                  {errors.wholesaler_price && touched.wholesaler_price ? (
                    <span className="text-xs text-red-500">
                      {errors.wholesaler_price}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleReset();
                }}
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormProduct;
