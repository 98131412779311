import { FieldArray, Form, Formik } from "formik";
import React, { useRef, useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import {
  Header,
  ImagePreview,
  Label,
  TextEmail,
  TextInput,
} from "../../../../components";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";

const FormEmployee = () => {
  const navigate = useNavigate();
  const idProofRef = useRef();
  const addressProofRef = useRef();

  const params = useParams();

  const breadCrumbs = [
    { key: 0, name: "Contact", to: "", active: false },
    { key: 1, name: "Employee", to: "/contact/employee", active: false },
    {
      key: 2,
      name: `${params.type === "add" ? "Add Employee" : "Edit Employee"}`,
      to: `${params.type} === "add" ? '/contact/employee/add' : '/contact/employee/edit/${params.id}}'`,
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    employee_type: "",
    name: "",
    email: "",
    mobile_no: "",
    nia_no: "",
    wages: "",
    employment_type: "",
    addresses: [
      {
        address_line_1: "",
        address_line_2: "",
        country: "",
        state: "",
        city: "",
        pincode: "",
      },
    ],
    account_no: "",
    branch_name: "",
    bank_sort_code: "",
    id_proof: "",
    address_proof: "",
  });

  useAsyncEffect(async () => {
    if (params.type === "edit" && params.id !== "") {
      try {
        const response = await api.getEmployeeById({ id: params.id });
        if (response.status === 200) {
          setInitialValues(response.data.data[0]);
          console.log("response :>", response.data.data[0]);
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  const EmployeeSchema = Yup.object().shape({
    employee_type: Yup.string().required("Customer type is required"),
    name: Yup.string().required("Customer name is required"),
    email: Yup.string()
      .email("Please enter valid email")
      .required("Email is required"),
    mobile_no: Yup.string().required("Contact number is required"),
    nia_no: Yup.string().required("NIA number is required"),
    wages: Yup.string().required("Wages is required"),
    employment_type: Yup.string().required("Employment type is required"),
    addresses: Yup.array().of(
      Yup.object().shape({
        address_line_1: Yup.string().required("Please enter valid address"),
        address_line_2: Yup.string().required("Please enter valid address"),
        country: Yup.string().required("Please enter country"),
        state: Yup.string().required("Please enter state"),
        city: Yup.string().required("Please enter city"),
        pincode: Yup.string(),
      })
    ),
    account_no: Yup.string().required("Account number is required"),
    branch_name: Yup.string().required("Branch name is required"),
    bank_sort_code: Yup.string().required("SORT code is required"),
    id_proof: Yup.mixed().required("Please upload ID Proof"),
    address_proof: Yup.mixed().required("Please upload Address Proof"),
  });

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add new Employee" />
      <Formik
        initialValues={initialValues}
        validationSchema={EmployeeSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          values.addresses = JSON.stringify(values.addresses);
          let formData = new FormData();
          for (var key in values) {
            formData.append(key, values[key]);
          }
          if (params.type === "add") {
            try {
              const response = await api.createEmployee(formData);
              console.log("response add=========>", response.data);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/contact/employee");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (params.type === "edit" && params.id !== "") {
            formData.append("id", params.id);
            try {
              const response = await api.updateEmployee(formData);
              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/contact/employee");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100 ">
              <div className="mb-6">
                <p className="text-xl font-semibold">Employee information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Employee name" isRequired={true} />
                  <TextInput
                    placeholder="Enter employee name"
                    name="name"
                    isError={errors.name && touched.name}
                  />
                  {errors.name && touched.name ? (
                    <span className="text-xs text-red-500">{errors.name}</span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Email ID" isRequired={true} />
                  <TextEmail
                    placeholder="Enter email ID"
                    name="email"
                    isError={errors.email && touched.email}
                  />
                  {errors.email && touched.email ? (
                    <span className="text-xs text-red-500">{errors.email}</span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Mobile number" isRequired={true} />
                  <TextInput
                    placeholder="Enter mobile number"
                    name="mobile_no"
                    isError={errors.mobile_no && touched.mobile_no}
                  />
                  {errors.mobile_no && touched.mobile_no ? (
                    <span className="text-xs text-red-500">
                      {errors.mobile_no}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="NIA number" isRequired={true} />
                  <TextInput
                    placeholder="Enter NIA number"
                    name="nia_no"
                    isError={errors.nia_no && touched.nia_no}
                  />
                  {errors.nia_no && touched.nia_no ? (
                    <span className="text-xs text-red-500">
                      {errors.nia_no}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Wages" isRequired={true} />
                  <TextInput
                    placeholder="Enter Wages"
                    name="wages"
                    isError={errors.wages && touched.wages}
                  />
                  {errors.wages && touched.wages ? (
                    <span className="text-xs text-red-500">{errors.wages}</span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Employee as" isRequired={false} />

                  <div className="flex justify-start gap-6 mt-2">
                    <div>
                      <div className="flex justify-start gap-6">
                        <div>
                          <input
                            className="sr-only peer sr-only-focusable"
                            type="radio"
                            value="Manager"
                            name="employee_type"
                            id="wholesaler"
                            onChange={() => {
                              setFieldValue("employee_type", "Manager");
                            }}
                            checked={
                              initialValues.employee_type !== "" &&
                              initialValues.employee_type === "Manager"
                                ? true
                                : false
                            }
                          />
                          <label
                            htmlFor="wholesaler"
                            className={`border border-highlight flex p-3 w-32 shadow-sm text-center rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center`}
                          >
                            Manager
                          </label>
                        </div>
                        <input
                          className="sr-only peer"
                          type="radio"
                          value="Delivery User"
                          name="employee_type"
                          id="general"
                          onChange={() => {
                            setFieldValue("employee_type", "Delivery User");
                          }}
                          checked={
                            initialValues.employee_type !== "" &&
                            initialValues.employee_type === "Delivery User"
                              ? true
                              : false
                          }
                        />
                        <label
                          htmlFor="general"
                          className="flex p-3 w-32 shadow-sm text-center border border-highlight rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center"
                        >
                          Delivery user
                        </label>
                      </div>
                      <div>
                        {errors.employee_type && touched.employee_type ? (
                          <span className="text-xs text-red-500">
                            {errors.employee_type}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className="mb-6">
                  <Label text="Employment type*" isRequired={false} />
                  <div className="flex justify-start gap-6 mt-2">
                    <div>
                      <div className="flex justify-start gap-6">
                        <div>
                          <input
                            className="sr-only peer"
                            type="radio"
                            value="Full Time"
                            name="employment_type"
                            id="full_time"
                            onChange={() => {
                              setFieldValue("employment_type", "Full Time");
                            }}
                            checked={
                              initialValues.employment_type !== "" &&
                              initialValues.employment_type === "Full Time"
                                ? true
                                : false
                            }
                          />
                          <label
                            htmlFor="full_time"
                            className={`border border-highlight flex p-3 w-32 shadow-sm text-center rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center`}
                          >
                            Full Time
                          </label>
                        </div>
                        <input
                          className="sr-only peer"
                          type="radio"
                          value="Part Time"
                          name="employment_type"
                          id="part_time"
                          onChange={() => {
                            setFieldValue("employment_type", "Part Time");
                          }}
                          checked={
                            initialValues.employment_type !== "" &&
                            initialValues.employment_type === "Part Time"
                              ? true
                              : false
                          }
                        />
                        <label
                          htmlFor="part_time"
                          className="flex p-3 w-32 shadow-sm text-center border border-highlight rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center"
                        >
                          Part Time
                        </label>
                      </div>
                      <div>
                        {errors.employment_type && touched.employment_type ? (
                          <span className="text-xs text-red-500">
                            {errors.employment_type}
                          </span>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/70 border border-blue-100 ">
              <div className="mb-6 flex items-center justify-between">
                <p className="text-xl font-semibold">Address information</p>
              </div>
              <FieldArray
                name="addresses"
                render={({ insert, remove, push }) => (
                  <>
                    {values.addresses.length > 0 &&
                      values.addresses.map((item, index) => (
                        <div
                          className={`${
                            values.addresses.length > 1
                              ? "border-b  mb-4"
                              : null
                          } grid grid-cols-3 grid-flow-row gap-x-10`}
                          key={index}
                        >
                          <div className="mb-6">
                            <Label text="Address Line 1" isRequired={true} />
                            <TextInput
                              placeholder="Enter Address Line 1"
                              name={`addresses.${index}.address_line_1`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].address_line_1 &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].address_line_1
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].address_line_1 &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].address_line_1 ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].address_line_1}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="Address Line 2" isRequired={true} />
                            <TextInput
                              placeholder="Enter Address Line 2"
                              name={`addresses.${index}.address_line_2`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].address_line_2 &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].address_line_2
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].address_line_2 &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].address_line_2 ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].address_line_2}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="Country" isRequired={true} />
                            <TextInput
                              placeholder="Enter Country"
                              name={`addresses.${index}.country`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].country &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].country
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].country &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].country ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].country}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="State" isRequired={true} />
                            <TextInput
                              placeholder="Enter state"
                              name={`addresses.${index}.state`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].state &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].state
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].state &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].state ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].state}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="City" isRequired={true} />
                            <TextInput
                              placeholder="Enter city"
                              name={`addresses.${index}.city`}
                              isError={
                                errors.addresses &&
                                errors.addresses[index] &&
                                errors.addresses[index].city &&
                                touched.addresses &&
                                touched.addresses[index] &&
                                touched.addresses[index].city
                              }
                            />
                            {errors.addresses &&
                            errors.addresses[index] &&
                            errors.addresses[index].city &&
                            touched.addresses &&
                            touched.addresses[index] &&
                            touched.addresses[index].city ? (
                              <span className="text-xs text-red-500">
                                {errors.addresses[index].city}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="pincode" isRequired={false} />
                            <TextInput
                              placeholder="Enter Pincode"
                              name={`addresses.${index}.pincode`}
                            />
                          </div>
                        </div>
                      ))}
                    <div
                      className="cursor-pointer flex items-center space-x-4"
                      onClick={() =>
                        push({
                          address_line_1: "",
                          address_line_2: "",
                          country: "",
                          state: "",
                          city: "",
                          pincode: "",
                        })
                      }
                    >
                      <BiPlusCircle className="text-xl text-[#3C81FC]" />
                      <span className="text-base font-semibold text-[#3C81FC]">
                        Add new addresses
                      </span>
                    </div>
                  </>
                )}
              />
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/70 border border-blue-100 ">
              <div className="mb-6 flex items-center justify-between">
                <p className="text-xl font-semibold">Bank information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Account number" isRequired={true} />
                  <TextInput
                    placeholder="Enter Account number"
                    name="account_no"
                    isError={errors.account_no && touched.account_no}
                  />
                  {errors.account_no && touched.account_no ? (
                    <span className="text-xs text-red-500">
                      {errors.account_no}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="Branch Name" isRequired={true} />
                  <TextInput
                    placeholder="Enter Branch Name"
                    name="branch_name"
                    isError={errors.branch_name && touched.branch_name}
                  />
                  {errors.branch_name && touched.branch_name ? (
                    <span className="text-xs text-red-500">
                      {errors.branch_name}
                    </span>
                  ) : null}
                </div>
                <div className="mb-6">
                  <Label text="SORT Code" isRequired={true} />
                  <TextInput
                    placeholder="Enter bank SORT code"
                    name="bank_sort_code"
                    isError={errors.bank_sort_code && touched.bank_sort_code}
                  />
                  {errors.bank_sort_code && touched.bank_sort_code ? (
                    <span className="text-xs text-red-500">
                      {errors.bank_sort_code}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/70 border border-blue-100">
              <div className="mb-6 flex items-center justify-between">
                <p className="text-xl font-semibold">Upload document</p>
              </div>
              <div className="flex flex-wrap -mx-3">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <Label text="Document Type" isRequired={true} />
                  <p className="border border-[#DBE4F2] mt-2 py-3 px-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1">
                    ID Proof
                  </p>
                </div>
                <div className="w-full md:w-2/5 px-3 mb-6 mt-1 md:mb-0">
                  <label className="block text-sm mb-2 text-dark">
                    Upload File *
                  </label>

                  <input
                    ref={idProofRef}
                    hidden
                    type="file"
                    name="id_proof"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file.size > 2 * 1024 * 1024) {
                        toast.error("File size exceeds 2MB limit.", {
                          position: "top-right",
                        });
                      } else {
                        setFieldValue("id_proof", file);
                      }
                    }}
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    multiple
                  />
                  <button
                    type="button"
                    className={`${
                      errors.id_proof && touched.id_proof
                        ? "border border-red-500"
                        : "border border-[#DBE4F2]"
                    } w-full px-20 py-3 text-sm rounded-lg text-[#3C81FC] bg-[#EAEFF8]`}
                    onClick={() => {
                      idProofRef.current.click();
                    }}
                  >
                    Browse...
                  </button>
                  {errors.id_proof && touched.id_proof ? (
                    <span className="text-xs text-red-500">
                      {errors.id_proof}
                    </span>
                  ) : null}
                  {values.id_proof && (
                    <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                      <p className="text-[#525D68] text-xs my-2 flex justify-center">
                        (Supported format: PDF, PNG, JPG only, max 2 MB)
                      </p>
                      <ImagePreview file={values.id_proof} />
                    </div>
                  )}
                </div>
              </div>
              <div className="flex flex-wrap -mx-3 mt-6">
                <div className="w-full md:w-1/3 px-3 mb-6 md:mb-0">
                  <Label text="Document Type" isRequired={true} />
                  <p className="border border-[#DBE4F2] mt-2 py-3 px-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1">
                    Address Proof
                  </p>
                </div>
                <div className="w-full md:w-2/5 px-3 mb-6 mt-1 md:mb-0">
                  <label className="block text-sm mb-2 text-dark">
                    Upload File *
                  </label>

                  <input
                    ref={addressProofRef}
                    hidden
                    type="file"
                    name="address_proof"
                    onChange={(e) => {
                      const file = e.target.files[0];
                      if (file.size > 2 * 1024 * 1024) {
                        toast.error("File size exceeds 2MB limit.", {
                          position: "top-right",
                        });
                      } else {
                        setFieldValue("address_proof", file);
                      }
                    }}
                    accept="image/png, image/jpg, image/jpeg, application/pdf"
                    multiple
                  />
                  <button
                    type="button"
                    className={`${
                      errors.address_proof && touched.address_proof
                        ? "border border-red-500"
                        : "border border-[#DBE4F2]"
                    } w-full px-20 py-3 text-sm rounded-lg text-[#3C81FC] bg-[#EAEFF8]`}
                    onClick={() => {
                      addressProofRef.current.click();
                    }}
                  >
                    Browse...
                  </button>
                  {errors.address_proof && touched.address_proof ? (
                    <span className="text-xs text-red-500">
                      {errors.address_proof}
                    </span>
                  ) : null}
                  {values.address_proof && (
                    <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                      <p className="text-[#525D68] text-xs my-2 flex justify-center">
                        (Supported format: PDF, PNG, JPG only, max 2 MB)
                      </p>
                      <ImagePreview file={values.address_proof} />
                    </div>
                  )}
                </div>
              </div>
            </div>
            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                type="reset"
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormEmployee;
