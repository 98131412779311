import React, { useState } from "react";
import { BiDotsVertical } from "react-icons/bi";
import { useAsyncEffect } from "use-async-effect";
import { Header, Table } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";

const CustomerList = () => {
  const breadCrumbs = [
    { key: 0, name: "Loyalty", to: "/loyalty/customer-list", active: false },
    {
      key: 1,
      name: "Customer list",
      to: "/loyalty/customer-list",
      active: true,
    },
  ];

  const [customers, setCustomers] = useState([]);

  const getList = async () => {
    try {
      const response = await api.getGeneralCustomers();
      if (response.status === 200) {
        setCustomers(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };
  useAsyncEffect(async () => {
    await getList();
  }, []);

  const data = React.useMemo(() => {
    const formattedData = [];

    customers?.map((data, index) =>
      formattedData.push({
        col1: data.name,
        col2: data.available_points ? data.available_points : 0,
        col3: data.redeem_points ? data.redeem_points : 0,
        col4: 0,
        col5: (
          <div className="flex items-center gap-2">
            {data.status === "Active" && (
              <>
                <div className="w-3 h-3 rounded-full bg-green-600"></div>
                <div>Active</div>
              </>
            )}
            {data.status === "Inactive" && (
              <>
                <div className="w-3 h-3 rounded-full bg-red-600"></div>
                <div>Inactive</div>
              </>
            )}
          </div>
        ),
        col6: (
          <div className="flex items-center space-x-4">
            <BiDotsVertical className="text-xl text-[#3C81FC]" />
          </div>
        ),
      })
    );

    return formattedData;
  }, [customers]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Customer name",
        accessor: "col1",
      },
      {
        Header: "Total Points",
        accessor: "col2",
      },
      {
        Header: "Reedeem Points",
        accessor: "col3",
      },
      {
        Header: "Avg. Point",
        accessor: "col4",
      },
      {
        Header: "Card Activation",
        accessor: "col5",
      },
      {
        Header: "Status",
        accessor: "col6",
      },
    ],
    []
  );

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Customer list" />
      <Table columns={columns} data={data} />
    </>
  );
};

export default CustomerList;
