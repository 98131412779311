import React, { useState } from "react";
import { BiDotsVertical } from "react-icons/bi";
import { FiSend } from "react-icons/fi";
import { useAsyncEffect } from "use-async-effect";
import { Header, Table } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";

const Customer = () => {
  const breadCrumbs = [
    { key: 0, name: "Price list", to: "/price-list/wholesaler", active: false },
    { key: 1, name: "Customer", to: "/price-list/customer", active: true },
  ];

  const otherList = [
    {
      title: "Send List",
      icon: <FiSend className="text-[#525D68] text-lg" />,
    },
  ];

  const [products, setProducts] = useState([]);
  let [imageURL, setImageURL] = useState("");

  const getList = async () => {
    try {
      const response = await api.getAllProducts();

      if (response.status === 200) {
        setImageURL(response.data.url);
        setProducts(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };
  useAsyncEffect(async () => {
    await getList();
  }, []);

  const data = React.useMemo(() => {
    const formattedData = [];

    products?.map((data, index) =>
      formattedData.push({
        col1: data.id,
        col2: (
          <img src={`${imageURL}${data.image}`} alt="no-img" className="w-6" />
        ),
        col3: data.wholesale_box_product_name,
        col4: data.department_name,
        col5: data.brand_name,
        col6: data.mrp,

        col7: (
          <div className="flex items-center space-x-4">
            <BiDotsVertical className="text-xl text-[#3C81FC]" />
          </div>
        ),
      })
    );

    return formattedData;
  }, [products, imageURL]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1",
      },
      {
        Header: "Image",
        accessor: "col2",
      },
      {
        Header: "Name",
        accessor: "col3",
      },
      {
        Header: "Department",
        accessor: "col4",
      },
      {
        Header: "Brand",
        accessor: "col5",
      },
      {
        Header: "Customer Price",
        accessor: "col6",
      },
      {
        Header: "",
        accessor: "col7",
      },
    ],
    []
  );

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Customer" />

      <Table
        columns={columns}
        data={data}
        otherList={otherList}
        priceListModal={true}
      />
    </>
  );
};

export default Customer;
