import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import {
  Header,
  Label,
  TextInput,
  Datepicker,
  SelectInput,
} from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncEffect } from "use-async-effect";
import * as Yup from "yup";
import moment from "moment";

const FormMaterialConsumption = () => {
  const params = useParams();
  const navigate = useNavigate();
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    {
      key: 1,
      name: "Material Consumption",
      to: "/inventory/material-consumption",
      active: false,
    },

    {
      key: 2,
      name: `${
        params.type === "add"
          ? "Add Material Consumption"
          : "Edit Add Material Consumption"
      }`,
      to: `${params.type} === "add" ? '/inventory/material-consumption/add' : '/inventory/material-consumption/edit/${params.id}}'`,
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    consumption_date: "",
    consumption_no: "",
    consumption_type: "",
    remarks: "",
    products: [
      {
        product_id: "",
        quantity: "",
        price: "",
      },
    ],
  });

  const [products, setProducts] = useState([]);
  const [materialConsmType, setmaterialConsmType] = useState([]);
  const getProducts = async () => {
    try {
      const response = await api.getAllProducts();

      if (response.status === 200) {
        setProducts(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const getMaterialConsType = async () => {
    try {
      const response = await api.getAllConsumType();
      if (response.status === 200) {
        console.log("getMaterialConsType+++++++++++", response);
        setmaterialConsmType(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  useAsyncEffect(async () => {
    await getProducts();
    await getMaterialConsType();

    if (params.type === "edit" && params.id !== "") {
      try {
        const response = await api.getMaterialConsmById({ id: params.id });
        if (response.status === 200) {
          if (response.status === 200) {
            setInitialValues(response.data.data[0]);
          }
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  const MaterialConsmSchema = Yup.object().shape({
    consumption_date: Yup.string().required("Date of consumption is required"),
    consumption_no: Yup.string().required("Consumption no is required"),
    consumption_type: Yup.string().required("Consumption type is required"),
    remarks: Yup.string().required("remarks are required"),
    products: Yup.array().of(
      Yup.object().shape({
        product_id: Yup.string().required("Product id is required"),
        quantity: Yup.string().required("Quantity  is required"),
        price: Yup.number().required("Price is required"),
      })
    ),
  });

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add material consumption" />
      <Formik
        initialValues={initialValues}
        enableReinitialize={true}
        validationSchema={MaterialConsmSchema}
        onSubmit={async (values) => {
          if (params.type === "add") {
            try {
              const response = await api.createMaterialConsmType(values);

              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/inventory/material-consumption");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (params.type === "edit" && params.id !== "") {
            params.id = JSON.parse(localStorage.getItem("pos-admin-user")).id;

            try {
              const response = await api.updateMaterialConsm(values);

              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/inventory/material-consumption");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Product information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Consumption date" isRequired={true} />

                  <Datepicker
                    name="consumption_date"
                    isTimeRequired={true}
                    isError={
                      errors.consumption_date && touched.consumption_date
                    }
                    onNewChange={(date) => {
                      setFieldValue(
                        "consumption_date",
                        moment(date).format("YYYY_MM_DD HH:mm:ss")
                      );
                    }}
                  />
                  {errors.consumption_date && touched.consumption_date ? (
                    <span className="text-xs text-red-500">
                      {errors.consumption_date}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Consumption No" isRequired={true} />
                  <TextInput
                    placeholder="Enter Consumption No"
                    name="consumption_no"
                    isError={errors.consumption_no && touched.consumption_no}
                  />
                  {errors.consumption_no && touched.consumption_no ? (
                    <span className="text-xs text-red-500">
                      {errors.consumption_no}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Consumption type" isRequired={true} />

                  <SelectInput
                    selectOptions={materialConsmType}
                    isError={errors.consumption_type}
                    name="consumption_type"
                    onblur={(e) => {
                      setFieldValue("consumption_type", e.target.value);
                    }}
                  />
                  {errors.consumption_type && touched.consumption_type ? (
                    <span className="text-xs text-red-500">
                      {errors.consumption_type}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6 col-span-2">
                  <Label text="Remarks" isRequired={true} />
                  <TextInput
                    placeholder="Enter Remarks"
                    name="remarks"
                    isError={errors.remarks && touched.remarks}
                  />
                  {errors.remarks && touched.remarks ? (
                    <span className="text-xs text-red-500">
                      {errors.remarks}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Add product</p>
              </div>
              <FieldArray
                name="products"
                render={({ insert, remove, push }) => (
                  <>
                    {values.products &&
                      values.products.length > 0 &&
                      values.products?.map((item, index) => (
                        <div
                          key={index}
                          className="grid grid-cols-3 grid-flow-row gap-x-10"
                        >
                          <div className="mb-6 text-red">
                            <Label text="Product" isRequired={true} />

                            <SelectInput
                              selectOptions={products}
                              isError={
                                errors.products &&
                                errors.products[index] &&
                                errors.products[index].product_id &&
                                touched.products &&
                                touched.products[index] &&
                                touched.products[index].product_id
                              }
                              name={`products.${index}.product_id`}
                              onblur={(e) => {
                                setFieldValue(
                                  `products.${index}.product_id`,
                                  e.target.value
                                );
                              }}
                            />
                            {errors.products &&
                            errors.products[index] &&
                            errors.products[index].product_id &&
                            touched.products &&
                            touched.products[index] &&
                            touched.products[index].product_id ? (
                              <span className="text-xs text-red-500">
                                {errors.products[index].product_id}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="Product quantity" isRequired={true} />
                            <TextInput
                              placeholder="Enter Product quantity"
                              name={`products.${index}.quantity`}
                              isError={
                                errors.products &&
                                errors.products[index] &&
                                errors.products[index].quantity &&
                                touched.products &&
                                touched.products[index] &&
                                touched.products[index].quantity
                              }
                            />
                            {errors.products &&
                            errors.products[index] &&
                            errors.products[index].quantity &&
                            touched.products &&
                            touched.products[index] &&
                            touched.products[index].quantity ? (
                              <span className="text-xs text-red-500">
                                {errors.products[index].quantity}
                              </span>
                            ) : null}
                          </div>
                          <div className="mb-6">
                            <Label text="Price" isRequired={true} />
                            <TextInput
                              placeholder="Price"
                              name={`products.${index}.price`}
                              isError={
                                errors.products &&
                                errors.products[index] &&
                                errors.products[index].price &&
                                touched.products &&
                                touched.products[index] &&
                                touched.products[index].price
                              }
                            />
                            {errors.products &&
                            errors.products[index] &&
                            errors.products[index].price &&
                            touched.products &&
                            touched.products[index] &&
                            touched.products[index].price ? (
                              <span className="text-xs text-red-500">
                                {errors.products[index].price}
                              </span>
                            ) : null}
                          </div>
                        </div>
                      ))}
                    <div
                      className="cursor-pointer flex items-center space-x-2 w-32 mt-4"
                      onClick={() =>
                        push({
                          product_id: "",
                          quantity: "",
                          price: "",
                        })
                      }
                    >
                      <BiPlusCircle className="text-xl text-[#3C81FC]" />
                      <p className="text-[#3C81FC] text-base font-semibold">
                        Add product
                      </p>
                    </div>
                  </>
                )}
              />
            </div>

            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleReset();
                }}
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormMaterialConsumption;
