import axios from "axios";

const { REACT_APP_BASE_URL, REACT_APP_BASE_TOKEN } = process.env;

const api = axios.create({
  baseURL: `${REACT_APP_BASE_URL}`,
  headers: {
    "app-token": REACT_APP_BASE_TOKEN,
    "Content-type": "application/json",
    Accept: "*/*",
  },
});

api.interceptors.request.use((req) => {
  if (localStorage.getItem("pos-admin-access")) {
    req.headers.Authorization = `${localStorage.getItem("pos-admin-access")}`;
  }
  return req;
});

export const login = (data) => api.post("/account/login", data);
export const changePassword = (data) =>
  api.post("/account/change-password", data);
export const logout = () => api.post("/account/logout", {});

// Supplier
export const createSupplier = (data) => api.post("/supplier/create", data);
export const updateSupplier = (data) => api.post("/supplier/update", data);
export const getSupplierById = (data) => api.post("/supplier/getbyid", data);
export const getAllSuppliers = (data) => api.post("/supplier/getall", data);
export const deleteSupplier = (data) => api.post("/supplier/delete", data);

// Customer
export const createCustomer = (data) => api.post("/customer/create", data);
export const updateCustomer = (data) => api.post("/customer/update", data);
export const getCustomerById = (data) => api.post("/customer/getbyid", data);
export const getAllCustomers = (data) => api.post("/customer/getall", data);
export const deleteCustomer = (data) => api.post("/customer/delete", data);

export const getGeneralCustomers = (data) =>
  api.post("/customer/getbygeneral", data);
export const getWholesaleCustomers = (data) =>
  api.post("/customer/getbywholesaler", data);

// Employee
export const createEmployee = (data) => api.post("/employee/create", data);
export const updateEmployee = (data) => api.post("/employee/update", data);
export const getEmployeeById = (data) => api.post("/employee/getbyid", data);
export const getAllEmployees = (data) => api.post("/employee/getall", data);
export const deleteEmployee = (data) => api.post("/employee/delete", data);

//Product
export const createProduct = (data) => api.post("/product/create", data);
export const updateProduct = (data) => api.post("/product/update", data);
export const getProductById = (data) => api.post("/product/getbyid", data);
export const getAllProducts = (data) => api.post("/product/getall", data);
export const deleteProduct = (data) => api.post("/product/delete", data);
export const adjustStock = (data) => api.post("/product/adjuststock", data);

// Department
export const createDepartment = (data) => api.post("/department/create", data);
export const updateDepartment = (data) => api.post("/department/update", data);
export const getDepartmentById = (data) =>
  api.post("/department/getbyid", data);
export const getAllDepartments = (data) => api.post("/department/getall", data);
export const deleteDepartment = (data) => api.post("/department/delete", data);

// Brand
export const createBrand = (data) => api.post("/brand/create", data);
export const updateBrand = (data) => api.post("/brand/update", data);
export const getBrandById = (data) => api.post("/brand/getbyid", data);
export const getAllBrands = (data) => api.post("/brand/getall", data);
export const deleteBrand = (data) => api.post("/brand/delete", data);

//POS-management
export const createPOS = (data) => api.post("/user/create", data);
export const getAllUser = (data) => api.post("/user/getall", data);
export const updateUser = (data) => api.post("/user/update", data);
export const getUserById = (data) => api.post("/user/getbyid", data);
export const deleteUser = (data) => api.post("/user/delete", data);

// Stock
export const getAllStock = (data) => api.post("/stock/getall", data);

//MaterialConsumption
export const getAllConsumType = (data) =>
  api.post("/material-consumption-type/getall", data);
export const createMaterialConsmType = (data) =>
  api.post("/material-consumption/create", data);
export const getAllMaterialConsm = (data) =>
  api.post("/material-consumption/getall", data);
export const deleteMaterialConsm = (data) =>
  api.post("/material-consumption/delete", data);
export const getMaterialConsmById = (data) =>
  api.post("/material-consumption/getbyid", data);
export const updateMaterialConsm = (data) =>
  api.post("/material-consumption/update", data);

//Loyalty
export const createLoyalty = (data) =>
  api.post("/loyalty-reedeems/create", data);
export const getAllLoyalty = (data) =>
  api.post("/loyalty-reedeems/getall", data);

//Recipe
export const getAllRecipe = (data) => api.post("/recipe/getall", data);
export const createRecipe = (data) => api.post("/recipe/create", data);
export const deleteRecipe = (data) => api.post("/recipe/delete", data);
export const getRecipeById = (data) => api.post("/recipe/getbyid", data);
export const updateRecipe = (data) => api.post("/recipe/update", data);

export const checkPassValid = (data) => api.post("/isPassValid", data);

// Customer Display
export const createUserDisplay = (data) =>
  api.post("/front-user-display/create", data);
export const updateDisplay = (data) =>
  api.post("/front-user-display/update", data);
export const getDisplay = (data) =>
  api.post("/front-user-display/getbyid", data);
