import React, { useState } from "react";
import { Header, Table1 } from "../../../../components";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import Supplier from "./Supplier";

const ProductList = () => {
  const params = useParams();
  const breadCrumbs = [
    { key: 0, name: "Contact", to: "/contact/Supplier", active: false },
    { key: 1, name: "Supplier", to: "/contact/Supplier", active: false },
    {
      key: 2,
      name: "Preview",
      to: "/contact/Supplier/view",
      active: true,
    },
  ];

  const data = React.useMemo(
    () => [
      {
        col1: "001",
        col2: "Jira Khakhra",
        col3: "200",
        col4: "£10.00",
        col5: "150",
        col6: "£12.00",

        col7: (
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-600"></div>
          </div>
        ),
        col8: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,
      },

      {
        col1: "002",
        col2: "Masala Khakhra",
        col3: "150",
        col4: "£12.00",
        col5: "100",
        col6: "£15.00",

        col7: (
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-600"></div>
          </div>
        ),
        col8: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,
      },

      {
        col1: "003",
        col2: "Batata",
        col3: "150 kg",
        col4: "£12.00/kg",
        col5: "80 kg",
        col6: "£14.00/kg",

        col7: (
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-600"></div>
          </div>
        ),
        col8: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,
      },
      {
        col1: "004",
        col2: "Sugam panner",
        col3: "52",
        col4: "£15.00",
        col5: "15",
        col6: "£20.05",

        col7: (
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-red-600"></div>
          </div>
        ),
        col8: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,
      },
      {
        col1: "005",
        col2: "Elmlea double",
        col3: "45",
        col4: "£15.00",
        col5: "40",
        col6: "£18",

        col7: (
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-600"></div>
          </div>
        ),
        col8: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,
      },
    ],
    []
  );

  console.log("DATA", data);

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Product name",
        accessor: "col2",
      },
      {
        Header: "Total quantity",
        accessor: "col3",
      },
      {
        Header: "Purchased Amt / piece",
        accessor: "col4",
      },
      {
        Header: "Sold quantity",
        accessor: "col5",
      },
      {
        Header: "Sold amt/Qut",
        accessor: "col6",
      },
      {
        Header: "Status",
        accessor: "col7",
      },

      {
        Header: "",
        accessor: "col8",
      },
    ],
    []
  );

  return (
    <>
      <Header
        breadCrumbs={breadCrumbs}
        title={`Preview supplier details ${data[1].col2}`}
      />
      <div className="flex items-center mx-6 my-6 space-x-8">
        <Link
          to={`/contact/supplier-details/${params.id}`}
          className="text-sm pb-2"
        >
          Basic details
        </Link>
        <Link
          to={`/contact/supplier-bill/${params.id}`}
          className="text-sm pb-2"
        >
          Supplier bill
        </Link>
        <Link to={`/contact/dabit-note/${params.id}`} className="text-sm pb-2">
          Dabit note
        </Link>
        <Link
          to={`/contact/product-list/${params.id}`}
          className="text-sm font-bold border-b-2 border-[#3C81FC] pb-2"
        >
          Product list
        </Link>
      </div>

      <Table1 columns={columns} data={data} />
    </>
  );
};

export default ProductList;
