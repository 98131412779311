import { FieldArray, Form, Formik } from "formik";
import React, { useState } from "react";
import { BiPlusCircle } from "react-icons/bi";
import { Datepicker, Header, Label, TextInput } from "../../../../components";
import moment from "moment";

const FormStockTransfer = () => {
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    {
      key: 1,
      name: "Stock Transfer",
      to: "/inventory/stock-transfer",
      active: false,
    },
    {
      key: 2,
      name: "Add Stock Transfer",
      to: "/inventory/stock-transfer/add",
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    from_branch: "",
    to_branch: "",
    date: "",
    products: [
      {
        product_id: "",
        barcode: "",
        quantity: "",
        price: "",
        discount: "",
        tax: "",
        total: "",
      },
    ],
    sub_total: "",
    tax: "",
    discount: "",
    total: "",
  });

  const [value, onChange] = useState(new Date());

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add stock transfer" />
      <Formik
        initialValues={initialValues}
        // validationSchema={LoginSchema}
        onSubmit={(values) => {
          console.log(values);
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">
                  Stock transfer information
                </p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="From branch" isRequired={true} />
                  <TextInput
                    placeholder="Select From branch"
                    name="from_branch"
                    isError={errors.from_branch && touched.from_branch}
                  />
                  {errors.from_branch && touched.from_branch ? (
                    <span className="text-xs text-red-500">
                      {errors.from_branch}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="To branch" isRequired={true} />
                  <TextInput
                    placeholder="Select To branch"
                    name="to_branch"
                    isError={errors.to_branch && touched.to_branch}
                  />
                  {errors.to_branch && touched.to_branch ? (
                    <span className="text-xs text-red-500">
                      {errors.to_branch}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Transfer date" isRequired={true} />
                  <Datepicker
                    name="date"
                    isError={
                      errors.date &&
                      touched.date
                    }
                    onNewChange={(date) => {
                      setFieldValue(
                        "date",
                        moment(date).format("MM/DD/YYYY")
                      );
                    }}
                  />

                  {errors.date && touched.date ? (
                    <span className="text-xs text-red-500">{errors.date}</span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Raw product</p>
              </div>
              <div>
                <FieldArray
                  name="products"
                  render={({ insert, remove, push }) => (
                    <>
                      <table className="min-w-full table-fixed">
                        <thead className="bg-gray-100">
                          <tr>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              #No
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Item / Barcode
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Product
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Quantity
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Price / Qnt
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Discount
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Tax
                            </th>
                            <th
                              scope="col"
                              className="py-3 px-2 text-xs font-medium tracking-wider text-left text-gray-700"
                            >
                              Amount
                            </th>
                          </tr>
                        </thead>
                        <tbody className="bg-white divide-y divide-gray-200 dark:bg-gray-800 dark:divide-gray-700">
                          {values.products.length > 0 &&
                            values.products.map((item, index) => (
                              <tr key={index}>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  {index + 1}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-500 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Code"
                                    name={`products.${index}.barcode`}
                                    isError={
                                      errors.products &&
                                      errors.products[index] &&
                                      errors.products[index].barcode &&
                                      touched.products &&
                                      touched.products[index].barcode
                                    }
                                  />
                                  {errors.products &&
                                  errors.products[index] &&
                                  errors.products[index].barcode &&
                                  touched.products &&
                                  touched.products[index].barcode ? (
                                    <span className="text-xs text-red-500">
                                      {errors.products[index].barcode}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Product Name"
                                    name={`products.${index}.product_id`}
                                    isError={
                                      errors.products &&
                                      errors.products[index] &&
                                      errors.products[index].product_id &&
                                      touched.products &&
                                      touched.products[index].product_id
                                    }
                                  />
                                  {errors.products &&
                                  errors.products[index] &&
                                  errors.products[index].product_id &&
                                  touched.products &&
                                  touched.products[index].product_id ? (
                                    <span className="text-xs text-red-500">
                                      {errors.products[index].product_id}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter quantity"
                                    name={`products.${index}.quantity`}
                                    isError={
                                      errors.products &&
                                      errors.products[index] &&
                                      errors.products[index].quantity &&
                                      touched.products &&
                                      touched.products[index].quantity
                                    }
                                  />
                                  {errors.products &&
                                  errors.products[index] &&
                                  errors.products[index].quantity &&
                                  touched.products &&
                                  touched.products[index].quantity ? (
                                    <span className="text-xs text-red-500">
                                      {errors.products[index].quantity}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Price"
                                    name={`products.${index}.price`}
                                    isError={
                                      errors.products &&
                                      errors.products[index] &&
                                      errors.products[index].price &&
                                      touched.products &&
                                      touched.products[index].price
                                    }
                                  />
                                  {errors.products &&
                                  errors.products[index] &&
                                  errors.products[index].price &&
                                  touched.products &&
                                  touched.products[index].price ? (
                                    <span className="text-xs text-red-500">
                                      {errors.products[index].price}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  <TextInput
                                    placeholder="Enter Discount"
                                    name={`products.${index}.discount`}
                                    isError={
                                      errors.products &&
                                      errors.products[index] &&
                                      errors.products[index].discount &&
                                      touched.products &&
                                      touched.products[index].discount
                                    }
                                  />
                                  {errors.products &&
                                  errors.products[index] &&
                                  errors.products[index].discount &&
                                  touched.products &&
                                  touched.products[index].discount ? (
                                    <span className="text-xs text-red-500">
                                      {errors.products[index].discount}
                                    </span>
                                  ) : null}
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  0.00
                                </td>
                                <td className="py-2 px-2 text-sm font-medium text-gray-900 whitespace-nowrap">
                                  0.00
                                </td>
                              </tr>
                            ))}
                        </tbody>
                      </table>
                      <div
                        className="cursor-pointer flex items-center space-x-2 w-32 mt-4"
                        onClick={() =>
                          push({
                            product_id: "",
                            barcode: "",
                            quantity: "",
                            price: "",
                            discount: "",
                            tax: "",
                            total: "",
                          })
                        }
                      >
                        <BiPlusCircle className="text-xl text-[#3C81FC]" />
                        <p className="text-[#3C81FC] text-base font-semibold">
                          Add product
                        </p>
                      </div>
                    </>
                  )}
                />
              </div>
            </div>

            <div className="p-6 flex  justify-end">
              <div className="p-6 bg-[#EAEFF8] border-[#EAEFF8] rounded w-1/3">
                <div className="flex flex-row justify-between items-center mb-3 text-sm">
                  <div>Subtotal</div>
                  <div>£20.00</div>
                </div>
                <div className="flex flex-row justify-between items-center mb-4 text-sm">
                  <div>Taxes</div>
                  <div>£4.00</div>
                </div>
                <div className="flex flex-row justify-between items-center mb-4 text-sm">
                  <div>Discount</div>
                  <div>£4.00</div>
                </div>
                <hr />
                <div className="flex flex-row justify-between items-center mb-4 pt-4 text-xl">
                  <div>Total</div>
                  <div>£4.00</div>
                </div>
              </div>
            </div>

            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleReset();
                }}
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormStockTransfer;
