import React, { useState } from "react";
import { Header, Table1 } from "../../../../components";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";





const SupplierBill = () => {
    const params = useParams();
    const breadCrumbs = [
        { key: 0, name: "Contact", to: "/contact/Supplier", active: false },
        { key: 1, name: "Supplier", to: "/contact/Supplier", active: false },
        {
            key: 2,
            name: "Preview",
            to: "/contact/Supplier/view",
            active: true,
        },
    ];
    const data = React.useMemo(
        () => [
            {
                col1: "001",
                col2: "123-456",
                col3: "12 June 2022",
                col4: "£50.00",
                col5: "cash",

              
                col6: (
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 rounded-full bg-green-600"></div>
                      <div>Paid</div>
                    </div>
                  ),
                col7: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,

            },
            {
                col1: "002",
                col2: "526-569",
                col3: "23 June 2022",
                col4: "£100.00",
                col5: "Cash",
                col6: (
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 rounded-full bg-green-600"></div>
                      <div>Paid</div>
                    </div>
                  ),
                col7: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,

            },

            {
                col1: "003",
                col2: "125-696",
                col3: "2 June 2022",
                col4: "£105.00",
                col5: "--",
                col6: (
                    <div className="flex items-center gap-2">
                      <div className="w-3 h-3 rounded-full bg-red-600"></div>
                      <div>Unpaid</div>
                    </div>
                  ),
                col7: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,

            },
        ],
        []
    );

    const columns = React.useMemo(
        () => [
            {
                Header: "#No",
                accessor: "col1", // accessor is the "key" in the data
            },
            {
                Header: "Bill no",
                accessor: "col2",
            },
            {
                Header: " Bill date",
                accessor: "col3",
            },
            {
                Header: " Amount",
                accessor: "col4",
            },
            {
                Header: "Payment mode",
                accessor: "col5",
            },
        
            {
                Header: "Status",
                accessor: "col6",
            },

            {
                Header: " ",
                accessor: "col7",
            },
        ],
        []
    );

    return (
        <>
            <Header breadCrumbs={breadCrumbs} title={`Preview supplier details ${data[1].col2}`} />
            <div className="flex items-center mx-6 my-6 space-x-8">
        <Link
          to={`/contact/supplier-details/${params.id}`}
          className="text-sm pb-2"
        >
          Basic details
        </Link>
        <Link
          to={`/contact/supplier-bill/${params.id}`}
          className="text-sm font-bold border-b-2 border-[#3C81FC] pb-2"
        >
         Supplier bill
        </Link>
        <Link
          to={`/contact/dabit-note/${params.id}`}
          className="text-sm pb-2"
        >
         Dabit note
        </Link>
        <Link
          to={`/contact/product-list/${params.id}`}
          className="text-sm pb-2"
        >
         Product list
        </Link>
      </div>
          
            <Table1 columns={columns} data={data} />

        </>
    )
}


export default SupplierBill;