import React, { useState } from "react";
import { BiPencil, BiPlusCircle, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Header } from "../../../components";
import * as api from "../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import moment from "moment";

const List = () => {
  const [user, setuser] = useState([]);

  const breadCrumbs = [
    { key: 0, name: "Dashboard", to: "", active: false },
    { key: 1, name: "POS Management", to: "/pos-management", active: true },
  ];

  const getList = async () => {
    try {
      const response = await api.getAllUser();

      if (response.status === 200) {
        setuser(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  useAsyncEffect(async () => {
    await getList();
  }, []);

  const deleteItem = async (id) => {
    try {
      const response = await api.deleteUser({ id });
      if (response.status === 200) {
        toast.success(response.data.message, { position: "top-right" });
        await getList();
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="POS Management" />
      <div className="flex flex-col">
        <div className="flex justify-end m-4">
          <Link
            className="flex items-center space-x-2 px-6 py-2 bg-[#3C81FC] text-white rounded-full"
            to="/pos-management/add"
          >
            <BiPlusCircle />
            <span>Add POS</span>
          </Link>
        </div>
        <div className="grid grid-cols-3 gap-8 m-8">
          {user.length > 0 &&
            user.map((pos, index) => (
              <div
                key={pos.index}
                className="p-6 border rounded-md drop-shadow-md"
              >
                <div className="flex items-center justify-between border-b pb-4">
                  <p className="text-lg font-bold">{pos.pos_name}</p>

                  <div className="flex items-center space-x-2 cursor-pointer" onClick={() => deleteItem(pos.id)}>
                    <BiTrash className="text-xl text-red-500" />
                    <span className="text-base font-semibold text-[#3C81FC]">
                      Delete User
                    </span>
                  </div>

                  <Link
                    to={`/pos-management/edit/${pos.id}`}
                    className="flex items-center space-x-2"
                  >
                    <BiPencil className="text-base text-[#3C81FC]" />
                    <span className="text-base font-semibold text-[#3C81FC]">
                      Edit Details
                    </span>
                  </Link>
                </div>
                <div className="grid grid-cols-3 gap-4 mt-8">
                  <div className="flex flex-col">
                    <p className="text-[#525D68] text-sm">User ID</p>
                    <p className="text-[#142243] text-base">{pos.account_id}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#525D68] text-sm">Manage by</p>
                    <p className="text-[#142243] text-base">{pos.username}</p>
                  </div>
                  <div className="flex flex-col">
                    <p className="text-[#525D68] text-sm">Created on</p>
                    <p className="text-[#142243] text-base">
                      {moment(pos.created_datetime).format("D MMM YYYY")}
                    </p>
                  </div>
                </div>
              </div>
            ))}
        </div>
      </div>
    </>
  );
};

export default List;
