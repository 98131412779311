import React, { useState } from "react";
import { Header, Label, Table } from "../../../../components";
import DateTimePicker from "react-datetime-picker";

const StockTransfer = () => {
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    {
      key: 1,
      name: "Stock transfer",
      to: "/inventory/stock-transfer",
      active: true,
    },
  ];

  const data = React.useMemo(
    () => [
      {
        col1: "001",
        col2: "12 Apr 2022",
        col3: "TRS001",
        col4: "Rothwell UK",
        col5: "Rothwell US",
        col6: "£500.00",
        col7: "80",
        col8: (
          <div className="flex items-center gap-2">
            <div className="w-3 h-3 rounded-full bg-green-600"></div>
            <div>Active</div>
          </div>
        ),
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Transfer date",
        accessor: "col2",
      },
      {
        Header: "Transfer No",
        accessor: "col3",
      },
      {
        Header: "From branch",
        accessor: "col4",
      },
      {
        Header: "To branch",
        accessor: "col5",
      },
      {
        Header: "Total Amt",
        accessor: "col6",
      },
      {
        Header: "Qnt",
        accessor: "col7",
      },
      {
        Header: "Status",
        accessor: "col8",
      },
      {
        Header: "",
        accessor: "col9",
      },
    ],
    []
  );

  const addNewData = [
    {
      title: "Add Stock transfer",
      to: "/inventory/stock-transfer/add",
    },
  ];
  const [value, onChange] = useState(new Date());

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Stock transfer" />
      <div className="flex flex-col mx-6 my-6">
        <Label text="Select print date & time" isRequired={true} />
        <DateTimePicker
          className="border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-1/4 sm:text-sm focus:ring-1"
          amPmAriaLabel="Select AM/PM"
          calendarAriaLabel="Toggle calendar"
          clearAriaLabel="Clear value"
          dayAriaLabel="Day"
          hourAriaLabel="Hour"
          maxDetail="second"
          minuteAriaLabel="Minute"
          monthAriaLabel="Month"
          nativeInputAriaLabel="Date and time"
          onChange={onChange}
          secondAriaLabel="Second"
          value={value}
          yearAriaLabel="Year"
        />
      </div>
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};

export default StockTransfer;
