import React from "react";
import NoImage from "../../assets/images/no-image.svg";
import PDFIcon from "../../assets/images/pdf-icon.svg";

const ImagePreview = ({ file }) => {
  const fileDetails = {
    type: file.type,
    name: file.name,
    size: (file.size / 1024).toFixed(2),
  };

  return (
    <div className="p-2 flex justify-between items-center">
      <div className="flex items-center space-x-4">
        <img
          src={
            fileDetails.type === "image/png" ||
            fileDetails.type === "image/jpg" ||
            fileDetails.type === "image/jpeg"
              ? NoImage
              : fileDetails.type === "application/pdf"
              ? PDFIcon
              : null
          }
          alt="preview"
        />
        <div className="flex flex-col">
          <p className="text-sm text-[#142243]">{fileDetails.name}</p>
          <p className="text-xs text-[#525D68]">{fileDetails.size} KB</p>
        </div>
      </div>
    </div>
    // <>
    //   {fileDetails.type === "image/png" ||
    //   fileDetails.type === "image/jpg" ||
    //   fileDetails.type === "image/jpeg" ? (

    //   ) : null}
    // </>
  );
};

export default ImagePreview;
