import { Form, Formik } from "formik";
import React, { useState } from "react";
import {
  BiCaretDown,
  BiCaretUp,
  BiFilter,
  BiPlus,
  BiSearchAlt,
} from "react-icons/bi";
import { Link } from "react-router-dom";
import {
  useGlobalFilter,
  usePagination,
  useSortBy,
  useTable,
} from "react-table";
import Button from "../Button/Button";
import TextInput from "../Form/TextInput/TextInput";
import Label from "../Label/Label";
import Modal from "../Modal/Modal";

const Table1 = ({ columns, data, addData, otherList, priceListModal }) => {
  const [isPriceListModalOpen, setPriceListModalOpen] = useState(false);

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    previousPage,
    canNextPage,
    canPreviousPage,
    prepareRow,
  } = useTable({ columns, data }, useGlobalFilter, useSortBy, usePagination);
  return (
    <div className="mx-6 my-6">
      <div className="flex flex-col  shadow-lg shadow-[#3C81FC]/20">
        <div className="flex flex-row justify-center items-center gap-8">
          {addData &&
            addData.map((data, i) => (
              <Link key={i} to={data.to} className="flex items-center gap-2">
                <BiPlus className="h-6 w-6 text-gray-500" />{" "}
                <p className="text-gray-500 text-base">{data.title}</p>
              </Link>
            ))}
          {otherList &&
            otherList.map((data, i) => (
              <p
                key={i}
                className="flex items-center gap-2 cursor-pointer"
                onClick={() => setPriceListModalOpen(true)}
              >
                {data.icon}
                <p className="text-gray-500 text-base">{data.title}</p>
              </p>
            ))}
        </div>
        <table {...getTableProps()} className="w-full bg-white border">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-6 py-3 text-sm border-b border-gray-300"
                  >
                    {/* {column.render("Header")} */}
                    <div className="flex flex-row justify-start items-center space-x-4">
                      <span>{column.render("Header")}</span>
                      <span>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <BiCaretDown />
                          ) : (
                            <BiCaretUp />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          {data.length > 0 ? (
            <tbody {...getTableBodyProps()}>
              {page.map((row) => {
                prepareRow(row);
                return (
                  <tr className="bg-white border-b" {...row.getRowProps()}>
                    {row.cells.map((cell) => {
                      return (
                        <td
                          {...cell.getCellProps()}
                          className="px-6 py-3 text-sm border-b  border-gray-200 whitespace-nowrap"
                        >
                          {cell.render("Cell")}
                        </td>
                      );
                    })}
                  </tr>
                );
              })}
            </tbody>
          ) : (
            <tbody>
              <tr>
                <td className="text-center" colSpan={columns.length}>
                  {/* <img src={NoOrder} className="text-center" alt="no-order" /> */}
                  <p className="font-bold my-12">No data available</p>
                </td>
              </tr>
            </tbody>
          )}
        </table>
        <div className="flex items-center justify-between border-l border-r px-6 py-3 bg-white rounded-b-lg">
          <div className="flex justify-between items-center flex-1">
            <p
              onClick={() => previousPage()}
              className={`${
                !canPreviousPage
                  ? "disabled text-gray-400 cursor-not-allowed"
                  : ""
              } relative inline-flex items-center text-sm font-medium text-gray-700"`}
            >
              Previous
            </p>
            <p
              onClick={() => nextPage()}
              className={`${
                !canNextPage ? "disabled text-gray-400 cursor-not-allowed" : ""
              } relative inline-flex items-center text-sm font-medium text-gray-700 `}
            >
              Next
            </p>
          </div>
        </div>
      </div>
      {isPriceListModalOpen && (
        <Modal setIsOpen={setPriceListModalOpen}>
          <div className="w-[30rem] h-auto p-8">
            <h1 className="text-[#142243] font-semibold text-lg">
              Send Price list
            </h1>

            <div className="mt-6">
              <Formik
                initialValues={{
                  send_type: "",
                  whom: "",
                }}
                // validationSchema={PasswordSchema}
                onSubmit={(values) => {
                  console.log(values);
                }}
              >
                {({ errors, touched, handleReset, setFieldValue }) => (
                  <Form>
                    <div className="mb-6">
                      <div className="flex justify-start gap-6">
                        <div>
                          <input
                            className="sr-only peer"
                            type="radio"
                            value="Email"
                            name="send_type"
                            id="email"
                            onChange={() => {
                              setFieldValue("send_type", "Email");
                            }}
                          />
                          <label
                            htmlFor="email"
                            className={`border border-highlight flex p-3 w-32 shadow-sm text-center rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center`}
                          >
                            Email
                          </label>
                        </div>
                        <input
                          className="sr-only peer"
                          type="radio"
                          value="Whatsapp"
                          name="send_type"
                          id="whatsapp"
                          onChange={() => {
                            setFieldValue("send_type", "Whatsapp");
                          }}
                        />
                        <label
                          htmlFor="whatsapp"
                          className="flex p-3 w-32 shadow-sm text-center border border-highlight rounded-lg cursor-pointer focus:outline-none hover:bg-gray-50 peer-checked:ring-blue-500 peer-checked:ring-2 peer-checked:border-transparent justify-center"
                        >
                          Whatsapp
                        </label>
                      </div>
                    </div>

                    <div className="mb-6">
                      <Label text="Send To" isRequired={false} />
                      <TextInput
                        placeholder="Select whom to send"
                        name="whom"
                        isError={errors.whom && touched.whom}
                      />
                      {errors.whom && touched.whom ? (
                        <span className="text-xs text-red-500">
                          {errors.whom}
                        </span>
                      ) : null}
                    </div>
                    <div className="flex items-center justify-center space-x-4">
                      <button
                        onClick={(event) => {
                          event.preventDefault();
                          handleReset();
                        }}
                        className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
                      >
                        Cancel
                      </button>
                      <button
                        type="submit"
                        className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
                      >
                        Save
                      </button>
                    </div>
                  </Form>
                )}
              </Formik>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default Table1;
