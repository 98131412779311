import { Form, Formik } from "formik";
import React, { useState } from "react";
import { Header, Label, TextInput } from "../../../components";
import * as Yup from "yup";
import * as api from "../../../http";
import { toast } from "react-hot-toast";
import { useNavigate } from "react-router-dom";

const ChangePassword = () => {
  const navigate = useNavigate();
  const breadCrumbs = [
    { key: 0, name: "Dashboard", to: "", active: false },
    { key: 1, name: "Change Password", to: "/change-password", active: true },
  ];

  const [initialValues, setInitialValues] = useState({
    old_password: "",
    new_password: "",
    confirm_password: "",
  });

  const ChangePasswordSchema = Yup.object().shape({
    old_password: Yup.string().required("Please enter your current password"),
    new_password: Yup.string()
      .required("Please enter your new password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      ),
    confirm_password: Yup.string().oneOf(
      [Yup.ref("new_password"), null],
      "Confirm password should match with the new password"
    ),
  });

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Change Password" />
      <Formik
        initialValues={initialValues}
        validationSchema={ChangePasswordSchema}
        onSubmit={async (values) => {
          delete values.confirm_password;
          values.id = JSON.parse(localStorage.getItem("pos-admin-user")).id;

          try {
            const response = await api.changePassword(values);
            if (response.status === 200 || response.status === 201) {
              toast.success(response.data.message, { position: "top-right" });
              localStorage.removeItem("pos-admin-user");
              localStorage.removeItem("pos-admin-access");
              navigate("/");
              // const logout = await api.logout()

              // if(logout.status === 200) {
              //     console.log(logout)
              // } else {
              //   toast.error(response.data.message, { position: "top-right" });
              // }
            } else {
              toast.error(response.data.message, { position: "top-right" });
            }
          } catch (err) {
            console.log(":>>", err);
          }
        }}
      >
        {({ errors, touched, handleReset }) => (
          <Form className="flex justify-center w-full">
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100 w-[36rem]">
              <div className="mb-5 flex items-center justify-between">
                <p className="text-xl font-semibold">Change Password</p>
                <p className="text-xs  text-[#525D68]">
                  Last change 10 June 2022
                </p>
              </div>
              <div className="mb-8">
                <p className="text-sm  text-[#525D68]">
                  Choose a unique password to Protect your Account
                </p>
              </div>
              <div className="grid grid-rows-4 grid-flow-col gap-y-2">
                <div>
                  <Label text="Current Password" isRequired={true} />
                  <TextInput
                    placeholder="Enter Current Password"
                    name="old_password"
                    isError={errors.old_password && touched.old_password}
                  />
                  {errors.old_password && touched.old_password ? (
                    <span className="text-xs text-red-500">
                      {errors.old_password}
                    </span>
                  ) : null}
                </div>

                <div>
                  <Label text="New Password" isRequired={true} />
                  <TextInput
                    placeholder="Enter New Password"
                    name="new_password"
                    isError={errors.new_password && touched.new_password}
                  />
                  {errors.new_password && touched.new_password ? (
                    <span className="text-xs text-red-500">
                      {errors.new_password}
                    </span>
                  ) : null}
                </div>

                <div>
                  <Label text="Confirm Password" isRequired={true} />
                  <TextInput
                    placeholder="Enter confirm Password"
                    name="confirm_password"
                    isError={
                      errors.confirm_password && touched.confirm_password
                    }
                  />
                  {errors.confirm_password && touched.confirm_password ? (
                    <span className="text-xs text-red-500">
                      {errors.confirm_password}
                    </span>
                  ) : null}
                </div>
                <div className="flex items-center gap-x-4 w-full justify-end">
                  <button
                    type="submit"
                    className="bg-[#1267FF] py-3 px-8 !text-white rounded-full w-40"
                  >
                    Save
                  </button>
                  <button
                    onClick={(event) => {
                      event.preventDefault();
                      handleReset();
                    }}
                    className="border border-[#1267FF] py-3 px-8 !text-[#1267FF] rounded-full w-40"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default ChangePassword;
