import React, { useState } from "react";
import { NavLink } from "react-router-dom";
import { BiChevronDown } from "react-icons/bi";

const NavItem = ({ navItem }) => {
  const [isSubMenuShow, setIsSubMenuShow] = useState(false);

  return (
    <div>
      <div className="flex px-8 py-3 items-center justify-between">
        {/* FOR ACTIVE TAB: bg-[#E6EDF9] rounded  */}
        <div className="flex items-center space-x-4 w-full">
          {navItem.icon}

          {navItem.to === "" ? (
            <p className="text-base text-[#142243] font-bold">{navItem.name}</p>
          ) : (
            <NavLink
              to={navItem.to}
              className="text-base text-[#142243] font-bold"
            >
              {navItem.name}
            </NavLink>
          )}
        </div>
        <>
          {navItem.sub_items.length > 0 && (
            <BiChevronDown
              className="text-2xl cursor-pointer"
              onClick={() => setIsSubMenuShow(!isSubMenuShow)}
            />
          )}
        </>
      </div>
      {navItem.sub_items.length > 0 &&
        isSubMenuShow &&
        navItem.sub_items.map((item) => (
          <div className="px-4 py-1 ml-14" key={item.key}>
            <NavLink
              className={({ isActive }) =>
                isActive
                  ? "text-sm text-[#142243] font-bold"
                  : "text-sm text-[#525D68]"
              }
              to={item.to}
            >
              {item.name}
            </NavLink>
          </div>
        ))}
    </div>
  );
};

export default NavItem;
