import React, { useState } from "react";
import { BiAlbum, BiPencil, BiTrash } from "react-icons/bi";
import { Link } from "react-router-dom";
import { Header, Table } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import { BsEyeFill } from "react-icons/bs";
import { confirmAlert } from "react-confirm-alert"; // Import
import "react-confirm-alert/src/react-confirm-alert.css"; // Import css

const Customer = () => {
  const breadCrumbs = [
    { key: 0, name: "Contact", to: "", active: false },
    { key: 1, name: "Customer", to: "/contact/customer", active: true },
  ];

  const [customers, setCustomers] = useState([]);

  const getList = async () => {
    try {
      const response = await api.getAllCustomers();
      if (response.status === 200) {
        setCustomers(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };
  useAsyncEffect(async () => {
    await getList();
  }, []);

  const data = React.useMemo(() => {
    const formattedData = [];
    const confirmDelete = (id) => {
      confirmAlert({
        message: "Are you sure to do this.",
        buttons: [
          {
            label: "Yes",
            onClick: () => deleteItem(id),
          },
          {
            label: "No",
          },
        ],
      });
    };
    const deleteItem = async (id) => {
      try {
        const response = await api.deleteCustomer({ id });
        if (response.status === 200) {
          toast.success(response.data.message, { position: "top-right" });
          await getList();
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        console.log("Err:", err);
      }
    };

    customers?.map((data, index) =>
      formattedData.push({
        col1: data.name,
        col2: data.customer_type,
        col3: data.mobile_no,
        col4: data.email,
        col5: (
          <div className="flex items-center gap-2">
            {data.status === "Active" && (
              <>
                <div className="w-3 h-3 rounded-full bg-green-600"></div>
                <div>Active</div>
              </>
            )}
            {data.status === "Inactive" && (
              <>
                <div className="w-3 h-3 rounded-full bg-red-600"></div>
                <div>Inactive</div>
              </>
            )}
          </div>
        ),
        col6: (
          <div className="flex items-center space-x-4">
            <Link
              className="cursor-pointer"
              to={`/contact/customer-details/${data.id}`}
            >
              <BsEyeFill className="text-xl text-[#3C81FC]" />
            </Link>
            <Link
              className="cursor-pointer"
              to={`/contact/customer/edit/${data.id}`}
            >
              <BiPencil className="text-xl text-[#3C81FC]" />
            </Link>

            <p
              className="cursor-pointer"
              onClick={() => confirmDelete(data.id)}
            >
              <BiTrash className="text-xl text-red-500" />
            </p>
          </div>
        ),
      })
    );

    return formattedData;
  }, [customers]);

  const columns = React.useMemo(
    () => [
      {
        Header: "Customer name",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Type",
        accessor: "col2",
      },
      {
        Header: "Mobile number",
        accessor: "col3",
      },
      {
        Header: "Email ID",
        accessor: "col4",
      },
      {
        Header: "Status",
        accessor: "col5",
      },
      {
        Header: "",
        accessor: "col6",
      },
    ],
    []
  );

  const addNewData = [
    {
      title: "Add New Customer",
      to: "/contact/customer/add",
    },
  ];

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Customer" />
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};
export default Customer;
