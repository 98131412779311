import React from "react";
import Select from "react-select";

const MultiSelects = ({ selectOptions, isError, name, onchange }) => {
  const customStyles = {
    control: (provided) => ({
      ...provided,
      marginTop: "0.5rem",
      innerHeight: "2rem",
      borderWidth: "1px",
      borderColor: "#DBE4F2",
      padding: "0.3rem",
      borderRadius: "0.25rem",
      fontSize: "0.875rem",
      lineHeight: "1.25rem",
    }),
  };

  return (
    <>
      <Select
        options={selectOptions}
        isMulti={true}
        name={name}
        selectOptions={`${
          isError ? "!border-red-500" : null
        } custom-select border border-[#DBE4F2] mt-2 p-2 h-12 rounded bg-white text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
        onChange={onchange}
        styles={customStyles}
      />
    </>
  );
};

export default MultiSelects;
