import { Field } from "formik";
import React from "react";

const RadioButton = ({ name, value, isError }) => {
  return (
    <>
      <Field
        type="radio"
        name={name}
        value={value}
        className={`${
          isError ? "!border-red-500" : null
        } h-4 w-4 border-gray-300 focus:ring-2 focus:ring-blue-300`}
      />
    </>
  );
};

export default RadioButton;
