import React, { useState } from "react";
import { Header, Table } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";

const Stock = () => {
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    { key: 1, name: "Stock", to: "/inventory/stock", active: true },
  ];

  const [stock, setStock] = useState([]);

  const getList = async () => {
    try {
      const response = await api.getAllStock();
      if (response.status === 200) {
        setStock(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };
  useAsyncEffect(async () => {
    await getList();
  }, []);

  const data = React.useMemo(() => {
    const formattedData = [];

    stock?.map((data, index) =>
      formattedData.push({
        col1: index + 1,
        col2: data.barcode,
        col3: data.product_name,
        col4: data.brand_name,
        col5: data.wholesale_box_product_quantity,
        col6: data.mrp,
        col7: data.landing_cost,
        col8: data.landing_cost
      })
    );

    return formattedData;
  }, [stock]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Item code",
        accessor: "col2",
      },
      {
        Header: "Product name",
        accessor: "col3",
      },
      {
        Header: "Brand",
        accessor: "col4",
      },
      {
        Header: "Available Qnt",
        accessor: "col5",
      },
      {
        Header: "MRP",
        accessor: "col6",
      },
      {
        Header: "Stock value",
        accessor: "col7",
      },
      {
        Header: "Landing stock value",
        accessor: "col8",
      },
    ],
    []
  );

  const addNewData = [];

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Stock" />
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};

export default Stock;
