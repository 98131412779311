import { Form, Formik } from "formik";
import React, { useState } from "react";
import { BiPlus } from "react-icons/bi";
import {
  Header,
  Label,
  TextInput,
  SingleImagePreview,
} from "../../../../../components";
import NoUpload from "../../../../../assets/images/no-upload.svg";
import * as api from "../../../../../http";
import { toast } from "react-hot-toast";
import * as Yup from "yup";
import { useNavigate, useParams } from "react-router-dom";
import { useAsyncEffect } from "use-async-effect";

const FormBrand = () => {
  const params = useParams();
  const navigate = useNavigate();

  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    {
      key: 1,
      name: "Department / Brand",
      to: "/inventory/brand",
      active: false,
    },
    {
      key: 2,
      name: `${params.type === "add" ? "Add Brand" : "Edit Brand"}`,
      to: `${params.type} === "add" ? '/inventory/brand/add' : '/inventory/brand/edit/${params.id}}'`,
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    name: "",
    code: "",
    description: "",
    image: "",
  });

  useAsyncEffect(async () => {
    if (params.type === "edit" && params.id !== "") {
      try {
        const response = await api.getBrandById({ id: params.id });
        if (response.status === 200) {
          response.data.data[0].image =
            response.data.url + response.data.data[0].image;
          setInitialValues(response.data.data[0]);
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  const BrandSchema = Yup.object().shape({
    image: Yup.mixed().required("Image is required"),
    name: Yup.string().required("Name is required"),
    code: Yup.string().required("Code is required"),
    description: Yup.string().required("Description is required"),
  });

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add Brand" />
      <Formik
        initialValues={initialValues}
        validationSchema={BrandSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          let formData = new FormData();
          for (var key in values) {
            formData.append(key, values[key]);
          }

          if (params.type === "add") {
            try {
              const response = await api.createBrand(formData);
              if (response.data.status === 200 || response.data.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/inventory/brand");
              } else if (response.data.status === 204) {
                toast.error(response.data.message, { position: "top-right" });
              }
              else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (params.type === "edit" && params.id !== "") {
            formData.append("id", params.id);

            try {
              if(typeof formData.get("image") == "string"){
                formData.delete("image");
              } 
              const response = await api.updateBrand(formData);
              if (response.data.status === 200 || response.data.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/inventory/brand");
              } else if (response.data.status === 204) {
                toast.error(response.data.message, { position: "top-right" });
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, values, handleReset, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
              <div className="mb-6">
                <p className="text-xl font-semibold">Brand information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="grid grid-cols-3 grid-flow-row gap-x-10 mb-6">
                  <div className="col-span-3 flex items-center space-x-8">
                    {values.image && params.type === "add" && (
                      <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                        <SingleImagePreview file={values.image} />
                      </div>
                    )}
                    {typeof values.image == "string" && params.type === "edit" && (
                      <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                        <img
                          src={values.image}
                          alt="preview"
                          className="w-32"
                        />
                      </div>
                    )}
                    {typeof values.image == "object" && params.type === "edit" && (
                      <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
                        <SingleImagePreview file={values.image} />
                      </div>
                    )}
                    {values.image === "" && params.type === "add" && (
                      <img
                        src={NoUpload}
                        className={`${
                          errors.image && touched.image
                            ? "border border-red-500 rounded-md"
                            : null
                        }`}
                        alt=""
                      />
                    )}
                    <label
                      htmlFor="upload"
                      className="flex flex-row items-center gap-2 cursor-pointer"
                    >
                      <BiPlus className="text-xl text-[#3C81FC]" />
                      <span className="text-sm text-[#3C81FC]">Add Image</span>
                    </label>
                    <input
                      id="upload"
                      type="file"
                      className="hidden"
                      name="image"
                      onChange={(e) => {
                        setFieldValue("image", e.target.files[0]);
                      }}
                    />
                  </div>
                  {errors.image && touched.image ? (
                    <span className="text-xs mt-2 text-red-500">
                      {errors.image}
                    </span>
                  ) : null}
                </div>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="Brand name" isRequired={true} />
                  <TextInput
                    placeholder="Enter brand name"
                    name="name"
                    isError={errors.name && touched.name}
                  />
                  {errors.name && touched.name ? (
                    <span className="text-xs text-red-500">{errors.name}</span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Code" isRequired={true} />
                  <TextInput
                    placeholder="Enter code"
                    name="code"
                    isError={errors.code && touched.code}
                  />
                  {errors.code && touched.code ? (
                    <span className="text-xs text-red-500">{errors.code}</span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Description" isRequired={true} />
                  <TextInput
                    placeholder="Enter description"
                    name="description"
                    isError={errors.description && touched.description}
                  />
                  {errors.description && touched.description ? (
                    <span className="text-xs text-red-500">
                      {errors.description}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>

            <div className="p-6 flex items-center gap-x-4 w-full justify-end mt-60">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleReset();
                }}
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormBrand;
