import React, { useState } from "react";
import { Header ,Table1} from "../../../../components";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";
import { useAsyncEffect } from "use-async-effect";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import moment from "moment";

const ProductSupplier = () => {
    const params = useParams();
  
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    { key: 1, name: "Product", to: "/inventory/product", active: false },
    {
      key: 2,
      name: "Preview",
      to: "/inventory/product-supplier/view",
      active: true,
    },
  ];


  const [productSupplier, setproductSupplier] = useState([]);
  console.log("product supplier details :",productSupplier);
  useAsyncEffect(async () => {

    if (params.id !== "") {
      try {
        const response = await api.getProductById({ id: params.id });

        if (response.status === 200) {
          setproductSupplier(response.data.data[0])
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);
 
  const data = React.useMemo(
    () => [
      {
        col1: productSupplier.id,
        col2:  productSupplier.supplier,
        col3:   moment(productSupplier.created_datetime).format("D MMM YYYY"),
        col4:   "C01002",
        col5: "£8.00",
        col6: productSupplier.batch_number,
        col7: "10",
        col8: "6",
        col9: "4",
        col10: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,
      }
     
    ],
   
  );


  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Supplier name",
        accessor: "col2",
      },
      {
        Header: "Date",
        accessor: "col3",
      },
      {
        Header: "Invoice No",
        accessor: "col4",
      },
      {
        Header: "Price",
        accessor: "col5",
      },
      {
        Header: "Batch No",
        accessor: "col6",
      },
      {
        Header: "In Qut",
        accessor: "col7",
      },
      {
        Header: "Out Qut",
        accessor: "col8",
      },
      {
        Header: "Closing",
        accessor: "col9",
      },
     
      {
        Header: "",
        accessor: "col10",
      },
    ],
    []
  );
  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Preview product details(Mahavir chevda)" />
      <div className="flex items-center mx-6 my-6 space-x-8">
        <Link
          to={`/inventory/product-details/${params.id}`}
      
        >
          Product details
        </Link>
        <Link
          to={`/inventory/product-supplier/${params.id}`}
          className="text-sm font-bold border-b-2 border-[#3C81FC] pb-2"
        >
          Product supplier
        </Link>
        <Link
          to={`/inventory/product-brand/${params.id}`}
          className="text-sm pb-2"
        >
          Pricing details
        </Link>
        <Link
          to={`/inventory/product-profit-loss/${params.id}`}
          className="text-sm pb-2"
        >
          Profit & loss
        </Link>
      </div>

      <Table1 columns={columns} data={data} />
    </>
  );
};

export default ProductSupplier;
