import React, { useEffect, useRef, useState } from "react";
import {
  BiCalendar,
  BiChevronDown,
  BiLockAlt,
  BiLogOutCircle,
  BiTv,
  BiUser,
} from "react-icons/bi";
import { Link, useNavigate } from "react-router-dom";
import Avatar from "react-avatar";
import { useDispatch, useSelector } from "react-redux";
import { setLogout } from "../../store/slices/auth";
import { useCallback } from "react";
const Navbar = () => {
  const ref = useRef();

  const defaultUser = JSON.parse(localStorage.getItem("pos-admin-user"));

  const [dropDownOpened, setDropDownOpened] = useState(false);
  const [user, setUser] = useState(defaultUser);

  const dispatch = useDispatch();
  const navigate = useNavigate();

  const logMeOut = () => {
    dispatch(setLogout());
    navigate("/login");
  };

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (dropDownOpened && ref.current && !ref.current.contains(e.target)) {
        setDropDownOpened(false);
      }
    };
    document.addEventListener("mousedown", checkIfClickedOutside);

    return () => {
      document.removeEventListener("mousedown", checkIfClickedOutside);
    };
  }, [dropDownOpened]);

  return (
    <div className="bg-[#002362] h-14 flex justify-between items-center py-2 px-6">
      <div>
        <input
          type="text"
          placeholder="Search here"
          className="bg-[#356bd0a9] text-white h-8 w-96 px-4 rounded-full text-sm focus:outline-none focus:border-[#002362] focus:ring-[#002362] block sm:text-sm focus:ring-1"
        />
      </div>
      <div className="flex items-center gap-5">
        <div className="flex items-center py-1 px-2 bg-[#356bd0a9] gap-2 rounded">
          <BiCalendar className="text-white text-xl" />
          <p className="text-white">2022-23</p>
        </div>
        <div className="flex flex-row items-center gap-2">
          <div
            className="flex justify-end items-center space-x-2 py-2 cursor-pointer"
            onClick={() => setDropDownOpened((oldState) => !oldState)}
          >
            <Avatar
              name={user.name}
              round={true}
              size="30"
              className="text-xs"
            />
            <span className="text-white">{user.name}</span>

            <BiChevronDown className="h-6 w-6 text-white" />
          </div>
        </div>
        {dropDownOpened ? (
          <div
            ref={ref}
            className="absolute top-0 right-0 mt-14 mr-8 py-2 w-48 bg-white rounded-md shadow-xl z-20"
          >
            <Link
              to="/profile"
              onClick={() => setDropDownOpened((oldState) => !oldState)}
              className="flex items-center space-x-2 px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-100  cursor-pointer"
            >
              <BiUser className="text-base text-[#3C81FC]" />{" "}
              <span className="text-base">Profile </span>
            </Link>
            <Link
              to="/change-password"
              onClick={() => setDropDownOpened((oldState) => !oldState)}
              className="flex items-center space-x-2 px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-100  cursor-pointer"
            >
              <BiLockAlt className="text-base text-[#3C81FC]" />{" "}
              <span className="text-base">Change Password </span>
            </Link>
            <Link
              to="/pos-management"
              onClick={() => setDropDownOpened((oldState) => !oldState)}
              className="flex items-center space-x-2 px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-100  cursor-pointer"
            >
              <BiTv className="text-base text-[#3C81FC]" />{" "}
              <span className="text-base">POS Management </span>
            </Link>
            <p
              onClick={() => logMeOut()}
              className="flex items-center space-x-2 px-4 py-2 text-sm capitalize text-gray-700 hover:bg-gray-100 cursor-pointer"
            >
              <BiLogOutCircle className="text-base text-[#3C81FC]" />{" "}
              <span className="text-base">Sign Out </span>
            </p>
          </div>
        ) : (
          <></>
        )}
      </div>
    </div>
  );
};

export default Navbar;
