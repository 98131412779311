import { Field, Form, Formik } from "formik";
import React, { useState } from "react";
import {
  Header,
  Label,
  TextInput,
  TextPassword,
  SelectInput,
} from "../../../components";
import * as Yup from "yup";
import * as api from "../../../../src/http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";
import { useNavigate, useParams } from "react-router-dom";
import { VscEye } from "react-icons/vsc";

const FormPOS = () => {
  const params = useParams();
  const navigate = useNavigate();

  const breadCrumbs = [
    { key: 0, name: "Dashboard", to: "", active: false },
    { key: 1, name: "POS Management", to: "/pos-management", active: false },
    {
      key: 2,
      name: `${params.type === "add" ? "Add POS" : "Edit POS"}`,
      to: `${params.type} === "add" ? '/pos-management/add' : '/pos-management/edit/${params.id}}'`,
      active: true,
    },
  ];

  const [initialValues, setInitialValues] = useState({
    pos_name: "",
    username: "",
    employee_id: "",
    password: "",
  });

  const [employee, setEmployee] = useState([]);

  const getList = async () => {
    try {
      const response = await api.getAllEmployees();
      console.log("response :", response);
      if (response.status === 200) {
        setEmployee(response.data.data);
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  useAsyncEffect(async () => {
    await getList();

    if (params.type === "edit" && params.id !== "") {
      try {
        const response = await api.getUserById({ id: params.id });

        console.log("user edit :", response.data.data[0]);
        if (response.status === 200) {
          if (response.status === 200) {
            setInitialValues(response.data.data[0]);
          }
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
      } catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  const [passwordShown, setPasswordShown] = useState(false);
  const togglePasswordVisibility = () => {
    setPasswordShown(passwordShown ? false : true);
  };

  const POSSchema = Yup.object().shape({
    pos_name: Yup.string().required("Please enter POS TID"),
    username: Yup.string().required("Please select atleast one user"),
    employee_id: Yup.string().required("Please enter user id"),
    password: Yup.string()
      .required("Please enter password")
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#\$%\^&\*])(?=.{8,})/,
        "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Character"
      ),
  });

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Add new POS" />
      <Formik
        initialValues={initialValues}
        validationSchema={POSSchema}
        enableReinitialize={true}
        onSubmit={async (values) => {
          values.account_id = JSON.parse(
            localStorage.getItem("pos-admin-user")
          ).id;

          if (params.type === "add") {
            try {
              const response = await api.createPOS(values);

              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/pos-management");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }

          if (params.type === "edit" && params.id !== "") {
            values.account_id = JSON.parse(
              localStorage.getItem("pos-admin-user")
            ).id;
            try {
              const response = await api.updateUser(values);

              if (response.status === 200 || response.status === 201) {
                toast.success(response.data.message, { position: "top-right" });
                navigate("/pos-management");
              } else {
                toast.error(response.data.message, { position: "top-right" });
              }
            } catch (err) {
              toast.error("Something went wrong! Please try again.", {
                position: "top-right",
              });
            }
          }
        }}
      >
        {({ errors, touched, handleReset, values, setFieldValue }) => (
          <Form>
            <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100 ">
              <div className="mb-6">
                <p className="text-xl font-semibold">POS Information</p>
              </div>
              <div className="grid grid-cols-3 grid-flow-row gap-x-10">
                <div className="mb-6">
                  <Label text="POS TID" isRequired={true} />
                  <TextInput
                    placeholder="Enter POS TID"
                    name="pos_name"
                    isError={errors.pos_name && touched.pos_name}
                  />
                  {errors.pos_name && touched.pos_name ? (
                    <span className="text-xs text-red-500">
                      {errors.pos_name}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="Manage by" isRequired={true} />
                  <SelectInput
                    selectOptions={employee}
                    isError={errors.username && touched.username}
                    name="username"
                    onblur={(e) => {
                      setFieldValue("username", e.target.value);
                      setFieldValue("employee_id", e.target.value);
                    }}
                  />
                  {errors.username && touched.username ? (
                    <span className="text-xs text-red-500">
                      {errors.username}
                    </span>
                  ) : null}
                </div>

                <div className="mb-6">
                  <Label text="User ID" isRequired={false} />
                  <Field
                    type="text"
                    className={`border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-full sm:text-sm focus:ring-1`}
                    disabled
                    name="employee_id"
                  />
                </div>

                <div className="mb-12 relative">
                  <Label text="Create Password" isRequired={true} />
                  <TextPassword
                    placeholder="Create Password"
                    name="password"
                    type={passwordShown ? "text" : "password"}
                    isError={errors.password && touched.password}
                  />
                  <div className="absolute right-4 top-12">
                    <VscEye
                      className={
                        passwordShown
                          ? "text-blue-900 cursor-pointer"
                          : "text-gray-500 cursor-pointer"
                      }
                      onClick={togglePasswordVisibility}
                    />
                  </div>
                  {errors.password && touched.password ? (
                    <span className="text-xs text-red-500">
                      {errors.password}
                    </span>
                  ) : null}
                </div>
              </div>
            </div>
            <div className="p-6 flex items-center gap-x-4 w-full justify-end">
              <button
                type="submit"
                className="bg-[#1267FF] py-3 px-16 !text-white rounded-full w-52"
              >
                Save
              </button>
              <button
                onClick={(event) => {
                  event.preventDefault();
                  handleReset();
                }}
                className="border border-[#1267FF] py-3 px-16 !text-[#1267FF] rounded-full w-52"
              >
                Cancel
              </button>
            </div>
          </Form>
        )}
      </Formik>
    </>
  );
};

export default FormPOS;
