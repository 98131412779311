import React from "react";
import {
  BiGridAlt,
  BiBook,
  BiStore,
  BiPurchaseTag,
  BiRocket,
  BiWallet,
  BiShoppingBag,
  BiCartAlt,
  BiGitBranch,
  BiBarChartAlt,
  BiCog,
} from "react-icons/bi";
import { RiBankFill } from "react-icons/ri";
import Logo from "../../assets/images/logo.svg";
import NavItem from "../NavItem/NavItem";
const Sidebar = () => {
  const navItems = [
    {
      key: "dashboard",
      name: "Dashboard",
      to: "/dashboard",
      icon: <BiGridAlt className="text-xl text-[#3C81FC]" />,
      sub_items: [],
    },
    {
      key: "contact",
      name: "Contact",
      to: "",
      icon: <BiBook className="text-xl text-[#3C81FC]" />,
      sub_items: [
        {
          key: "supplier",
          name: "Supplier",
          to: "/contact/supplier",
        },
        {
          key: "customer",
          name: "Customer",
          to: "/contact/customer",
        },
        {
          key: "employee",
          name: "Employee",
          to: "/contact/employee",
        },
      ],
    },
    {
      key: "inventory",
      name: "Inventory",
      to: "/inventory/product",
      icon: <BiStore className="text-xl text-[#3C81FC]" />,
      sub_items: [
        {
          key: "product",
          name: "Product",
          to: "/inventory/product",
        },
        {
          key: "stock",
          name: "Stock",
          to: "/inventory/stock",
        },
        {
          key: "stock_trans_request",
          name: "Stock trans request",
          to: "/inventory/stock-transfer-request",
        },
        {
          key: "recipe",
          name: "Recipe",
          to: "/inventory/recipe",
        },
        {
          key: "material_consumption",
          name: "Material consumption",
          to: "/inventory/material-consumption",
        },
        {
          key: "department_brand",
          name: "Department / Brand",
          to: "/inventory/department",
        },
        {
          key: "stock_verification",
          name: "Stock verification",
          to: "/inventory/stock-verification",
        },
        {
          key: "stock_transfer",
          name: "Stock transfer",
          to: "/inventory/stock-transfer",
        },
      ],
    },
    {
      key: "price-list",
      name: "Price list",
      to: "/price-list",
      icon: <BiPurchaseTag className="text-xl text-[#3C81FC]" />,
      sub_items: [
        {
          key: "wholesaler",
          name: "Wholesaler",
          to: "/price-list/wholesaler",
        },
        {
          key: "retailer",
          name: "Retailer",
          to: "/price-list/retailer",
        },
        {
          key: "customer",
          name: "Customer",
          to: "/price-list/customer",
        },
      ],
    },
    {
      key: "loyalty",
      name: "Loyalty",
      to: "/loyalty",
      icon: <BiRocket className="text-xl text-[#3C81FC]" />,
      sub_items: [
        {
          key: "customer-list",
          name: "Customer list",
          to: "/loyalty/customer-list",
        },
        {
          key: "loyalty-settings",
          name: "Loyalty settings",
          to: "/loyalty/loyalty-settings",
        },
      ],
    },
    {
      key: "expense",
      name: "Expenses",
      to: "/expenses",
      icon: <BiWallet className="text-xl text-[#3C81FC]" />,
      sub_items: [],
    },
    {
      key: "purchase",
      name: "Purchase",
      to: "/purchase",
      icon: <BiShoppingBag className="text-xl text-[#3C81FC]" />,
      sub_items: [],
    },
    {
      key: "sales",
      name: "Sales",
      to: "/sales",
      icon: <BiCartAlt className="text-xl text-[#3C81FC]" />,
      sub_items: [],
    },
    {
      key: "bank-cash",
      name: "Bank / Cash",
      to: "/bank-cash",
      icon: <RiBankFill className="text-xl text-[#3C81FC]" />,
      sub_items: [],
    },
    {
      key: "branch",
      name: "Branch",
      to: "/branch",
      icon: <BiGitBranch className="text-xl text-[#3C81FC]" />,
      sub_items: [],
    },
    {
      key: "reports",
      name: "Reports",
      to: "/reports",
      icon: <BiBarChartAlt className="text-xl text-[#3C81FC]" />,
      sub_items: [],
    },
    {
      key: "settings",
      name: "Settings",
      to: "/settings",
      icon: <BiCog className="text-xl text-[#3C81FC]" />,
      sub_items: [
        {
          key: "customer-list",
          name: "Customer list",
          to: "/settings/customer-list",
        },
        {
          key: "loyalty-settings",
          name: "Loyalty settings",
          to: "/settings/loyalty-settings",
        },
        {
          key: "customer-display",
          name: "Customer Display",
          to: "/settings/customer-display",
        },
      ],
    },
  ];

  return (
    <div className="border border-[#DBE4F2] w-64 px-1 flex flex-col items-center h-screen overflow-y-auto overflow-x-hidden">
      <div className="mt-3 flex items-center gap-2 mb-2 shadow-2xl">
        <img src={Logo} className="w-full" alt="login banner" />{" "}
        <p className="text-xl font-extrabold">POS</p>
      </div>
      <div className="mt-16">
        <div className="w-[16rem]">
          {navItems.map((item) => (
            <NavItem key={item.key} navItem={item} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default Sidebar;
