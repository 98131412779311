import React, { useState } from "react";
import { Link, useParams } from "react-router-dom";
import { Header } from "../../../../components";
import * as api from "../../../../http";
import { toast } from "react-hot-toast";
import { useAsyncEffect } from "use-async-effect";

const ViewProduct = () => {
  const params = useParams();

  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    { key: 1, name: "Product", to: "/inventory/product", active: false },
    {
      key: 2,
      name: "Preview",
      to: "/inventory/product/view",
      active: true,
    },
  ];

  const [products, setProducts] = useState([]);
  const [imageURL, setImageURL] = useState("");

  console.log("products", products);

  useAsyncEffect(async () => {
    if (params.id !== "") {
      try {
        const response = await api.getProductById({ id: params.id });
        if (response.status === 200) {
          setProducts(response.data.data[0]);
          setImageURL(response.data.url);
        } else {
          toast.error(response.data.message, { position: "top-right" });
        }
        
  console.log("data", response.data.data[0]);
      }
       catch (err) {
        toast.error("Something went wrong! Please try again.", {
          position: "top-right",
        });
      }
    }
  }, []);

  return (
    <>
      <Header
        breadCrumbs={breadCrumbs}
        title={`Preview product details (${products.product_name})`}
      />
      <div className="flex items-center mx-6 my-6 space-x-8">
        <Link
          to={`/inventory/product-details/${params.id}`}
          className="text-sm font-bold border-b-2 border-[#3C81FC] pb-2"
        >
          Product details
        </Link>
        <Link
          to={`/inventory/product-supplier/${params.id}`}
          className="text-sm pb-2"
        >
          Product supplier
        </Link>
        <Link
          to={`/inventory/product-brand/${params.id}`}
          className="text-sm pb-2"
        >
          Pricing details
        </Link>
        <Link
          to={`/inventory/product-profit-loss/${params.id}`}
          className="text-sm pb-2"
        >
          Profit & loss
        </Link>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Product information</p>
        </div>

        <div className="grid grid-cols-3 grid-flow-row gap-x-10 mb-12">
          <div className="col-span-3 flex items-center space-x-8">
            <div className="border border-[#DBE4F2] rounded-lg flex flex-col justify-center">
              <img
                src={imageURL + products.image}
                alt="preview"
                className="w-32"
              />
            </div>
          </div>
        </div>
        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Wholesale box barcode</p>
            <p className="text-base text-[#142243]">
              {products.wholesale_box_barcode}
            </p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">
              Wholesale box batch number
            </p>
            <p className="text-base text-[#142243]">
              {products.wholesale_box_batch_number}
            </p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">
              Wholesale box product name
            </p>
            <p className="text-base text-[#142243]">
              {products.wholesale_box_product_name}
            </p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">
              Wholesale box product quantity
            </p>
            <p className="text-base text-[#142243] pari"> {products.wholesale_box_product_quantity}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">
              Wholesale box product weight
            </p>
            <p className="text-base text-[#142243]">
              {products.wholesale_box_product_weight}
            </p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Supplier</p>
            <p className="text-base text-[#142243]">{products.supplier}</p>
          </div>
        </div>
        <hr />
        <div className="grid grid-cols-3 grid-flow-row gap-x-10 mt-6">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Barcode</p>
            <p className="text-base text-[#142243]">{products.barcode}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Batch number</p>
            <p className="text-base text-[#142243]">{products.batch_number}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Product name</p>
            <p className="text-base text-[#142243]">{products.product_name}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Department</p>
            <p className="text-base text-[#142243]">{products.department_id}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3"> Brand</p>
            <p className="text-base text-[#142243]">{products.brand_id}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Net weight</p>
            <p className="text-base text-[#142243]">{products.net_weight}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Sale tax</p>
            <p className="text-base text-[#142243]">{products.sale_tax}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">VAT add</p>
            <p className="text-base text-[#142243]">{products.add_vat}</p>
          </div>
        </div>
      </div>

      <div className="m-8 p-6 rounded-md shadow-md shadow-blue-100/60 border border-blue-100">
        <div className="mb-6">
          <p className="text-xl font-semibold">Other information</p>
        </div>
        <div className="grid grid-cols-3 grid-flow-row gap-x-10">
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Expiry date</p>
            <p className="text-base text-[#142243]">{products.expiry_date}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Ingredients</p>
            <p className="text-base text-[#142243]">{products.ingredients}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Description</p>
            <p className="text-base text-[#142243]">{products.description}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Gross weight</p>
            <p className="text-base text-[#142243]">{products.gross_weight}</p>
          </div>
          <div className="mb-6">
            <p className="text-sm text-[#525D68] mb-3">Nutrition</p>
            <p className="text-base text-[#142243]">--</p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewProduct;
