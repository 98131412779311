import React from "react";
import { Header } from "../../../components";

const Dashboard = () => {
  const breadCrumbs = [
    { key: 0, name: "Dashboard", to: "/dashboard", active: true },
  ];
  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Dashboard" />
    </>
  );
};

export default Dashboard;
