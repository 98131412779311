import React, { useState } from "react";
import { Header, Table1 } from "../../../../components";
import { BiDotsVerticalRounded } from "react-icons/bi";
import { Link, useParams } from "react-router-dom";





const Invoice = () => {
    const params = useParams();
    const breadCrumbs = [
        { key: 0, name: "Contact", to: "/contact/Customer", active: false },
        { key: 1, name: "Customer", to: "/contact/Customer", active: false },
        {
            key: 2,
            name: "Preview",
            to: "/contact/Customer/view",
            active: true,
        },
    ];

    const data = React.useMemo(
        () => [
            {
                col1: "001",
                col2: "ORD001",
                col3: "12 Apr 2022",
                col4: "£50.00",
                col5: "--",
                col6: "£50.00",
                col7: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,

            },
            {
                col1: "002",
                col2: "ORD002",
                col3: "23 Apr 2022",
                col4: "£34.05",
                col5: "--",
                col6: "£34.05",
                col7: <BiDotsVerticalRounded className="text-2xl text-[#3C81FC]" />,

            },
        ],
        []
    );


    const columns = React.useMemo(
        () => [

            {
                Header: "#No",
                accessor: "col1", // accessor is the "key" in the data
            },
            {
                Header: "Invoice number",
                accessor: "col2",
            },
            {
                Header: "Date",
                accessor: "col3",
            },
            {
                Header: "Amount",
                accessor: "col4",
            },
            {
                Header: "Paid Amt",
                accessor: "col5",
            },
            {
                Header: "Due Amt",
                accessor: "col6",
            },

            {
                Header: "",
                accessor: "col7",
            },
        ],
        []
    );

    return (
        <>
            {/* <Header breadCrumbs={breadCrumbs} title="Preview customer details (Mohima Shah)" /> */}
            <div className="flex items-center mx-6 my-6 space-x-8">
                <Link
                    to={`/contact/customer-details/${params.id}`}
                    className="text-sm pb-2"
                >
                    Basic details
                </Link>
                <Link
                    to={`/contact/invoice/${params.id}`}
                    className="text-sm font-bold border-b-2 border-[#3C81FC] pb-2"
                >
                    Invoice
                </Link>
                <Link
                    to={`/contact/reciept/${params.id}`}
                    className="text-sm pb-2"
                >
                    Receipt
                </Link>
                <Link
                    to={`/contact/credit-note/${params.id}`}
                    className="text-sm pb-2"
                >
                    Credit note
                </Link>
            </div>
            <Table1 columns={columns} data={data} />

        </>
    )
}


export default Invoice;