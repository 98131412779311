import React, { useState } from "react";
import NoImage from "../../../../assets/images/no-image.svg";
import { Header, Label, Table } from "../../../../components";
import DateTimePicker from "react-datetime-picker";

const StockVerification = () => {
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory/product", active: false },
    {
      key: 1,
      name: "Stock verification",
      to: "/inventory/stock-verification",
      active: true,
    },
  ];

  const data = React.useMemo(
    () => [
      {
        col1: "001",
        col2: <img src={NoImage} alt="no-img" />,
        col3: "5588599996",
        col4: "Haldi",
        col5: "Grocery",
        col6: "Patanjali",
        col7: "01",
        col8: "32",
      },
      {
        col1: "002",
        col2: <img src={NoImage} alt="no-img" />,
        col3: "5588599996",
        col4: "Haldi",
        col5: "Grocery",
        col6: "Patanjali",
        col7: "01",
        col8: "32",
      },
    ],
    []
  );

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      {
        Header: "Image",
        accessor: "col2",
      },
      {
        Header: "Barcode",
        accessor: "col3",
      },
      {
        Header: "Name",
        accessor: "col4",
      },
      {
        Header: "Department",
        accessor: "col5",
      },
      {
        Header: "Brand",
        accessor: "col6",
      },
      {
        Header: "Batch No.",
        accessor: "col7",
      },
      {
        Header: "Available Qty",
        accessor: "col8",
      },
    ],
    []
  );

  const addNewData = [];
  const [value, onChange] = useState(new Date());

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Stock verification" />
      <div className="flex flex-col mx-6 my-6">
        <Label text="Select print date & time" isRequired={true} />
        <DateTimePicker
          className="border border-[#DBE4F2] mt-2 p-2 h-12 rounded text-sm focus:outline-none focus:border-[#142243] focus:ring-[#142243] block w-1/4 sm:text-sm focus:ring-1"
          amPmAriaLabel="Select AM/PM"
          calendarAriaLabel="Toggle calendar"
          clearAriaLabel="Clear value"
          dayAriaLabel="Day"
          hourAriaLabel="Hour"
          maxDetail="second"
          minuteAriaLabel="Minute"
          monthAriaLabel="Month"
          nativeInputAriaLabel="Date and time"
          onChange={onChange}
          secondAriaLabel="Second"
          value={value}
          yearAriaLabel="Year"
        />
      </div>
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};

export default StockVerification;
