import React, { useState } from "react";
import { BiDotsVerticalRounded, BiPencil, BiTrash } from "react-icons/bi";
import { Header, Table } from "../../../../components";
import * as api from "../../../../http";
import { useAsyncEffect } from "use-async-effect";
import { Link } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { BsEyeFill } from "react-icons/bs";
import { toast } from "react-hot-toast";

const Recipe = () => {
  const breadCrumbs = [
    { key: 0, name: "Inventory", to: "/inventory", active: false },
    { key: 1, name: "Recipe", to: "/inventory/recipe", active: true },
  ];
  const [recipe, setRecipe] = useState([]);

  const getList = async () => {
    try {
      const response = await api.getAllRecipe();
      if (response.status === 200) {
        setRecipe(response.data.data);
        console.log("recipe+++++++++++++++++++++++", recipe);
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  useAsyncEffect(async () => {
    await getList();
  }, []);

  const confirmDelete = (id) => {
    confirmAlert({
      message: "Are you sure to do this.",
      buttons: [
        {
          label: "Yes",
          onClick: () => deleteItem(id),
        },
        {
          label: "No",
        },
      ],
    });
  };
  const deleteItem = async (id) => {
    try {
      const response = await api.deleteRecipe({ id });
      if (response.status === 200) {
        toast.success(response.data.message, { position: "top-right" });
        await getList();
      } else {
        toast.error(response.data.message, { position: "top-right" });
      }
    } catch (err) {
      console.log("Err:", err);
    }
  };

  const data = React.useMemo(() => {
    return recipe?.map((data, index) => ({
      col1: index + 1,
      // col2: data.code,
      col3: data.name,
      col4: data.recipe_date,
      col5: data.type,
      // col5: data.quantity,
      // col6: data.remain_quantity,
      // col7: data.status,
      // col8: (
      //   <div className="flex items-center gap-2 cursor-pointer">
      //     <div className="w-3 h-3 rounded-full bg-green-600"></div>
      //     <div>{data.show_in_pos === "yes" ? "Active" : "Inactive"}</div>
      //   </div>
      // ),
      col9: (
        <div className="flex items-center space-x-4">
          <Link
            className="cursor-pointer"
            to={`/inventory/recipe-details/${data.id}`}
          >
            <BsEyeFill className="text-xl text-[#3C81FC]" />
          </Link>
          <Link
            className="cursor-pointer"
            to={`/inventory/recipe/edit/${data.id}`}
          >
            <BiPencil className="text-xl text-[#3C81FC]" />
          </Link>

          <p className="cursor-pointer" onClick={() => confirmDelete(data.id)}>
            <BiTrash className="text-xl text-red-500" />
          </p>
        </div>
      ),
    }));
  }, [recipe]);

  const columns = React.useMemo(
    () => [
      {
        Header: "#No",
        accessor: "col1", // accessor is the "key" in the data
      },
      // {
      //   Header: "Recipe code",
      //   accessor: "col2",
      // },
      {
        Header: "Recipe name",
        accessor: "col3",
      },
      {
        Header: "Recipe Date",
        accessor: "col4",
      },
      {
        Header: "Recipe Type",
        accessor: "col5",
      },

      // {
      //   Header: "Recipe Qnt",
      //   accessor: "col5",
      // },
      // {
      //   Header: "Remaining",
      //   accessor: "col6",
      // },
      // {
      //   Header: "Status",
      //   accessor: "col7",
      // },
      {
        Header: "",
        accessor: "col8",
      },
      {
        Header: "",
        accessor: "col9",
      },
    ],
    []
  );

  const addNewData = [
    {
      title: "Add Recipe",
      to: "/inventory/recipe/add",
    },
  ];

  return (
    <>
      <Header breadCrumbs={breadCrumbs} title="Recipe" />
      <Table columns={columns} data={data} addData={addNewData} />
    </>
  );
};

export default Recipe;
